import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { NavLink } from "react-router-dom";
import { getInitials } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const Header = ({ sidebarOpen, setSidebarOpen }) => {
  const { user, logout } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || "en");

  const toggleLanguage = () => {
    const newLang = currentLanguage === "en" ? "es" : "en";
    i18n.changeLanguage(newLang);
    setCurrentLanguage(newLang);
    localStorage.setItem("i18nextLng", newLang);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = (event) => {
    event.stopPropagation();
    setSidebarOpen((prev) => !prev);
  };

  return (
    <div className="bg-white fixed inset-x-0 top-0 z-50 flex h-14 items-center justify-between gap-12 px-4 transition sm:px-6 lg:left-72 lg:z-30 lg:px-8 backdrop-blur-sm">
      <div className="absolute inset-x-0 top-full h-px transition bg-zinc-900/10"></div>
      <div className="hidden lg:block lg:max-w-md lg:flex-auto" />
      <div className="flex items-center gap-5 lg:hidden">
        <button
          id="menu-button"
          type="button"
          className="flex h-6 w-6 items-center justify-center rounded-md transition hover:bg-zinc-900/5"
          onClick={toggleSidebar}
        >
          {sidebarOpen ? (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              aria-hidden="true"
              strokeLinecap="round"
              className="w-2.5 stroke-zinc-900"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg
              viewBox="0 0 10 9"
              fill="none"
              strokeLinecap="round"
              aria-hidden="true"
              className="w-2.5 stroke-zinc-900"
            >
              <path d="M.5 1h9M.5 8h9M.5 4.5h9"></path>
            </svg>
          )}
        </button>
        <span className="logo self-center text-xl font-semibold whitespace-nowrap">
          Plura.design
        </span>
      </div>
      <div className="flex items-center gap-5">
        <div className="ml-4 flex items-center md:ml-6">
          <div className="relative ml-3" ref={containerRef}>
            <div>
              <button
                type="button"
                className="relative flex max-w-xs items-center rounded-full bg-blue-600 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-100"
                id="user-menu-button"
                aria-expanded={open}
                aria-haspopup="true"
                onClick={() => setOpen(!open)}
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">Open user menu</span>
                <span className="h-8 w-8 rounded-full bg-blue-600 text-white flex items-center justify-center">
                  {user && getInitials(user.username)}
                </span>
              </button>
            </div>
            {open && (
              <div
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabIndex="-1"
              >
                <span className="block w-full text-left px-4 py-2 text-sm text-gray-700">
                  <b>{user?.company_name}</b> <br />
                  {user?.username}
                </span>
                <hr />
                <div className="flex items-center justify-between px-4 py-2">
                  <span className="text-sm text-gray-700 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-1 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                      />
                    </svg>
                    {t("userProfile.language")}
                  </span>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={currentLanguage === "es"}
                      onChange={toggleLanguage}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full relative">
                      <span
                        className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${
                          currentLanguage === "es"
                            ? "translate-x-5"
                            : "translate-x-0"
                        }`}
                      ></span>
                    </div>
                  </label>
                </div>
                <hr />
                <NavLink
                  to="/settings?tab=Users"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  {t("userProfile.profile")}
                </NavLink>
                <NavLink
                  to="/settings"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  {t("userProfile.settings")}
                </NavLink>
                <button
                  onClick={logout}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  {t("userProfile.signOut")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
