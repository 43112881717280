import React, { useRef } from "react";

const LogoDropZone = ({
  file,
  previewUrl,
  onFileDrop,
  onInputChange,
  onRemoveFile,
  error,
}) => {
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files && e.dataTransfer.files[0];
    if (droppedFile) onFileDrop(droppedFile);
  };

  const handleClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  return (
    <div className="flex flex-col w-full">
      {previewUrl ? (
        <div className="w-full max-w-xs p-4 border rounded-md relative">
          <img
            src={previewUrl}
            alt="Logo Preview"
            className="size-48 object-cover rounded border"
          />
          <button
            type="button"
            onClick={onRemoveFile}
            className="absolute top-2 right-2 text-red-700 bg-white p-2 rounded-lg"
            title="Remove Logo"
          >
            Remove
          </button>
        </div>
      ) : (
        <div
          className="flex flex-col items-center justify-center w-64 h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 mt-2"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-8 mb-4 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-500 text-center">
              PNG, JPG, JPEG, GIF <br /> (Max. 2MB, square image)
            </p>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            name="logo"
            accept="image/png, image/jpeg, image/jpg, image/gif"
            onChange={onInputChange}
            className="hidden"
          />
        </div>
      )}

      {error && (
        <div className="text-red-500 text-sm mt-1 w-64 text-center">
          {error}
        </div>
      )}
    </div>
  );
};

export default LogoDropZone;
