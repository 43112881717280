import React from "react";

const InvoiceItems = ({ currentInvoice = {}, items = [] }) => {
  const formatCurrency = (value) =>
    new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(value);

  // Compute tax amount if applicable.
  let taxAmount = 0;
  if (
    currentInvoice?.tax &&
    // currentInvoice.tax.tax_type === "exclusive" &&
    currentInvoice.total_amount
  ) {
    taxAmount =
      currentInvoice.total_amount * (parseFloat(currentInvoice.tax.rate) / 100);
  }

  return (
    <div className="bg-white rounded-lg shadow border p-6">
      <h2 className="text-lg font-semibold text-gray-900">Items</h2>

      {items && items.length > 0 ? (
        <div className="mt-6 flow-root">
          <ul className="-my-6 divide-y divide-gray-200">
            {items.map((item, index) => (
              <li key={index} className="flex py-6">
                <div className="flex-1 flex flex-col">
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <p>{item.name || "Unnamed Item"}</p>
                    <p className="ml-4">{formatCurrency(item.value || 0)}</p>
                  </div>
                  {item.description && (
                    <p className="mt-1 text-sm text-gray-500">
                      {item.description}
                    </p>
                  )}
                  <div className="flex items-end justify-between text-sm mt-4">
                    {item.quantity && (
                      <p className="text-gray-500">Qty: {item.quantity}</p>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="mt-6 text-center text-gray-500">
          <p>No items have been added yet.</p>
        </div>
      )}

      {items.length > 0 && (
        <div className="border-t border-gray-200 mt-6 pt-4">
          <div className="flex justify-between text-lg font-medium text-gray-900 mb-4">
            <p>Total:</p>
            <p>{formatCurrency(currentInvoice?.total_amount) || 0}</p>
          </div>
          <div className="flex justify-between text-base text-gray-900 mb-4">
            <p>Discount:</p>
            <p>
              {currentInvoice?.discount_type === "percentage"
                ? `${Math.round(currentInvoice?.discount_value) || 0}%`
                : `${formatCurrency(currentInvoice?.discount_value) || 0}`}
            </p>
          </div>
          {currentInvoice?.tax && (
            <div className="flex justify-between text-base text-gray-900 mb-4">
              <p>
                Tax ({currentInvoice.tax.name} - {currentInvoice.tax.rate}%):
              </p>
              <p>{formatCurrency(taxAmount)}</p>
            </div>
          )}

          <div className="flex justify-between text-lg font-medium text-gray-900 font-semibold">
            <p>Final Total:</p>
            <p>{formatCurrency(currentInvoice?.final_amount || 0)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceItems;
