import { useMutation, useQuery, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import axiosInstance from "./../axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Create a new project
const createProject = async ({ data, token }) => {
  const response = await axiosInstance.post(`${API_URL}/projects`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Get all projects with filters applied, including archived filter if provided.
const fetchProjects = async (token, page = 1, limit = 20, filters = {}) => {
  // Build query parameters including filters if provided
  const queryParams = new URLSearchParams({
    page,
    limit,
    ...(filters.name ? { name: filters.name } : {}),
    ...(filters.status ? { status: filters.status } : {}),
    ...(filters.archived !== undefined ? { archived: filters.archived } : {}),
  });

  const response = await axiosInstance.get(
    `${API_URL}/projects?${queryParams.toString()}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Get a specific project
const fetchProject = async ({ projectId, token }) => {
  const response = await axiosInstance.get(`${API_URL}/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Update a project
const updateProject = async ({ projectId, data, token }) => {
  let headers = { Authorization: `Bearer ${token}` };
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }
  const response = await axiosInstance.put(
    `${API_URL}/projects/${projectId}`,
    data,
    { headers }
  );
  return response.data;
};

// Delete a project
const deleteProject = async ({ projectId, token }) => {
  const response = await axiosInstance.delete(
    `${API_URL}/projects/${projectId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Add a client to a project
const addClientToProject = async ({ projectId, clientId, token }) => {
  const response = await axiosInstance.post(
    `${API_URL}/projects/${projectId}/clients`,
    { client_id: clientId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Remove a client from a project
const removeClientFromProject = async ({ projectId, clientId, token }) => {
  const response = await axiosInstance.delete(
    `${API_URL}/projects/${projectId}/clients/${clientId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// New function to update the archive status of a project
const updateProjectArchive = async ({ projectId, archived, token }) => {
  const response = await axiosInstance.put(
    `${API_URL}/projects/${projectId}/archive`,
    { archived },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Function to call the copy project endpoint
const copyProject = async ({ projectId, token }) => {
  const response = await axiosInstance.post(
    `${API_URL}/projects/${projectId}/copy`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Hook to create a new project
export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation((data) => createProject({ data, token: user?.token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("projects");
    },
  });
};

// Hook to fetch all projects with optional filters
export const useProjects = (page, limit, filters = {}) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["projects", page, limit, filters],
    () => fetchProjects(user?.token, page, limit, filters),
    {
      enabled: !!user?.token,
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 0,
    }
  );
};

// Hook to fetch a specific project
export const useProject = (projectId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["project", projectId],
    () => fetchProject({ projectId, token: user?.token }),
    {
      enabled: !!user?.token && !!projectId,
    }
  );
};

// Hook to update a project
export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ projectId, data }) =>
      updateProject({ projectId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
      },
    }
  );
};

// Hook to delete a project
export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    (projectId) => deleteProject({ projectId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
      },
    }
  );
};

// Hook to add a client to a project
export const useAddClientToProject = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ projectId, clientId }) =>
      addClientToProject({ projectId, clientId, token: user?.token }),
    {
      onSuccess: (data, { projectId }) => {
        queryClient.invalidateQueries(["project", projectId]);
      },
    }
  );
};

// Hook to remove a client from a project
export const useRemoveClientFromProject = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ projectId, clientId }) =>
      removeClientFromProject({ projectId, clientId, token: user?.token }),
    {
      onSuccess: (data, { projectId }) => {
        queryClient.invalidateQueries(["project", projectId]);
      },
    }
  );
};

// Hook to update the archive status of a project
export const useUpdateProjectArchive = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ projectId, archived }) =>
      updateProjectArchive({ projectId, archived, token: user?.token }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("projects");
        queryClient.invalidateQueries(["project", variables.projectId]);
      },
    }
  );
};

// Hook to copy a project
export const useCopyProject = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation(
    ({ projectId }) => copyProject({ projectId, token: user?.token }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("projects");
        queryClient.invalidateQueries(["project", variables.projectId]);
      },
    }
  );
};
