import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import axiosInstance from "../axiosInstance";
import { AuthContext } from "../../context/AuthProvider";

const API_URL = process.env.REACT_APP_API_URL;

const fetchProducts = async ({ token, filters, search, page, limit }) => {
  const response = await axiosInstance.post(
    `${API_URL}/products/search`,
    { page, limit, filters, search },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const fetchProduct = async (productId, token) => {
  const response = await axiosInstance.get(`${API_URL}/products/${productId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const createProduct = async ({ data, token }) => {
  const response = await axiosInstance.post(`${API_URL}/products`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updateProduct = async ({ productId, data, token }) => {
  const response = await axiosInstance.put(
    `${API_URL}/products/${productId}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const deleteProduct = async ({ productId, token }) => {
  await axiosInstance.delete(`${API_URL}/products/${productId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const useProducts = (page, limit, { filters, search } = {}) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["products", page, filters, search],
    () => fetchProducts({ token: user?.token, filters, search, page, limit }),
    { enabled: !!user?.token }
  );
};

export const useProduct = (productId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["product", productId],
    () => fetchProduct(productId, user?.token),
    { enabled: !!user?.token && !!productId }
  );
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation((data) => createProduct({ data, token: user?.token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ productId, data }) =>
      updateProduct({ productId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
      },
    }
  );
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ productId }) => deleteProduct({ productId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("products");
      },
    }
  );
};
