import React, { useContext, useState, useMemo } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import TaskList from "./viewMode/TaskList";
import TaskBoard from "./viewMode/TaskBoard";
import TasksButtonGroupMenu from "../../../components/common/buttonGroup/TasksButtonGroupMenu";
import { useTasksOfCompany } from "../../../hooks/useTasks/useTasks";
import { AuthContext } from "../../../context/AuthProvider";
import LoadingPage from "../../../components/common/empty/LoadingPage";
import ErrorMessage from "../../../components/common/empty/ErrorMessage";
import { usePhases } from "../../../hooks/useTasks/usePhases";
import GanttView from "./viewMode/GanttView";
import { useUsers } from "../../../hooks/useUsers";
import TaskFilters from "../../../components/common/filters/TaskFilters";

const TodoList = () => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;
  const [viewMode, setViewMode] = useState("board");

  // Filter states
  const [phaseFilter, setPhaseFilter] = useState(null);
  const [usersFilter, setUsersFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);

  // Get phases for the company
  const { data: phasesData = [] } = usePhases(companyId);
  // Map phases to react-select options
  const phaseOptions = useMemo(
    () =>
      phasesData.map((phase) => ({
        value: phase.id,
        label: phase.name,
      })),
    [phasesData]
  );

  // Load company users using your users hook.
  // Adjust page and perPage if needed.
  const {
    data: usersData,
    isLoading: usersLoading,
    isError: usersError,
  } = useUsers(1, 100);
  // Convert the returned users to react-select options.
  const userOptions = useMemo(() => {
    if (!usersData || !usersData.users) return [];
    return usersData.users.map((user) => ({
      value: user.id,
      label: user.username,
    }));
  }, [usersData]);

  const statusOptions = useMemo(
    () => [
      { value: "Planned", label: "Planned" },
      { value: "In Progress", label: "In Progress" },
      { value: "Active", label: "Active" },
      { value: "Completed", label: "Completed" },
      { value: "Paused", label: "Paused" },
      { value: "Cancelled", label: "Cancelled" },
    ],
    []
  );

  // Build filters object for the hook. For multi-select, we join the selected values.
  const filters = useMemo(() => {
    return {
      phase_id: phaseFilter ? phaseFilter.value : "",
      user_ids:
        usersFilter && usersFilter.length > 0
          ? usersFilter.map((u) => u.value)
          : "",
      project_status: statusFilter ? statusFilter.value : "",
    };
  }, [phaseFilter, usersFilter, statusFilter]);

  // Pass filters to the useTasksOfCompany hook
  const {
    data: tasks,
    isLoading,
    isError,
  } = useTasksOfCompany(companyId, filters);

  return (
    <div>
      <PageHeadings title="Tasks" tags={[]} showCta={false}>
        <TasksButtonGroupMenu viewMode={viewMode} setViewMode={setViewMode} />
      </PageHeadings>
      <div className="flex">
        <TaskFilters
          phaseOptions={phaseOptions}
          phaseFilter={phaseFilter}
          setPhaseFilter={setPhaseFilter}
          usersLoading={usersLoading}
          usersError={usersError}
          userOptions={userOptions}
          usersFilter={usersFilter}
          setUsersFilter={setUsersFilter}
          statusOptions={statusOptions}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          filterByProjectStatus
        />
      </div>
      {isLoading ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <>
          {viewMode === "list" && <TaskList data={tasks} />}
          {viewMode === "board" && <TaskBoard data={tasks} />}
          {viewMode === "gantt" && (
            <GanttView tasks={tasks} phases={phasesData} />
          )}
        </>
      )}
    </div>
  );
};

export default TodoList;
