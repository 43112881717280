import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { decryptToken, generateEncryptedLink } from "../../utils/cryptoUtils";
import ProjectHeader from "../../components/layout/projectLayout/ProjectHeader";
import ProjectFooter from "../../components/layout/projectLayout/ProjectFooter";
import { usePublicProjectDetails } from "../../hooks/usePublic/usePublic";
import LoadingPage from "../../components/common/empty/LoadingPage";
import FilesContainer from "./details/files/FilesContainer";
import FoldersSection from "./details/folder/FoldersSection";
import HeroSection from "./details/HeroSection";

const SharedProject = () => {
  const { token } = useParams();
  const data = decryptToken(token);
  const [sharableLink] = useState(
    generateEncryptedLink(data?.company_id, data?.project_id)
  );
  const { company_id, project_id } = data;

  const {
    data: projectDetails,
    isLoading,
    isError,
  } = usePublicProjectDetails(company_id, project_id);

  if (isLoading) {
    return <LoadingPage />;
  }
  if (isError || !data || !data.company_id || !data.project_id) {
    return (
      <div className="bg-gray-50 h-screen flex items-center justify-center">
        <div className="text-center max-w-xl">
          <Link to="/" className="flex items-center mb-8">
            <span className="mx-auto text-3xl font-semibold whitespace-nowrap text-slate-500">
              Plura.design
            </span>
          </Link>
          <h1 className="text-3xl font-bold text-gray-900">
            Something went wrong or you do not have access to this project.
          </h1>
          <p className="mt-8 text-lg text-gray-600">
            Please contact the project administrator if you believe this is an
            error.
          </p>
        </div>
      </div>
    );
  }

  const { project, company, public_files } = projectDetails || {};

  // Group files that have a folder and separate those that do not.
  const folderGroupsMap = {};
  const ungroupedFiles = [];

  public_files.forEach((file) => {
    if (file.folder) {
      const folderId = file.folder.id;
      if (!folderGroupsMap[folderId]) {
        folderGroupsMap[folderId] = { folder: file.folder, files: [] };
      }
      folderGroupsMap[folderId].files.push(file);
    } else {
      ungroupedFiles.push(file);
    }
  });
  const folderGroups = Object.values(folderGroupsMap);

  return (
    <div>
      <ProjectHeader
        data={company}
        logo={company?.logo_url}
        banner={project?.banner_url}
      />
      <HeroSection data={project} />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-8">
        {folderGroups.length > 0 && (
          <FoldersSection folders={folderGroups} token={sharableLink} />
        )}
        <FilesContainer
          data={ungroupedFiles}
          hasFolder={folderGroups.length > 0}
        />
      </div>
      <ProjectFooter data={company} />
    </div>
  );
};

export default SharedProject;
