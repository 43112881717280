import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthProvider";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch all taxes, optionally filtering by archived status
const fetchTaxes = async (token, archived) => {
  const response = await axios.get(`${API_URL}/taxes`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { archived }, // if archived is provided it will filter accordingly
  });
  return response.data;
};

// Fetch a single tax by ID
const fetchTax = async (taxId, token) => {
  if (!taxId) {
    throw new Error("Tax ID is required.");
  }
  const response = await axios.get(`${API_URL}/taxes/${taxId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Create a new tax
const createTax = async (data, token) => {
  if (!data) {
    throw new Error("Tax data is required.");
  }
  const response = await axios.post(`${API_URL}/taxes`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Update an existing tax
const updateTax = async ({ taxId, data, token }) => {
  if (!taxId || !data) {
    throw new Error("Tax ID and data are required.");
  }
  const response = await axios.put(`${API_URL}/taxes/${taxId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Archive a tax (using DELETE to mark as archived)
const archiveTax = async (taxId, token) => {
  if (!taxId) {
    throw new Error("Tax ID is required.");
  }
  const response = await axios.delete(`${API_URL}/taxes/${taxId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// React Query Hooks with Token Integration for Taxes

// Fetch list of taxes (optionally filtered by archived status)
export const useTaxes = (archived) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["taxes", archived],
    () => fetchTaxes(user?.token, archived),
    {
      enabled: !!user?.token,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

// Fetch a single tax by ID
export const useTax = (taxId) => {
  const { user } = useContext(AuthContext);
  return useQuery(["tax", taxId], () => fetchTax(taxId, user?.token), {
    enabled: !!taxId && !!user?.token,
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

// Create a new tax
export const useCreateTax = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((data) => createTax(data, user?.token), {
    onSuccess: () => {
      queryClient.invalidateQueries("taxes");
    },
    onError: (error) => {
      console.error("Error creating tax:", error);
    },
  });
};

// Update an existing tax
export const useUpdateTax = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation(
    ({ taxId, data }) => updateTax({ taxId, data, token: user?.token }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["tax", variables.taxId]);
        queryClient.invalidateQueries("taxes");
      },
      onError: (error) => {
        console.error("Error updating tax:", error);
      },
    }
  );
};

// Archive (delete) a tax
export const useArchiveTax = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation((taxId) => archiveTax(taxId, user?.token), {
    onSuccess: (data, taxId) => {
      queryClient.invalidateQueries(["tax", taxId]);
      queryClient.invalidateQueries("taxes");
    },
    onError: (error) => {
      console.error("Error archiving tax:", error);
    },
  });
};
