import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const brandColor = "#2E3B4E";
const accentColor = "#ECECEC";

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    padding: 40,
    fontFamily: "Helvetica",
    backgroundColor: "#FAFAFA",
  },
  companyHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  companyLogo: {
    width: 100,
    height: 100,
    marginRight: 10,
  },
  companyName: {
    fontSize: 24,
    fontWeight: "bold",
    color: brandColor,
  },
  headerContainer: {
    marginBottom: 20,
    paddingBottom: 10,
    borderBottomWidth: 2,
    borderBottomColor: brandColor,
    borderBottomStyle: "solid",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: brandColor,
    marginBottom: 4,
    textTransform: "uppercase",
    letterSpacing: 1,
  },
  invoiceMetaRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  metaText: {
    fontSize: 10,
    color: "#555",
    marginBottom: 2,
  },
  labelBold: {
    fontWeight: "bold",
    color: brandColor,
    fontSize: 12,
  },
  sectionTitle: {
    marginBottom: 6,
    fontWeight: "bold",
    color: brandColor,
    fontSize: 14,
    borderBottomWidth: 1,
    borderBottomColor: accentColor,
    borderBottomStyle: "solid",
    paddingBottom: 2,
  },
  section: {
    marginBottom: 20,
    paddingHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    shadowColor: "#000",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  clientInfo: {
    marginBottom: 8,
  },
  clientLine: {
    fontSize: 11,
    color: "#333",
    marginBottom: 3,
  },
  table: {
    marginTop: 8,
    borderWidth: 1,
    borderColor: accentColor,
    borderStyle: "solid",
    borderRadius: 4,
    overflow: "hidden",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: accentColor,
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignItems: "center",
  },
  tableHeaderText: {
    fontWeight: "bold",
    fontSize: 11,
    color: brandColor,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: accentColor,
    borderBottomStyle: "solid",
    paddingHorizontal: 8,
    paddingVertical: 6,
    alignItems: "center",
  },
  tableCol: {
    width: "25%",
    fontSize: 10,
    color: "#333",
  },
  itemNameCol: {
    width: "25%",
    fontSize: 10,
    color: "#333",
    lineHeight: 1.4,
  },
  textRight: {
    textAlign: "right",
  },
  totalsSection: {
    marginTop: 10,
    paddingTop: 10,
    borderTopWidth: 1,
    borderTopColor: accentColor,
    borderTopStyle: "solid",
  },
  invoiceInfoRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  footer: {
    marginTop: 40,
    textAlign: "center",
    fontSize: 10,
    color: "#aaa",
  },
});

const InvoicePDF = ({ companyName, companyLogo, invoiceData }) => {
  // Destructure invoice data with defaults.
  const {
    invoice_number = "---",
    issue_date = null,
    due_date = null,
    client = {},
    items = {},
    total_amount = 0,
    discount_value = 0,
    discount_type = null,
    final_amount = 0,
    tax = null,
  } = invoiceData || {};

  const formatCurrency = (value = 0) =>
    new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(value);

  const formatDate = (dateString) => {
    if (!dateString) return "---";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const discountDisplay =
    discount_type === "percentage"
      ? `${Math.round(discount_value) || 0}%`
      : formatCurrency(discount_value || 0);

  const itemsArray = Object.values(items || {});

  let computedTaxAmount = 0;
  if (tax && total_amount) {
    computedTaxAmount = total_amount * (parseFloat(tax.rate) / 100);
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Company Header Section */}
        {(companyLogo || companyName) && (
          <View style={styles.companyHeader}>
            {companyLogo ? (
              <Image style={styles.companyLogo} src={companyLogo} />
            ) : (
              <Text style={styles.companyName}>{companyName}</Text>
            )}
          </View>
        )}

        {/* Invoice Header */}
        <View style={styles.headerContainer}>
          <Text style={styles.title}>Invoice #{invoice_number}</Text>
          <View style={styles.invoiceMetaRow}>
            <Text style={styles.metaText}>
              Issue Date: {formatDate(issue_date)}
            </Text>
            <Text style={styles.metaText}>
              Due Date: {formatDate(due_date)}
            </Text>
          </View>
        </View>

        {/* Client Info */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Bill To</Text>
          {Object.keys(client).length > 0 ? (
            <View style={styles.clientInfo}>
              {client.name && (
                <Text style={styles.clientLine}>{client.name}</Text>
              )}
              {client.email && (
                <Text style={styles.clientLine}>{client.email}</Text>
              )}
              {client.phone && (
                <Text style={styles.clientLine}>+{client.phone}</Text>
              )}
            </View>
          ) : (
            <Text style={{ color: "#999" }}>No client data</Text>
          )}
        </View>

        {/* Items */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Items</Text>
          {itemsArray.length ? (
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={[styles.tableCol, styles.tableHeaderText]}>
                  Item
                </Text>
                <Text style={[styles.tableCol, styles.tableHeaderText]}>
                  Quantity
                </Text>
                <Text style={[styles.tableCol, styles.tableHeaderText]}>
                  Price
                </Text>
                <Text
                  style={[
                    styles.tableCol,
                    styles.tableHeaderText,
                    styles.textRight,
                  ]}
                >
                  Subtotal
                </Text>
              </View>
              {itemsArray.map((item, idx) => {
                const { name, description, quantity, value } = item || {};
                const itemName = name || "Unnamed Item";
                const itemQuantity = quantity ?? 1;
                const itemPrice = value ?? 0;
                const itemTotal = itemQuantity * itemPrice;
                return (
                  <View key={idx} style={styles.tableRow}>
                    <Text style={styles.itemNameCol}>
                      {itemName}
                      {description && (
                        <Text style={{ fontSize: 9, color: "#666" }}>
                          {"\n"}
                          {description}
                        </Text>
                      )}
                    </Text>
                    <Text style={styles.tableCol}>{itemQuantity}</Text>
                    <Text style={styles.tableCol}>
                      {formatCurrency(itemPrice)}
                    </Text>
                    <Text style={[styles.tableCol, styles.textRight]}>
                      {formatCurrency(itemTotal)}
                    </Text>
                  </View>
                );
              })}
            </View>
          ) : (
            <Text style={{ color: "#999" }}>No items added to invoice.</Text>
          )}
        </View>

        {/* Totals */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Summary</Text>
          <View style={styles.totalsSection}>
            <View style={styles.invoiceInfoRow}>
              <Text style={styles.labelBold}>Subtotal:</Text>
              <Text>{formatCurrency(total_amount)}</Text>
            </View>
            <View style={styles.invoiceInfoRow}>
              <Text style={styles.labelBold}>Discount:</Text>
              <Text>{discountDisplay}</Text>
            </View>
            {tax && (
              <View style={styles.invoiceInfoRow}>
                <Text style={styles.labelBold}>
                  Tax ({tax.name} - {`${tax.rate}%`}):
                </Text>
                <Text>{formatCurrency(computedTaxAmount)}</Text>
              </View>
            )}
            <View style={styles.invoiceInfoRow}>
              <Text style={[styles.labelBold, { fontSize: 12 }]}>Total:</Text>
              <Text style={{ fontWeight: "bold", fontSize: 12 }}>
                {formatCurrency(final_amount)}
              </Text>
            </View>
          </View>
        </View>

        {/* Footer */}
        <Text style={styles.footer}>
          Thank you for your business. This invoice is system-generated.
        </Text>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
