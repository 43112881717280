import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getInitials } from "../../../utils/helpers";
import {
  useCopyProject,
  useUpdateProjectArchive,
} from "../../../hooks/useProjects/useProjects";

const ConfirmModal = ({
  show,
  title,
  message,
  onCancel,
  onConfirm,
  isProcessing,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [show, onCancel]);

  if (!show) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
        <div
          ref={modalRef}
          className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md relative"
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">{title}</h2>
            <button
              onClick={onCancel}
              className="text-gray-500 hover:text-gray-700 font-bold text-xl"
              aria-label="Close modal"
            >
              &times;
            </button>
          </div>
          <p className="mb-6">{message}</p>
          <div className="flex justify-end">
            <button
              onClick={onCancel}
              className="px-4 py-2 mr-2 bg-gray-200 text-gray-700 rounded"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-blue-600 text-white rounded"
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ProjectCard = ({ data, enableCopy = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState("");
  const dropdownRef = useRef(null);

  const { mutate: copyProjectMutate, isLoading: isCopying } = useCopyProject();
  const { mutate: updateArchiveMutate, isLoading: isUpdatingArchive } =
    useUpdateProjectArchive();

  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
  };

  // Instead of executing immediately, open the modal for confirmation
  const handleCopyProject = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmAction("copy");
    setShowConfirmModal(true);
    setIsDropdownOpen(false);
  };

  const handleArchiveProject = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmAction("archive");
    setShowConfirmModal(true);
    setIsDropdownOpen(false);
  };

  // Called when user confirms in the modal
  const handleConfirm = () => {
    if (confirmAction === "copy") {
      copyProjectMutate({ projectId: data.id });
    } else if (confirmAction === "archive") {
      updateArchiveMutate({ projectId: data.id, archived: !data.archived });
    }
    setShowConfirmModal(false);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Set modal title and message based on the action
  const modalTitle =
    confirmAction === "copy"
      ? "Confirm Create a Copy"
      : data.archived
      ? "Confirm Make Active"
      : "Confirm Archive";
  const modalMessage =
    confirmAction === "copy"
      ? "Are you sure you want to create a copy of this project?"
      : data.archived
      ? "Are you sure you want to make this project active?"
      : "Are you sure you want to archive this project?";

  return (
    <div className="relative">
      <Link
        to={`/projects/${data?.id}`}
        className="flex flex-col h-full rounded-lg border border-gray-200 bg-white shadow-sm hover:shadow-lg"
      >
        <div className="p-6 flex-grow">
          {/* Header: Status and Team Members */}
          <div className="mb-4 flex items-center justify-between gap-4">
            <div className="flex">
              <span
                className={`rounded px-2.5 py-0.5 text-xs font-medium ${
                  data?.status === "Completed"
                    ? "bg-green-100 text-green-800"
                    : data?.status === "Paused"
                    ? "bg-yellow-100 text-yellow-800"
                    : data?.status === "Active" ||
                      data?.status === "In Progress"
                    ? "bg-blue-100 text-blue-800"
                    : data?.status === "Cancelled"
                    ? "bg-red-100 text-red-800"
                    : "bg-gray-100 text-gray-800"
                }`}
              >
                {data?.status}
              </span>
              {data?.archived && (
                <span className="ml-2 rounded px-2.5 py-0.5 text-xs font-medium bg-gray-100 text-gray-800">
                  Archived
                </span>
              )}
            </div>

            <div className="flex -space-x-3">
              {data?.teamMembers?.map((member, index) => (
                <div
                  key={index}
                  className="h-6 w-6 rounded-full bg-blue-600 text-white flex items-center justify-center border border-white"
                  style={{ fontSize: "10px" }}
                  title={member.name}
                >
                  {getInitials(member?.name)}
                </div>
              ))}
            </div>
          </div>

          {/* Banner Section */}
          {data?.banner_url ? (
            <img
              src={data.banner_url}
              alt="Project Banner"
              className="object-cover w-full h-40 rounded-md mb-4"
            />
          ) : (
            <div className="w-full h-40 bg-gray-50 rounded-md flex items-center justify-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-10 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </div>
          )}

          {/* Project Title */}
          <h3 className="text-lg font-semibold leading-tight text-gray-900">
            {data?.name}
          </h3>

          {/* Client Information */}
          {data?.client && (
            <div>
              <p className="text-sm font-medium text-gray-600">
                Client: {data?.client?.name}
              </p>
            </div>
          )}

          {/* Providers */}
          {data?.providers && data?.providers?.length > 0 && (
            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-600">Providers:</h4>
              <ul className="text-sm text-gray-500">
                <li>
                  {data?.providers[0]?.name}
                  {data?.providers?.length > 1 &&
                    `, +${data?.providers?.length - 1}`}
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="bg-gray-50 border-t rounded-b-lg px-6 py-3 mt-auto">
          <p className="text-sm font-medium text-gray-600 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008h-.008v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
            {data?.creation_date
              ? new Date(data.creation_date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : "No date available"}
          </p>
        </div>
      </Link>

      {/* Three dots dropdown trigger */}
      {enableCopy && (
        <button
          className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 focus:outline-none z-20"
          onClick={toggleDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <circle cx="5" cy="12" r="2" />
            <circle cx="12" cy="12" r="2" />
            <circle cx="19" cy="12" r="2" />
          </svg>
        </button>
      )}

      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute top-10 right-2 bg-white border shadow-md rounded z-30"
        >
          <button
            onClick={handleArchiveProject}
            className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
            disabled={isUpdatingArchive}
          >
            {isUpdatingArchive
              ? "Updating..."
              : data.archived
              ? "Make Active"
              : "Archive"}
          </button>
          {/* Only show the copy option if the project is not archived */}
          {!data.archived && (
            <button
              onClick={handleCopyProject}
              className="w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
              disabled={isCopying}
            >
              {isCopying ? "Copying..." : "Create a Copy"}
            </button>
          )}
        </div>
      )}

      {/* Confirmation Modal */}
      <ConfirmModal
        show={showConfirmModal}
        title={modalTitle}
        message={modalMessage}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        isProcessing={isCopying || isUpdatingArchive}
      />
    </div>
  );
};

export default ProjectCard;
