import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import axiosInstance from "../axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch all expenses for the user's company with pagination
const fetchCompanyExpenses = async (page, perPage, search = "", token) => {
  const response = await axiosInstance.get(
    `${API_URL}/company/expenses?page=${page}&limit=${perPage}&search=${encodeURIComponent(
      search
    )}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch all expenses for a specific project with pagination
const fetchExpensesByProject = async (projectId, page, perPage, token) => {
  // Updated: use 'limit' instead of 'per_page' to match the endpoint.
  const response = await axiosInstance.get(
    `${API_URL}/projects/${projectId}/expenses?page=${page}&limit=${perPage}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch a single expense by ID
const fetchExpense = async (expenseId, token) => {
  const response = await axiosInstance.get(`${API_URL}/expenses/${expenseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Create a new expense
const createExpense = async ({ data, token }) => {
  const response = await axiosInstance.post(`${API_URL}/expenses`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Update an existing expense
const updateExpense = async ({ expenseId, data, token }) => {
  const response = await axiosInstance.put(
    `${API_URL}/expenses/${expenseId}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Delete an expense
const deleteExpense = async ({ expenseId, token }) => {
  await axiosInstance.delete(`${API_URL}/expenses/${expenseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// React Query hooks

// Fetch all expenses for the user's company
export const useCompanyExpenses = (page = 1, perPage = 10, search = "") => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["companyExpenses", page, perPage, search],
    () => fetchCompanyExpenses(page, perPage, search, user?.token),
    { enabled: !!user?.token }
  );
};

// Fetch expenses for a specific project
export const useExpensesByProject = (projectId, page = 1, perPage = 10) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["projectExpenses", projectId, page, perPage],
    () => fetchExpensesByProject(projectId, page, perPage, user?.token),
    { enabled: !!user?.token && !!projectId }
  );
};

// Fetch a single expense
export const useExpense = (expenseId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["expense", expenseId],
    () => fetchExpense(expenseId, user?.token),
    {
      enabled: !!user?.token && !!expenseId,
    }
  );
};

// Create a new expense
export const useCreateExpense = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation((data) => createExpense({ data, token: user?.token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("companyExpenses");
      queryClient.invalidateQueries("projectExpenses");
    },
  });
};

// Update an existing expense
export const useUpdateExpense = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ expenseId, data }) =>
      updateExpense({ expenseId, data, token: user?.token }),
    {
      onSuccess: (data, { expenseId }) => {
        queryClient.invalidateQueries(["expense", expenseId]);
        queryClient.invalidateQueries("companyExpenses");
        queryClient.invalidateQueries("projectExpenses");
      },
    }
  );
};

// Delete an expense
export const useDeleteExpense = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ expenseId }) => deleteExpense({ expenseId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("companyExpenses");
        queryClient.invalidateQueries("projectExpenses");
      },
    }
  );
};
