import React, { useState, useEffect, useRef } from "react";
import TaskModal from "./taskModal/TaskModal";
import gantt from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { format } from "date-fns";

const MyCustomModal = ({ task, onClose }) => {
  if (!task) return null;
  return <TaskModal taskId={task?.id} onClose={onClose} />;
};

const GanttView = ({ tasks = [], phases = [] }) => {
  const ganttContainerRef = useRef(null);

  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (taskId) => {
    if (!taskId) return;
    const task = gantt.getTask(taskId);
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  useEffect(() => {
    gantt.plugins({
      marker: true,
    });

    gantt.config.xml_date = "%Y-%m-%d";
    gantt.config.drag_links = false;
    gantt.config.drag_progress = false;
    gantt.config.drag_move = false;
    gantt.config.drag_resize = false;

    gantt.config.details_on_dblclick = false;
    gantt.config.details_on_create = false;
    gantt.config.show_task_cells = false;

    gantt.init(ganttContainerRef.current);

    gantt.attachEvent("onTaskDblClick", function (id, e) {
      openModal(id);
      return false;
    });
    gantt.attachEvent("onBeforeLightbox", function (id) {
      return false;
    });

    gantt.config.columns = [
      {
        name: "text",
        label: "Task Name",
        width: "*",
        min_width: 150,
        tree: true,
      },
      {
        name: "status",
        label: "Status",
        width: "*",
        min_width: 100,
        align: "center",
        template: function (task) {
          // existing status template logic remains unchanged...
          const labelMap = {
            todos: { text: "To Do's", colorClass: "bg-blue-100 text-blue-800" },
            inProgress: {
              text: "In Progress",
              colorClass: "bg-yellow-100 text-yellow-800",
            },
            "In Progress": {
              text: "In Progress",
              colorClass: "bg-yellow-100 text-yellow-800",
            },
            inReview: {
              text: "In Review",
              colorClass: "bg-purple-100 text-purple-800",
            },
            completed: {
              text: "Completed",
              colorClass: "bg-green-100 text-green-800",
            },
            Completed: {
              text: "Completed",
              colorClass: "bg-green-100 text-green-800",
            },
            Planned: {
              text: "Planned",
              colorClass: "bg-gray-100 text-gray-800",
            },
            Active: {
              text: "Active",
              colorClass: "bg-green-100 text-green-800",
            },
            Paused: {
              text: "Paused",
              colorClass: "bg-yellow-100 text-yellow-800",
            },
            Cancelled: {
              text: "Cancelled",
              colorClass: "bg-red-100 text-red-800",
            },
          };

          // PROJECT ROW: use custom label if available
          const projectStatus = labelMap[task?.projectStatus];
          if (task?.isProject && projectStatus) {
            return `<span class="px-2 py-1 rounded text-xs ${projectStatus?.colorClass}">${projectStatus?.text}</span>`;
          }

          const info = labelMap[task.status];
          if (info) {
            return `<span class="px-2 py-1 rounded text-xs ${info.colorClass}">
                  ${info.text}
                </span>`;
          }
          return "";
        },
      },
      {
        name: "start_date",
        label: "Start",
        width: "*",
        min_width: 100,
        align: "center",
      },
      {
        name: "end_date",
        label: "End",
        width: "*",
        min_width: 100,
        align: "center",
        template: function (task) {
          const today = new Date();
          const taskEndDate = new Date(task.end_date);

          if (
            !task.isProject &&
            !task.isPhase &&
            taskEndDate < today &&
            task.status &&
            task.status.toLowerCase() !== "completed"
          ) {
            return `<span class="text-red-600 font-semibold">${
              taskEndDate.toISOString().split("T")[0]
            }</span>`;
          }
          return task.end_date;
        },
      },
    ];

    // Assign CSS classes to bars
    const barClassMap = {
      todos: "status-todos",
      inProgress: "status-inProgress",
      inReview: "status-inReview",
      completed: "status-completed",
    };
    gantt.templates.task_class = function (start, end, task) {
      if (task.isProject) {
        return "status-project";
      }
      if (task.isPhase) {
        return "status-phase";
      }
      return barClassMap[task.status] || "";
    };

    // Add the TODAY marker
    gantt.addMarker({
      start_date: new Date(),
      css: "today_marker",
      text: "Today",
      title: "Today",
    });

    // Cleanup
    return () => {
      gantt.clearAll();
    };
  }, []);

  // -----------------------------------------------
  // 2) TRANSFORM YOUR TASKS + PHASES INTO GANTT DATA
  // -----------------------------------------------
  function transformToGanttData(tasks, phases) {
    // Sort tasks by status
    const statusOrder = {
      todos: 1,
      inProgress: 2,
      inReview: 3,
      completed: 4,
    };
    const sortedTasks = [...tasks].sort((a, b) => {
      return (statusOrder[a.status] || 999) - (statusOrder[b.status] || 999);
    });

    // Create a map of phases by ID
    const phaseMap = {};
    phases.forEach((p) => {
      phaseMap[String(p.id)] = p;
    });

    // Helper to generate a combined key
    function getProjPhaseKey(projectName, phaseKey) {
      return `${projectName}::${phaseKey}`;
    }

    // We'll group tasks by project -> phase
    const projects = {};
    // We'll store tallies of tasks per (project + phase)
    const projPhaseCounts = {};

    sortedTasks.forEach((task) => {
      const projectName = task.project_name || "Unspecified Project";
      const projectStatus = task.project_status || ""; // <-- ensure we capture this from the task
      const phaseKey =
        task.phase_id !== null && task.phase_id !== undefined
          ? String(task.phase_id)
          : "no-phase";

      // If this is the first time we're seeing this project, initialize
      if (!projects[projectName]) {
        projects[projectName] = {
          projectStatus, // store the project status here
          tasksByPhase: {},
        };
      }

      // Tally the counts
      const comboKey = getProjPhaseKey(projectName, phaseKey);
      if (!projPhaseCounts[comboKey]) {
        projPhaseCounts[comboKey] = { total: 0, completed: 0 };
      }
      projPhaseCounts[comboKey].total++;
      if (task.status === "completed") {
        projPhaseCounts[comboKey].completed++;
      }

      // Group tasks under the (project + phase)
      if (!projects[projectName].tasksByPhase[phaseKey]) {
        projects[projectName].tasksByPhase[phaseKey] = [];
      }
      projects[projectName].tasksByPhase[phaseKey].push(task);
    });

    // Build the Gantt data tree
    let ganttTasks = [];
    let autoId = 100000; // incremental ID for phases, etc.

    Object.keys(projects).forEach((projectName) => {
      const { projectStatus, tasksByPhase } = projects[projectName];

      // Top-level "project" node
      const projectId = autoId++;
      ganttTasks.push({
        id: projectId,
        text: projectName,
        parent: 0,
        type: "project",
        isProject: true,

        // IMPORTANT: This matches what your column template checks:
        projectStatus: projectStatus,
      });

      // For each phase in that project
      Object.keys(tasksByPhase).forEach((phaseKey) => {
        // Phase name or fallback
        let phaseName = "No Phase";
        if (phaseKey !== "no-phase" && phaseMap[phaseKey]) {
          phaseName = phaseMap[phaseKey].name;
        }

        // Tally
        const comboKey = getProjPhaseKey(projectName, phaseKey);
        const { total = 0, completed = 0 } = projPhaseCounts[comboKey] || {};

        // "Phase" node
        const thisPhaseId = autoId++;
        ganttTasks.push({
          id: thisPhaseId,
          text: phaseName,
          parent: projectId,
          type: "phase",
          isPhase: true,
          completedCount: completed,
          totalCount: total,
        });

        // Add tasks for this (project + phase)
        const tasksInPhase = tasksByPhase[phaseKey];
        tasksInPhase.forEach((taskItem) => {
          const startDateObj = parseDateOrDefault(
            taskItem.initial_date ||
              taskItem.start_date ||
              taskItem.creation_date
          );
          const endDateObj = parseDateOrDefault(
            taskItem.end_date,
            startDateObj
          );

          ganttTasks.push({
            id: taskItem.id,
            text: taskItem.title || "",
            start_date: toYmd(startDateObj),
            end_date: toYmd(endDateObj),
            parent: thisPhaseId,
            status: taskItem.status || "",
            phase_id: taskItem.phase_id,
            project_name: taskItem.project_name,
          });
        });
      });
    });

    return { data: ganttTasks, links: [] };
  }

  // Simple date helpers
  function parseDateOrDefault(rawDate, fallbackDate) {
    let dateObj = rawDate ? new Date(rawDate) : null;
    if (!dateObj || isNaN(dateObj.getTime())) {
      dateObj = fallbackDate instanceof Date ? fallbackDate : new Date();
    }
    return dateObj;
  }
  function toYmd(dateObj) {
    return `${dateObj.toISOString().slice(0, 10)}`;
  }

  // -----------------------------------------------------
  // 3) PARSE DATA + SCROLL TO "TODAY" WHEN TASKS CHANGE
  // -----------------------------------------------------
  useEffect(() => {
    if (!ganttContainerRef.current) return;
    // Clear and parse new data
    gantt.clearAll();
    const ganttData = transformToGanttData(tasks, phases);
    gantt.parse(ganttData);

    // Re-add the marker here, after parse
    gantt.addMarker({
      start_date: new Date(),
      css: "today_marker",
      text: "Today",
      title: "Today",
    });

    // Make sure we see the today's marker
    gantt.showDate(new Date());
  }, [tasks, phases]);

  return (
    <div>
      <div
        ref={ganttContainerRef}
        style={{ width: "100%", height: "1000px" }}
      />
      {isModalOpen && (
        <MyCustomModal task={selectedTask} onClose={closeModal} />
      )}
    </div>
  );
};

export default GanttView;
