import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/creatable";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  useDeleteProvider,
  useUpdateProvider,
} from "../../../hooks/useProviders/useProviders";
import { formatPhoneNumber, getWhatsAppLink } from "../../../utils/helpers";
import { AuthContext } from "../../../context/AuthProvider";
import SidebarDropdown from "../../../components/common/buttons/SidebarDropdown";

const ProviderDetails = ({ data, closeSidebar }) => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;
  const [isEditing, setIsEditing] = useState(false);

  // We'll store the mutable provider data here while editing.
  const [providerData, setProviderData] = useState(data);
  // Keep a copy of the initial data to revert changes on Cancel
  const [initialProviderData, setInitialProviderData] = useState(data);

  const [error, setError] = useState({});
  const [defaultCountry, setDefaultCountry] = useState("us");
  const [newServiceOptions, setNewServiceOptions] = useState([]);
  const navigate = useNavigate();

  const deleteProvider = useDeleteProvider();
  const updateProvider = useUpdateProvider();

  // On mount (and whenever `data` changes), update local states
  useEffect(() => {
    setProviderData(data);
    setInitialProviderData(data);

    // Infer default country from browser locale
    const getDefaultCountry = () => {
      const locale = navigator.language || navigator.userLanguage;
      const country = locale.split("-")[1]?.toLowerCase();
      if (country) setDefaultCountry(country);
    };
    getDefaultCountry();

    // Convert existing services object into react-select format
    const serviceOptions = Object.keys(data.services || {}).map((service) => ({
      label: service,
      value: service,
    }));
    setNewServiceOptions(serviceOptions);
  }, [data]);

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this partner?")) {
      try {
        await deleteProvider.mutateAsync({ providerId: providerData.id });
        closeSidebar();
        navigate("/partners");
      } catch (err) {
        setError({ submit: "Failed to delete provider. Please try again." });
      }
    }
  };

  // Input handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProviderData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setProviderData((prevData) => ({ ...prevData, phone: value }));
  };

  const handleServiceChange = (selectedServices) => {
    // Convert selected services to an object
    const updatedServices = {};
    selectedServices.forEach((s) => {
      updatedServices[s.value] = true;
    });
    setProviderData((prevData) => ({ ...prevData, services: updatedServices }));
    setNewServiceOptions(selectedServices);
  };

  // Validation: required fields + optional fields only if they have values
  const validateInputs = () => {
    const errors = {};

    // Required
    if (!providerData.contact_name?.trim()) {
      errors.contact_name = "Contact name is required.";
    }
    if (!providerData.company_name?.trim()) {
      errors.company_name = "Company name is required.";
    }
    if (!providerData.country) {
      errors.country = "Country is required.";
    }
    if (!providerData.city) {
      errors.city = "City is required.";
    }

    // Optional: validate only if user entered a value
    if (providerData.email && !/^\S+@\S+\.\S+$/.test(providerData.email)) {
      errors.email = "Enter a valid email.";
    }
    if (
      providerData.website &&
      !/^https?:\/\/\S+$/.test(providerData.website)
    ) {
      errors.website = "Enter a valid URL.";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateInputs()) return;

    try {
      await updateProvider.mutateAsync({
        providerId: providerData.id,
        data: providerData,
      });
      // Update "initialProviderData" to reflect fresh updates
      setInitialProviderData(providerData);
      setIsEditing(false);
    } catch (err) {
      setError({ submit: "Failed to update provider. Please try again." });
    }
  };

  const handleCancelEdit = () => {
    setProviderData(initialProviderData);
    setIsEditing(false);
    setError({});
  };

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div
          className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg"
          role="alert"
        >
          {error.submit}
        </div>
      )}

      {isEditing ? (
        // EDIT MODE
        <div className="space-y-4">
          {/* Contact Name (Required) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Contact Name
            </label>
            <input
              type="text"
              name="contact_name"
              value={providerData.contact_name || ""}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Contact Name"
            />
            {error.contact_name && (
              <div className="text-red-500 text-sm mt-1">
                {error.contact_name}
              </div>
            )}
          </div>

          {/* Company Name (Required) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Company Name
            </label>
            <input
              type="text"
              name="company_name"
              value={providerData.company_name || ""}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Company Name"
            />
            {error.company_name && (
              <div className="text-red-500 text-sm mt-1">
                {error.company_name}
              </div>
            )}
          </div>

          {/* Phone (Optional) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone (Optional)
            </label>
            <PhoneInput
              country={defaultCountry}
              value={providerData.phone || ""}
              onChange={handlePhoneChange}
              placeholder="Provider Phone"
              inputClass="w-full border p-2 rounded"
              inputStyle={{ width: "inherit" }}
              enableSearch={true}
            />
          </div>

          {/* Email (Optional) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email (Optional)
            </label>
            <input
              type="email"
              name="email"
              value={providerData.email || ""}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Provider Email"
            />
            {error.email && (
              <div className="text-red-500 text-sm mt-1">{error.email}</div>
            )}
          </div>

          {/* Website (Optional) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Website (Optional)
            </label>
            <input
              type="url"
              name="website"
              value={providerData.website || ""}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Website URL"
            />
            {error.website && (
              <div className="text-red-500 text-sm mt-1">{error.website}</div>
            )}
          </div>

          {/* Country (Required) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Country
            </label>
            <CountryDropdown
              value={providerData.country || ""}
              onChange={(val) =>
                setProviderData((prevData) => ({ ...prevData, country: val }))
              }
              className="border p-2 w-full rounded"
            />
            {error.country && (
              <div className="text-red-500 text-sm mt-1">{error.country}</div>
            )}
          </div>

          {/* City (Required) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              City
            </label>
            <RegionDropdown
              country={providerData.country || ""}
              value={providerData.city || ""}
              onChange={(val) =>
                setProviderData((prevData) => ({ ...prevData, city: val }))
              }
              className="border p-2 w-full rounded"
            />
            {error.city && (
              <div className="text-red-500 text-sm mt-1">{error.city}</div>
            )}
          </div>

          {/* Services (Optional) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Services (Optional)
            </label>
            <CreatableSelect
              isMulti
              value={newServiceOptions}
              onChange={handleServiceChange}
              placeholder="Select or type to add services..."
              className="mt-2"
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: "#d1d5db",
                  boxShadow: "none",
                  "&:hover": { borderColor: "#d1d5db" },
                }),
              }}
              noOptionsMessage={() => "Type to add a new service"}
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            />
          </div>

          {/* Save / Cancel Buttons */}
          <div className="mt-4 flex space-x-4">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Save
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          {/* Header: Contact, Company & Options */}
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900">
                {providerData.contact_name}
              </h3>
              <p className="text-sm text-gray-600">
                {providerData.company_name}
              </p>
            </div>

            {/* Options Dropdown */}
            {userRoleActionEnabled && (
              <SidebarDropdown
                onEdit={handleEditToggle}
                onDelete={handleDelete}
              />
            )}
          </div>

          {/* Provider Fields */}
          <dl className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
            <div>
              <dt className="text-sm font-medium text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {providerData?.phone ? (
                  <a
                    href={getWhatsAppLink(providerData?.phone)}
                    className="text-green-600 hover:underline block flex"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      fill="currentColor"
                      className="size-4 mr-1 text-green-600"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                    {formatPhoneNumber(providerData?.phone)}
                  </a>
                ) : (
                  "---"
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {providerData.email ? (
                  <a
                    href={`mailto:${providerData.email}`}
                    className="block w-full truncate text-blue-600 hover:underline"
                    title={providerData.email}
                  >
                    {providerData.email}
                  </a>
                ) : (
                  "---"
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Website</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {providerData.website ? (
                  <a
                    href={providerData.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-600"
                  >
                    {providerData.website}
                  </a>
                ) : (
                  "---"
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Country</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {providerData.country || "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">City</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {providerData.city || "---"}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Services</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {Object.keys(providerData.services || {}).length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {Object.keys(providerData.services).map(
                      (service, index) => (
                        <span
                          key={index}
                          className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-md"
                        >
                          {service}
                        </span>
                      )
                    )}
                  </div>
                ) : (
                  <p className="text-gray-600">No services added</p>
                )}
              </dd>
            </div>
          </dl>
        </div>
      )}
    </div>
  );
};

export default ProviderDetails;
