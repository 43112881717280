import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const formatSegment = (segment) => {
    if (segment === "projects") return "Projects";
    if (segment === "create-file") return "Create File"; // Special case for "create-file"
    return segment
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getBreadcrumbName = (segment, index) => {
    const isProjectDetails =
      pathnames.length === 2 && // Exactly two path segments
      pathnames[0] === "projects"; // First segment is "projects"

    const isCreateFile =
      pathnames.length === 3 && // Exactly three path segments
      pathnames[0] === "projects" && // First segment is "projects"
      pathnames[2] === "create-file"; // Last segment is "create-file"

    const isCreateFolder =
      pathnames.length === 3 &&
      pathnames[0] === "projects" &&
      pathnames[2] === "create-folder";

    if (isProjectDetails && index === 1) {
      return "Details";
    }

    if (isCreateFile) {
      if (index === 1) {
        return "Details";
      }
      if (index === 2) {
        return "Create File";
      }
    }

    if (isCreateFolder) {
      if (index === 1) {
        return "Details";
      }
      if (index === 2) {
        return "Create Folder";
      }
    }

    return formatSegment(segment);
  };

  return (
    <div className="fixed z-10 inset-x-0 top-14 flex h-12 items-center justify-between px-4 sm:px-6 lg:px-8 lg:left-72 bg-white">
      <nav className="flex items-center space-x-2 text-gray-700 text-sm">
        <Link to="/dashboard" className="text-blue-600 hover:underline">
          Home
        </Link>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const breadcrumbName = getBreadcrumbName(value, index);

          return (
            <div key={to} className="flex items-center">
              <span className="mx-2">/</span>
              {index === pathnames.length - 1 ? (
                <span>{breadcrumbName}</span>
              ) : (
                <Link to={to} className="text-blue-600 hover:underline">
                  {breadcrumbName}
                </Link>
              )}
            </div>
          );
        })}
      </nav>
      <div className="absolute inset-x-0 top-full h-px transition bg-zinc-900/10"></div>
    </div>
  );
};

export default Breadcrumb;
