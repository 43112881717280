import React from "react";

const RoleDetails = ({ role }) => {
  const roleData = {
    0: {
      title: "Admin Privileges",
      description: "Admin has full access to everything.",
      bg: "bg-green-50 border border-green-200",
      permissions: [
        "Full control over company settings and integrations.",
        "Manage users, projects, files, tasks, invoices, expenses, clients, and providers.",
        "Can create and delete anything.",
      ],
    },
    1: {
      title: "Manager Privileges",
      description:
        "Managers can create and manage most resources but have some restrictions.",
      bg: "bg-blue-50 border border-blue-200",
      permissions: [
        "Can create users, projects, files, tasks, invoices, expenses, clients, and providers.",
        "Cannot create integrations or modify company settings.",
      ],
    },
    2: {
      title: "Designer Privileges",
      description:
        "Designers have limited access focused on projects and tasks.",
      bg: "bg-purple-50 border border-purple-200",
      permissions: [
        "Access to projects, files, and tasks only.",
        "Cannot see invoices or expenses.",
        "Cannot edit company settings or user management.",
      ],
    },
  };

  const roleInfo = roleData[role];

  if (!roleInfo) return null;

  return (
    <div className={`mt-4 p-4 rounded-lg shadow-md mb-4 ${roleInfo.bg}`}>
      <div className="flex items-center gap-3">
        <h3 className="font-semibold text-lg">{roleInfo.title}</h3>
      </div>
      <p className="text-gray-700 mt-2">{roleInfo.description}</p>
      <ul className="mt-3 text-gray-600 list-disc pl-5">
        {roleInfo.permissions.map((permission, index) => (
          <li key={index}>{permission}</li>
        ))}
      </ul>
    </div>
  );
};

export default RoleDetails;
