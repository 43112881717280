import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateProvider } from "../../../hooks/useProviders/useProviders";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import PhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/creatable";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { AuthContext } from "../../../context/AuthProvider";

const CreateProvider = () => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;

  const [formData, setFormData] = useState({
    contact_name: "",
    company_name: "",
    phone: "",
    email: "",
    website: "",
    country: "",
    city: "",
    services: {},
  });
  const [error, setError] = useState({});
  const [defaultCountry, setDefaultCountry] = useState("us");
  const [serviceOptions, setServiceOptions] = useState([]);
  const createProvider = useCreateProvider();
  const navigate = useNavigate();

  // Set default country based on browser locale
  useEffect(() => {
    const getDefaultCountry = () => {
      const locale = navigator.language || navigator.userLanguage;
      const country = locale.split("-")[1]?.toLowerCase();
      if (country) {
        setDefaultCountry(country);
      }
    };
    getDefaultCountry();
  }, []);

  // Generic input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle phone with react-phone-input-2
  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({ ...prevData, phone: value }));
  };

  // Handle multi-select for services
  const handleServiceChange = (selectedOptions) => {
    const updatedServices = {};
    selectedOptions.forEach((option) => {
      updatedServices[option.value] = true;
    });
    setFormData((prevData) => ({ ...prevData, services: updatedServices }));
    setServiceOptions(selectedOptions);
  };

  // Validate only required fields and any optional fields if they have values
  const validateInputs = () => {
    const errors = {};

    // Required fields
    if (!formData.contact_name.trim()) {
      errors.contact_name = "Provider name is required.";
    }
    if (!formData.company_name.trim()) {
      errors.company_name = "Company name is required.";
    }
    if (!formData.country) {
      errors.country = "Country is required.";
    }
    if (!formData.city) {
      errors.city = "City is required.";
    }

    // Optional fields (validate only if they have a value)
    if (formData.email && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Enter a valid email.";
    }
    if (formData.website && !/^https?:\/\/\S+$/.test(formData.website)) {
      errors.website = "Enter a valid URL.";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({}); // clear previous errors

    if (!validateInputs()) return;

    try {
      await createProvider.mutateAsync(formData);
      navigate("/partners");
    } catch (err) {
      setError({ submit: "Failed to create provider. Please try again." });
    }
  };

  useEffect(() => {
    if (!userRoleActionEnabled) {
      navigate("/partners");
    }
  }, [userRoleActionEnabled, navigate]);

  return (
    <div>
      <PageHeadings title="Add New Partner" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {/* Submission error */}
          {error.submit && (
            <div
              className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div>{error.submit}</div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Provider Name (Required) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Provider Name
              </label>
              <input
                type="text"
                name="contact_name"
                placeholder="Provider Name"
                value={formData.contact_name}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.contact_name && (
                <div className="text-red-500 text-sm mt-1">
                  {error.contact_name}
                </div>
              )}
            </div>

            {/* Company Name (Required) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Company Name
              </label>
              <input
                type="text"
                name="company_name"
                placeholder="Company Name"
                value={formData.company_name}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.company_name && (
                <div className="text-red-500 text-sm mt-1">
                  {error.company_name}
                </div>
              )}
            </div>

            {/* Phone (Optional) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone (Optional)
              </label>
              <PhoneInput
                country={defaultCountry}
                value={formData.phone}
                onChange={handlePhoneChange}
                placeholder="Provider Phone"
                inputClass="w-full border p-2 rounded"
                enableSearch={true}
              />
            </div>

            {/* Email (Optional) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email (Optional)
              </label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.email && (
                <div className="text-red-500 text-sm mt-1">{error.email}</div>
              )}
            </div>

            {/* Website (Optional) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Website (Optional)
              </label>
              <input
                type="url"
                name="website"
                placeholder="Website URL"
                value={formData.website}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.website && (
                <div className="text-red-500 text-sm mt-1">{error.website}</div>
              )}
            </div>

            {/* Country (Required) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <CountryDropdown
                value={formData.country}
                onChange={(val) => setFormData({ ...formData, country: val })}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.country && (
                <div className="text-red-500 text-sm mt-1">{error.country}</div>
              )}
            </div>

            {/* City (Required) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                City
              </label>
              <RegionDropdown
                country={formData.country}
                value={formData.city}
                onChange={(val) => setFormData({ ...formData, city: val })}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.city && (
                <div className="text-red-500 text-sm mt-1">{error.city}</div>
              )}
            </div>

            {/* Services (Optional) */}
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Services (Optional)
              </label>
              <CreatableSelect
                isMulti
                value={serviceOptions}
                onChange={handleServiceChange}
                placeholder="Select or type to add services..."
                className="max-w-md"
              />
            </div>

            {/* Submit */}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Partner
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateProvider;
