import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import PageHeadings from "../common/pageHeadings/PageHeadings";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import MainStats from "./dashboardAnalytics/MainStats";
import { useProjects } from "../../hooks/useProjects/useProjects";
import LoadingPage from "../common/empty/LoadingPage";
import ErrorMessage from "../common/empty/ErrorMessage";
import { useCompanyInfo } from "../../hooks/useCompany";
import { Link, NavLink } from "react-router-dom";
import OnboardingSteps from "./OnboardingSteps";
import { useTranslation } from "react-i18next";

// Register Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DashboardContent = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;
  const companyId = user?.company_id;
  const { data, isLoading, isError } = useProjects(1, 1);
  const {
    data: companyInfo,
    isLoading: companyIsLoading,
    isError: companyIsError,
  } = useCompanyInfo(companyId);

  return (
    <div>
      <PageHeadings
        title={t("home.welcome", { username: user?.username })}
        tags={[]}
        showCta={false}
      />

      {/* Onboarding Steps */}
      {userRoleActionEnabled && <OnboardingSteps />}

      {isLoading || companyIsLoading ? (
        <LoadingPage />
      ) : isError || companyIsError ? (
        <ErrorMessage />
      ) : (
        <Analytics data={data} companyInfo={companyInfo} />
      )}
    </div>
  );
};

export default DashboardContent;

const Analytics = ({ data, companyInfo }) => {
  return (
    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
      <MainStats data={data} companyInfo={companyInfo} />
      <ScheduleSupportCard />
      <TutorialCard />
    </div>
  );
};

const TutorialCard = () => {
  const { t } = useTranslation();
  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-1 flex flex-col h-full">
      <div className="flex-1">
        <h3 className="text-md font-semibold text-gray-700 mb-2">
          {t("home.tutorial.title")}
        </h3>
        <p className="text-sm text-gray-600 mb-4">
          {t("home.tutorial.description")}
        </p>
        <div className="relative w-full aspect-video rounded-lg border border-gray-200 overflow-hidden">
          <iframe
            src="https://drive.google.com/file/d/1xoxyP2PYZX2s8hT-5gwOAIRL9Xjs4WL_/preview"
            title="Video Tutorial"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            className="absolute inset-0 w-full h-full"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

const ScheduleSupportCard = () => {
  const { t } = useTranslation();
  const calendlyLink = "https://calendar.app.google/VRyag6a5opnk3dEw5";

  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-1 flex flex-col max-h-40">
      <div className="flex-1">
        <h3 className="text-md font-semibold text-gray-700">
          {t("home.support.title")}
        </h3>
        <p className="text-sm text-gray-600 my-2">
          {t("home.support.description")}
        </p>
      </div>
      <div className="mt-auto">
        <Link
          to={calendlyLink}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 transition duration-200"
        >
          {t("home.support.cta")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5 ml-2 -mr-1"
          >
            <path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
          </svg>
        </Link>
      </div>
    </div>
  );
};

const TradePortalCard = () => {
  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-1 flex flex-col h-full">
      <div className="flex-1">
        <h3 className="text-md font-semibold text-gray-700">
          Explore Trade Portal
        </h3>
        <p className="text-sm text-gray-600 my-2">
          Discover exclusive discounts from providers for your interior design
          projects.
        </p>
      </div>
      <div className="mt-auto">
        <NavLink
          to="/trade-portal"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 transition duration-200"
        >
          Visit Trade Portal
        </NavLink>
      </div>
    </div>
  );
};
