import React from "react";

const NoFilesMessage = () => (
  <div className="my-10 flex items-center justify-center">
    <div className="max-w-xl w-full border-2 border-dashed border-slate-300 rounded-lg bg-slate-50 p-10 flex flex-col items-center justify-center text-center">
      <h2 className="text-xl font-bold text-gray-800 mb-4">No Files Added</h2>
      <p className="text-base text-gray-600 leading-relaxed">
        No files have been added to your client dashboard. Contact your studio
        design team to add files if needed.
      </p>
    </div>
  </div>
);

export default NoFilesMessage;
