export const InvoiceItems = ({
  items,
  errors,
  onItemChange,
  onAddItem,
  onRemoveItem,
}) => {
  const userLocale = navigator.language || "en-US";
  const userCurrency = "COP";

  return (
    <div className="space-y-2">
      <label className="block text-sm font-semibold text-gray-700">Items</label>
      {/* Responsive Wrapper for table */}
      <div className="relative overflow-x-auto rounded-md">
        <table className="w-full text-sm text-left text-gray-600">
          <thead className="bg-gray-100 text-xs uppercase text-gray-700 border-b">
            <tr>
              <th scope="col" className="px-4 py-3">
                Item Name
              </th>
              <th scope="col" className="px-4 py-3">
                Quantity
              </th>
              <th scope="col" className="px-4 py-3">
                Price
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(items).map(([itemId, item]) => (
              <tr key={itemId} className="border-b last:border-0">
                <td className="px-4 py-3">
                  <input
                    type="text"
                    placeholder="Item Name"
                    value={item.name}
                    onChange={(e) =>
                      onItemChange(itemId, "name", e.target.value)
                    }
                    className={`min-w-[180px] block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
                      errors[`itemName${itemId}`] ? "ring-1 ring-red-500" : ""
                    }`}
                  />
                  {errors[`itemName${itemId}`] && (
                    <p className="mt-1 text-xs text-red-500">
                      {errors[`itemName${itemId}`]}
                    </p>
                  )}
                  <input
                    type="text"
                    placeholder="Description (Optional)"
                    value={item.description || ""}
                    onChange={(e) =>
                      onItemChange(itemId, "description", e.target.value)
                    }
                    className="min-w-[180px] mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                </td>
                <td className="px-4 py-3">
                  <input
                    type="number"
                    placeholder="Quantity"
                    min="0"
                    value={item.quantity || ""}
                    onChange={(e) =>
                      onItemChange(itemId, "quantity", e.target.value)
                    }
                    className={`min-w-[180px] block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
                      errors[`itemQuantity${itemId}`]
                        ? "ring-1 ring-red-500"
                        : ""
                    }`}
                  />
                  {errors[`itemQuantity${itemId}`] && (
                    <p className="mt-1 text-xs text-red-500">
                      {errors[`itemQuantity${itemId}`]}
                    </p>
                  )}
                </td>
                <td className="px-4 py-3">
                  <input
                    type="text"
                    placeholder="Item Value"
                    value={
                      item.value !== undefined && item.value !== ""
                        ? new Intl.NumberFormat(userLocale, {
                            currency: userCurrency,
                            maximumFractionDigits: 0,
                          }).format(item.value)
                        : ""
                    }
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/[^\d.]/g, "");
                      if (rawValue === "" || isNaN(rawValue)) return;
                      onItemChange(itemId, "value", parseFloat(rawValue));
                    }}
                    onBlur={() => {
                      const numericValue = parseFloat(item.value || 0).toFixed(
                        0
                      );
                      onItemChange(itemId, "value", numericValue);
                    }}
                    className={`min-w-[180px] block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
                      errors[`itemValue${itemId}`] ? "ring-1 ring-red-500" : ""
                    }`}
                  />
                  {errors[`itemValue${itemId}`] && (
                    <p className="mt-1 text-xs text-red-500">
                      {errors[`itemValue${itemId}`]}
                    </p>
                  )}
                </td>
                <td className="px-4 py-3 text-center">
                  <button
                    type="button"
                    onClick={() => onRemoveItem(itemId)}
                    className="inline-block rounded bg-red-50 px-3 py-1 text-xs font-medium text-red-600 transition hover:bg-red-100"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        onClick={onAddItem}
        className="mt-2 inline-block text-sm font-medium text-blue-600 hover:text-blue-800 hover:underline"
      >
        + Add Item
      </button>
    </div>
  );
};
