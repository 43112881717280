export const USER_TABLE_COLUMNS = [
  "Customer",
  "Owner",
  "Projects",
  "Invoices",
  "Status",
];
export const PROVIDERS_TABLE_COLUMNS = [
  "Main Contact",
  "Business",
  "Location",
  "Services",
];
export const PURCHASE_ORDERS_TABLE_COLUMNS = [
  "Order",
  "Date",
  "Provider",
  "Total",
  "Status",
  "Items",
  "Payment Type",
];
export const INVOICES_TABLE_COLUMNS = [
  "Invoice",
  "Discount",
  "Total",
  "Status",
  "Customer",
  "Issued On",
  "Due Date",
];

export const INVOICES_MIN_TABLE_COLUMNS = [
  "Invoice",
  "Discount",
  "Total",
  "Issued On",
  "Paid On",
];
