import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import { InvoiceItems } from "../../../components/common/inputs/InvoiceItems";

import { useCreateInvoice } from "../../../hooks/useInvoices/useInvoices";
import { useProjects } from "../../../hooks/useProjects/useProjects";
import { useClients } from "../../../hooks/useClients/useClients";
import { AuthContext } from "../../../context/AuthProvider";
import { useTaxes } from "../../../hooks/useTaxes/useTaxes";

import "react-phone-input-2/lib/style.css";

const CreateInvoice = () => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;
  const location = useLocation();
  const paramProjectId = new URLSearchParams(location.search).get("projectId");
  const navigate = useNavigate();

  useEffect(() => {
    if (!userRoleActionEnabled) {
      navigate("/invoices");
    }
  }, [userRoleActionEnabled, navigate]);

  const [formData, setFormData] = useState({
    items: {},
    total_amount: 0,
    discount_type: "percentage",
    discount_value: 0,
    final_amount: 0,
    due_date: "",
    status: "Pending",
    notes: "",
    terms: "",
    project_id: paramProjectId || "",
    client_id: "",
    tax_id: "",
  });

  const [selectedTax, setSelectedTax] = useState(null);
  const [error, setError] = useState({});
  const createInvoice = useCreateInvoice();

  const { data: projectsData } = useProjects(1, 10000);
  const { data: clientsData } = useClients(1, 10000);
  const { data: taxesData = [] } = useTaxes(false);

  // Build select options
  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];

  const clientOptions =
    clientsData?.clients.map((client) => ({
      value: client.id,
      label: client.name,
    })) || [];

  const taxOptions = taxesData.map((tax) => ({
    value: tax.id,
    label: `${tax.name} (${tax.rate}%)`,
    tax_type: tax.tax_type,
    rate: parseFloat(tax.rate),
  }));

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle item changes
  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? Math.max(1, parseFloat(value))
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };
      return { ...prevData, items: updatedItems };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: { name: "", quantity: 1, value: 0 },
      },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];
      return { ...prevData, items: updatedItems };
    });
  };

  // Recalculate totals on items, discount, or tax changes.
  useEffect(() => {
    const total = Object.values(formData.items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );

    const discount =
      formData.discount_type === "percentage"
        ? (total * parseFloat(formData.discount_value)) / 100
        : parseFloat(formData.discount_value) || 0;

    let final = Math.max(total - discount, 0);

    // Add tax on top if a tax is selected.
    if (selectedTax) {
      final += total * (selectedTax.rate / 100);
    }

    // Only update state if the computed values are different.
    setFormData((prevData) => {
      if (prevData.total_amount === total && prevData.final_amount === final) {
        return prevData;
      }
      return { ...prevData, total_amount: total, final_amount: final };
    });
  }, [
    formData.items,
    formData.discount_value,
    formData.discount_type,
    selectedTax,
  ]);

  // Validate before submit
  const validateInputs = () => {
    const errors = {};

    if (!formData.due_date.trim()) {
      errors.due_date = "Due date is required.";
    }
    if (formData.total_amount <= 0) {
      errors.total_amount = "Total amount must be greater than 0.";
    }

    // Validate items
    for (let itemId in formData.items) {
      const item = formData.items[itemId];
      if (!item.name.trim()) {
        errors[`itemName${itemId}`] = "Item name is required.";
      }
      if (!item.value || isNaN(item.value) || item.value <= 0) {
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
      }
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    if (!validateInputs()) return;

    // Recompute final values right before submit.
    const total = Object.values(formData.items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );
    const discount =
      formData.discount_type === "percentage"
        ? (total * parseFloat(formData.discount_value)) / 100
        : parseFloat(formData.discount_value) || 0;

    let final = Math.max(total - discount, 0);

    if (selectedTax) {
      final += total * (selectedTax.rate / 100);
    }

    const updatedFormData = {
      ...formData,
      total_amount: total,
      final_amount: final,
      tax_id: selectedTax ? selectedTax.value : "",
    };

    try {
      await createInvoice.mutateAsync(updatedFormData);
      const redirectUrl = new URLSearchParams(location.search).get("redirect")
        ? `${new URLSearchParams(location.search).get("redirect")}?view=finance`
        : "/invoices";
      navigate(redirectUrl);
    } catch (err) {
      setError({ submit: "Failed to create invoice. Please try again." });
    }
  };

  return (
    <div className="container mx-auto">
      <PageHeadings title="Create Invoice" tags={[]} showCta={false} />

      <DefaultCard>
        <div className="p-4 bg-white rounded-md shadow-sm">
          {error.submit && (
            <div className="mb-3 rounded-md bg-red-50 p-3 text-red-600">
              {error.submit}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6 text-sm">
            {/* Invoice Info */}
            <div className="max-w-2xl">
              <div className="mb-2 font-bold text-gray-700">Invoice Info</div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {/* Status */}
                <div>
                  <label htmlFor="status" className="block text-gray-700">
                    Status
                  </label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Paid">Paid</option>
                    <option value="Overdue">Overdue</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>

                {/* Due Date */}
                <div>
                  <label htmlFor="due_date" className="block text-gray-700">
                    Due Date
                  </label>
                  <input
                    type="date"
                    id="due_date"
                    name="due_date"
                    value={formData.due_date}
                    onChange={handleChange}
                    className={`mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                      error.due_date ? "border-red-500" : ""
                    }`}
                  />
                  {error.due_date && (
                    <p className="mt-1 text-xs text-red-600">
                      {error.due_date}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Client & Project */}
            <div className="max-w-2xl">
              <div className="mb-2 font-bold text-gray-700">
                Client &amp; Project
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {/* Client */}
                <div>
                  <label className="block text-gray-700">Client</label>
                  <AsyncSelect
                    className="mt-1 text-sm"
                    cacheOptions
                    defaultOptions={clientOptions}
                    isClearable
                    placeholder="Select a client"
                    onChange={(selected) =>
                      setFormData((prev) => ({
                        ...prev,
                        client_id: selected ? selected.value : "",
                      }))
                    }
                    loadOptions={(inputValue, callback) => {
                      callback(
                        clientOptions.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                      );
                    }}
                  />
                </div>

                {/* Project (only if no paramProjectId) */}
                {!paramProjectId && (
                  <div>
                    <label className="block text-gray-700">
                      Project (Optional)
                    </label>
                    <AsyncSelect
                      className="mt-1 text-sm"
                      cacheOptions
                      defaultOptions={projectOptions}
                      isClearable
                      placeholder="Select a project"
                      onChange={(selected) =>
                        setFormData((prev) => ({
                          ...prev,
                          project_id: selected ? selected.value : "",
                        }))
                      }
                      loadOptions={(inputValue, callback) => {
                        callback(
                          projectOptions.filter((option) =>
                            option.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Items */}
            <div>
              <InvoiceItems
                items={formData.items}
                errors={error}
                onItemChange={handleItemChange}
                onAddItem={addItem}
                onRemoveItem={removeItem}
              />
            </div>

            {/* Totals, Discounts & Tax */}
            <div className="max-w-xs">
              <div className="mb-2 font-bold text-gray-700">
                Totals, Discounts &amp; Tax
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
                {/* Tax */}
                <div>
                  <label className="block text-gray-700">Tax (Optional)</label>
                  <AsyncSelect
                    className="mt-1 text-sm"
                    cacheOptions
                    defaultOptions={taxOptions}
                    isClearable
                    placeholder="Select a tax option"
                    value={selectedTax}
                    onChange={(selected) => {
                      setSelectedTax(selected);
                      setFormData((prev) => ({
                        ...prev,
                        tax_id: selected ? selected.value : "",
                      }));
                    }}
                  />
                </div>

                {/* Discount Type */}
                <div>
                  <label className="block text-gray-700">Discount Type</label>
                  <select
                    name="discount_type"
                    value={formData.discount_type}
                    onChange={handleChange}
                    className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="percentage">Percentage</option>
                    <option value="value">Value</option>
                  </select>
                </div>

                {/* Discount Value */}
                <div>
                  <label className="block text-gray-700">Discount Value</label>
                  <input
                    type="number"
                    name="discount_value"
                    value={formData.discount_value}
                    onChange={handleChange}
                    className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>

                {/* Total Amount */}
                <div>
                  <label className="block text-gray-700">Total Amount</label>
                  <input
                    type="text"
                    readOnly
                    disabled
                    value={
                      formData.total_amount
                        ? new Intl.NumberFormat().format(formData.total_amount)
                        : 0
                    }
                    className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
                  />
                  {error.total_amount && (
                    <p className="mt-1 text-xs text-red-600">
                      {error.total_amount}
                    </p>
                  )}
                </div>

                {/* Final Amount */}
                <div>
                  <label className="block text-gray-700">Final Amount</label>
                  <input
                    type="text"
                    readOnly
                    disabled
                    value={
                      formData.final_amount
                        ? new Intl.NumberFormat().format(formData.final_amount)
                        : 0
                    }
                    className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
                  />
                </div>
              </div>
            </div>

            {/* Submit */}
            <button
              type="submit"
              className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 disabled:opacity-50"
              disabled={createInvoice.isLoading}
            >
              {createInvoice.isLoading ? "Adding..." : "Add Invoice"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateInvoice;
