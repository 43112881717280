import { format, parse } from "date-fns";

export const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();
};

export const getWhatsAppLink = (phone) => {
  let cleaned = phone.replace(/\D/g, ""); // Remove non-numeric characters

  if (cleaned.startsWith("0")) {
    cleaned = cleaned.substring(1); // Remove leading 0 (if any)
  }

  return `https://wa.me/${cleaned}`;
};

export const formatPhoneNumber = (phone) => {
  if (!phone) return ""; // Handle empty values

  let cleaned = phone.replace(/\D/g, ""); // Remove all non-numeric characters

  // If the number is too short, return as-is
  if (cleaned.length < 10) return phone;

  // Check if it includes a country code (assuming international numbers)
  if (cleaned.length > 10) {
    const countryCode = cleaned.slice(0, cleaned.length - 10);
    const mainNumber = cleaned.slice(-10); // Last 10 digits as the main number

    return `+${countryCode} (${mainNumber.slice(0, 3)}) ${mainNumber.slice(
      3,
      6
    )}-${mainNumber.slice(6)}`;
  }

  // Default to US-style format if no country code
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
};

export function getLastUpdatedText(date) {
  try {
    const dateFormat = "EEE, dd MMM yyyy HH:mm:ss 'GMT'";
    const parsedDate = parse(date, dateFormat, new Date());

    if (isNaN(parsedDate.getTime())) {
      return "Invalid date provided";
    }

    const formattedDate = format(parsedDate, "dd MMM yyyy");
    return `Last edited on ${formattedDate}`;
  } catch (error) {
    console.error("Error parsing date:", error);
    return "Invalid date provided";
  }
}

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
