import React, { useState } from "react";
import { format } from "date-fns";
import Badge from "../../../components/common/badges/Badge";
import Tabs from "../../../components/common/tabs/Tabs";
import {
  useDeleteExpense,
  useUpdateExpense,
} from "../../../hooks/useExpenses/useExpenses";
import ClientProjects from "../clients/details/ClientProjects";
import ExpenseProvider from "./details/ExpenseProvider";
import ExpenseItems from "./details/ExpenseItems";
import EditPurchaseOrder from "./details/EditPurchaseOrder";
import SidebarDropdown from "../../../components/common/buttons/SidebarDropdown";

const PurchaseOrder = ({ data, closeSidebar }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [expenseData, setExpenseData] = useState(data);
  const [error, setError] = useState({});

  const updateExpense = useUpdateExpense();
  const deleteExpense = useDeleteExpense();

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
      try {
        await deleteExpense.mutateAsync({ expenseId: expenseData.id });
        closeSidebar();
      } catch (err) {
        setError({ submit: "Failed to delete expense. Please try again." });
      }
    }
  };

  const handleExpenseUpdate = (updatedExpense) => {
    setExpenseData((prev) => ({
      ...prev,
      ...updatedExpense,
      project: data.project,
      provider: data.provider,
    }));
    setIsEditing(false);
  };

  const formatCurrency = (value) =>
    new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    })?.format(value);

  const tabs = [
    {
      label: "Items",
      content: (
        <ExpenseItems
          items={Object?.values(expenseData?.items || {})}
          tax={expenseData?.tax || null}
          amount={expenseData?.amount}
          finalAmount={expenseData?.final_total}
        />
      ),
    },
    {
      label: "Project",
      content: (
        <ClientProjects
          data={expenseData?.project ? [expenseData?.project] : []}
          title={"Expense Not Linked to Project."}
          description={
            "Please edit this expense or create a project to associate it."
          }
        />
      ),
    },
    {
      label: "Provider",
      content: <ExpenseProvider data={expenseData?.provider} />,
    },
  ];

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg">
          {error.submit}
        </div>
      )}
      {isEditing ? (
        <EditPurchaseOrder
          updateExpense={updateExpense}
          expenseData={expenseData}
          handleEditToggle={handleEditToggle}
          onExpenseUpdate={handleExpenseUpdate}
        />
      ) : (
        <div className="space-y-6">
          {/* Header */}
          <div className="flex items-center justify-between">
            <div>
              <h5 className="text-xl font-bold text-gray-900">
                {expenseData?.order_id || "Expense Details"}
              </h5>
              <Badge status={expenseData?.status} />
            </div>
            {/* Options Dropdown */}
            <SidebarDropdown
              onEdit={handleEditToggle}
              onDelete={handleDelete}
            />
          </div>

          <dl className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
            <div>
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {expenseData?.description || "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Amount</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {expenseData?.amount
                  ? formatCurrency(expenseData.amount)
                  : "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Due Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {expenseData?.due_date
                  ? format(new Date(expenseData.due_date), "MMMM dd, yyyy")
                  : "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Payment Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {expenseData?.payment_type || "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Location</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {expenseData?.location || "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Tax</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {expenseData?.tax
                  ? `${expenseData.tax.name} (${expenseData.tax.rate}%)`
                  : expenseData?.tax_id
                  ? `Tax ID: ${expenseData.tax_id}`
                  : "---"}
              </dd>
            </div>
          </dl>

          {/* Tabs */}
          <Tabs tabs={tabs} />
        </div>
      )}
    </div>
  );
};

export default PurchaseOrder;
