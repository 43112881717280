import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { decryptToken } from "../../utils/cryptoUtils";
import ProjectHeader from "../../components/layout/projectLayout/ProjectHeader";
import ProjectFooter from "../../components/layout/projectLayout/ProjectFooter";
import { usePublicProjectDetails } from "../../hooks/usePublic/usePublic";
import LoadingPage from "../../components/common/empty/LoadingPage";
import FilesContainer from "./details/files/FilesContainer";
// import { FilesContainer } from "./SharedProject"; // reuse the FilesContainer from SharedProject

const FolderView = () => {
  const { token, uuid: folderId } = useParams();
  const data = decryptToken(token);
  const { company_id, project_id } = data;

  const {
    data: projectDetails,
    isLoading,
    isError,
  } = usePublicProjectDetails(company_id, project_id);

  if (isLoading) return <LoadingPage />;
  if (isError) return <div>Error loading folder details</div>;

  const { company, project, public_files } = projectDetails || {};

  // Filter to only the files that belong to this folder.
  const folderFiles = public_files.filter(
    (file) => file.folder && file.folder.id === folderId
  );

  // Use the first file's folder info (if available) to display the folder name.
  const folderInfo = folderFiles.length > 0 ? folderFiles[0].folder : null;

  return (
    <div>
      <ProjectHeader
        data={company}
        logo={company?.logo_url}
        banner={project?.banner_url}
        projectName={project?.name}
        folderName={folderInfo?.name}
        numberFiles={`${folderFiles.length} File${
          folderFiles.length !== 1 && "s"
        }`}
        enableBreadCrumb
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-8">
        <FilesContainer data={folderFiles} />
      </div>
      <ProjectFooter data={company} />
    </div>
  );
};

export default FolderView;
