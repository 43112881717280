import React, { useState, useEffect, useContext } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import { INVOICES_TABLE_COLUMNS } from "../../../utils/tableColumns";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import FiltersContainer from "../../../components/common/filters/FiltersContainer";
import TableContainer from "../../../components/common/tables/TableContainer";
import Pagination from "../../../components/common/tables/Pagination";
import { useNavigate } from "react-router-dom";
import { useCompanyInvoices } from "../../../hooks/useInvoices/useInvoices";
import SlideOver from "../../../components/layout/SlideOver";
import InvoiceDetails from "./InvoiceDetails";
import { AuthContext } from "../../../context/AuthProvider";
import NoAccessView from "../../../components/common/cards/NoAccessView";

const Invoices = () => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [sideOveOpen, setSideOverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const navigate = useNavigate();

  const { data, isLoading, isError } = useCompanyInvoices(
    currentPage,
    perPage,
    debouncedSearch
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchInput);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);
  };

  const handleAddInvoice = () => {
    navigate("/invoices/create");
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleRowClick = (data) => {
    setSideOverOpen((prevState) => !prevState);
    setSelectedRow(data || {});
  };

  const invoices = data?.invoices || [];
  const totalItems = data?.pagination?.total_items || 0;

  if (!userRoleActionEnabled) {
    return <NoAccessView />;
  }

  return (
    <div>
      <PageHeadings
        title="Invoices"
        tags={[]}
        showCta={true}
        ctaLabel="Create"
        handleCtaAction={handleAddInvoice}
      />
      <DefaultCard>
        <FiltersContainer value={searchInput} onChange={handleSearchChange} />
        <TableContainer
          hasCheckbox={false}
          columns={INVOICES_TABLE_COLUMNS}
          columnType="invoices"
          rowType="invoices"
          data={invoices}
          loading={isLoading}
          error={isError}
          handleRowClick={handleRowClick}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={totalItems}
          pageSize={perPage}
          onPageChange={handlePageChange}
        />
      </DefaultCard>
      <SlideOver open={sideOveOpen} setOpen={setSideOverOpen} title="Details">
        <InvoiceDetails
          data={selectedRow}
          closeSidebar={() => setSideOverOpen(false)}
        />
      </SlideOver>
    </div>
  );
};

export default Invoices;
