import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import SlideOver from "../../../../components/layout/SlideOver";
import {
  useDeleteFolder,
  useFilesByProject,
  useFoldersByProject,
  useUpdateFolder,
} from "../../../../hooks/useFiles/useFiles";
import FileDetails from "../../files/FileDetails";
import ProjectFilesTable from "../../../../components/common/tables/ProjectFilesTableContainer";

const ProjectFiles = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const [sideOverOpen, setSideOverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // New state for folder actions
  const [editFolderOpen, setEditFolderOpen] = useState(false);
  const [deleteFolderOpen, setDeleteFolderOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const {
    data: files,
    isLoading: filesLoading,
    isError: filesError,
  } = useFilesByProject(projectId);
  const {
    data: folders,
    isLoading: foldersLoading,
    isError: foldersError,
  } = useFoldersByProject({ projectId });

  const handleFileRowClick = (data) => {
    setSideOverOpen(true);
    setSelectedRow(data || {});
  };

  const handleAddFile = () => {
    navigate(`/projects/${projectId}/create-file`);
  };

  const handleAddFolder = () => {
    navigate(`/projects/${projectId}/create-folder`);
  };

  // New handlers for folder actions
  const handleEditFolder = (folder) => {
    setSelectedFolder(folder);
    setEditFolderOpen(true);
  };

  const handleDeleteFolder = (folder) => {
    setSelectedFolder(folder);
    setDeleteFolderOpen(true);
  };

  // Dropdown options for the header dropdown button
  const dropdownOptions = [
    { label: "Folder", onClick: handleAddFolder },
    { label: "File", onClick: handleAddFile },
  ];

  const loading = filesLoading || foldersLoading;
  const error = filesError || foldersError;

  return (
    <div className="bg-white rounded-lg pb-8">
      <CardHeadings
        title="Files & Folders"
        description="Manage files and folders within your project."
        showCta={false}
        dropdownOptions={dropdownOptions}
        dropdownLabel="Create"
      />
      <ProjectFilesTable
        files={files}
        folders={folders}
        onFileRowClick={handleFileRowClick}
        onEditFolder={handleEditFolder} // NEW
        onDeleteFolder={handleDeleteFolder} // NEW
        loading={loading}
        error={error}
      />
      <SlideOver
        open={sideOverOpen}
        setOpen={setSideOverOpen}
        title="File Details"
      >
        <FileDetails
          data={selectedRow}
          closeSidebar={() => setSideOverOpen(false)}
        />
      </SlideOver>
      {/* SlideOver for editing a folder */}
      <SlideOver
        open={editFolderOpen}
        setOpen={setEditFolderOpen}
        title="Edit Folder"
      >
        <EditFolderForm
          folder={selectedFolder}
          onClose={() => setEditFolderOpen(false)}
        />
      </SlideOver>

      {/* SlideOver for confirming folder deletion */}
      <SlideOver
        open={deleteFolderOpen}
        setOpen={setDeleteFolderOpen}
        title="Delete Folder"
      >
        <DeleteFolderConfirm
          folder={selectedFolder}
          onClose={() => setDeleteFolderOpen(false)}
        />
      </SlideOver>
    </div>
  );
};

export default ProjectFiles;

const EditFolderForm = ({ folder, onClose }) => {
  const [name, setName] = useState(folder.name);
  const updateFolderMutation = useUpdateFolder();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateFolderMutation.mutateAsync({
        folderId: folder.id,
        data: { name },
      });
      onClose();
    } catch (error) {
      console.error("Error updating folder:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-white rounded-lg">
      <div>
        <label
          htmlFor="folderName"
          className="block text-sm font-medium text-gray-700"
        >
          Folder Name
        </label>
        <input
          type="text"
          id="folderName"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
        >
          {updateFolderMutation.isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </form>
  );
};

const DeleteFolderConfirm = ({ folder, onClose }) => {
  const deleteFolderMutation = useDeleteFolder();
  const [confirmationName, setConfirmationName] = useState("");

  const handleDelete = async () => {
    try {
      await deleteFolderMutation.mutateAsync({ folderId: folder.id });
      onClose();
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  return (
    <div className="space-y-6 p-6 bg-white rounded-lg">
      <p className="text-gray-700">
        <span className="font-bold">Warning:</span> Deleting the folder{" "}
        <span className="font-semibold">{folder.name}</span> will permanently
        remove it and all associated files. This action cannot be undone!
      </p>
      <p className="text-gray-700">
        To confirm deletion, please type the folder name below:
      </p>
      <input
        type="text"
        value={confirmationName}
        onChange={(e) => setConfirmationName(e.target.value)}
        placeholder="Type folder name"
        className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring-red-500 focus:border-red-500"
      />
      <div className="flex justify-end space-x-2">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          onClick={handleDelete}
          disabled={confirmationName !== folder.name}
          className={`px-4 py-2 rounded-md transition-colors duration-200 ${
            confirmationName === folder.name
              ? "bg-red-600 text-white hover:bg-red-700"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
        >
          {deleteFolderMutation.isLoading ? "Deleting..." : "Delete"}
        </button>
      </div>
    </div>
  );
};
