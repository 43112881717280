import React, { useState, useRef, useEffect } from "react";

const CardHeadings = ({
  title,
  description,
  showCta = false,
  ctaLabel,
  handleCtaAction,
  dropdownOptions, // Array of objects: { label: string, onClick: function }
  dropdownLabel, // Label to display on the dropdown button
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    if (option.onClick) {
      option.onClick();
    }
    setIsDropdownOpen(false);
  };

  return (
    <div className="bg-white p-4 rounded-lg">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-bold text-gray-900">{title}</h3>
          {description && (
            <p className="max-w-2xl text-sm leading-6 text-gray-500 mb-2">
              {description}
            </p>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {showCta && (
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              onClick={handleCtaAction}
            >
              {ctaLabel}
            </button>
          )}
          {dropdownOptions && dropdownOptions.length > 0 && (
            <div className="relative" ref={dropdownRef}>
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                onClick={handleDropdownToggle}
              >
                {dropdownLabel}
                <svg
                  className="ml-2 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-28 bg-white border rounded-md shadow-lg z-10">
                  {dropdownOptions.map((option, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => handleOptionClick(option)}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardHeadings;
