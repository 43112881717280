import React, { useContext } from "react";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import { AuthContext } from "../../../../context/AuthProvider";
import {
  useCompanyApiKey,
  useDeleteCompanyApiKey,
  useGenerateCompanyApiKey,
} from "../../../../hooks/useCompany";
import ApiKeyDisplay from "../../../../components/common/inputs/ApiKeyDisplay";
import NoAccessView from "../../../../components/common/cards/NoAccessView";

const Integrations = () => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;
  const userRoleActionEnabled = user?.role === 0;

  const {
    data: apiKeyData,
    isLoading: apiKeyLoading,
    error: apiKeyError,
  } = useCompanyApiKey(companyId);

  const generateMutation = useGenerateCompanyApiKey(companyId);
  const deleteMutation = useDeleteCompanyApiKey(companyId);

  const handleGenerate = () => {
    generateMutation.mutate();
  };

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  const handleCopy = () => {
    if (apiKeyData && apiKeyData.api_key) {
      navigator.clipboard.writeText(apiKeyData.api_key);
    }
  };

  return (
    <div className="bg-white rounded-md border border-gray-200 w-full">
      <CardHeadings
        title="API Key"
        description="Use this API key to connect external integrations (e.g., Zapier, Webhooks) with your account."
      />
      {userRoleActionEnabled ? (
        <div>
          {/* API Key Section */}
          <div className="px-4 pb-4">
            {apiKeyLoading ? (
              <p className="text-gray-600">Loading API key...</p>
            ) : apiKeyError ? (
              <p className="text-red-500">
                Error loading API key: {apiKeyError.message}
              </p>
            ) : (
              <div>
                {apiKeyData?.api_key ? (
                  <div>
                    <ApiKeyDisplay apiKeyData={apiKeyData} />
                    <div className="mt-4 flex flex-col sm:flex-row sm:space-x-4">
                      <button
                        onClick={handleGenerate}
                        disabled={generateMutation.isLoading}
                        className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        {generateMutation.isLoading
                          ? "Generating..."
                          : "Regenerate API Key"}
                      </button>
                      <button
                        onClick={handleDelete}
                        disabled={deleteMutation.isLoading}
                        className="mt-2 sm:mt-0 w-full sm:w-auto bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
                      >
                        {deleteMutation.isLoading
                          ? "Deleting..."
                          : "Delete API Key"}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <p className="text-gray-600">No API key generated yet.</p>
                    <button
                      onClick={handleGenerate}
                      disabled={generateMutation.isLoading}
                      className="mt-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      {generateMutation.isLoading
                        ? "Generating..."
                        : "Generate API Key"}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Integration Configuration Instructions */}
          {apiKeyData?.api_key && (
            <div className="mt-8 bg-gray-50 p-4 m-4 rounded-md border border-gray-200">
              <h3 className="text-xl font-semibold text-gray-800">
                Integration Setup
              </h3>
              <p className="mt-2 text-sm text-gray-600">
                To integrate with Zapier (or any service that supports
                webhooks), follow these steps:
              </p>
              <ol className="list-decimal list-inside mt-2 text-sm text-gray-600">
                <li>
                  Use the following webhook URL in your integration platform:
                  <span className="block bg-gray-100 p-2 mt-1 rounded text-sm font-mono">
                    {process.env.REACT_APP_API_URL}/api/webhook/clients
                  </span>
                </li>
                <li className="mt-2">
                  Set the HTTP header{" "}
                  <code className="bg-gray-200 px-1 py-0.5 rounded">
                    X-API-KEY
                  </code>{" "}
                  with your API key:
                  <span className="block bg-gray-100 p-2 mt-1 rounded text-sm font-mono">
                    {apiKeyData.api_key}
                  </span>
                </li>
                <li className="mt-2">
                  Configure the webhook payload with the required fields. For
                  example:
                  <pre className="bg-gray-100 p-2 mt-1 rounded text-sm font-mono whitespace-pre-wrap">
                    {`{
"name": "Client Name",
"email": "client@example.com",
"phone": "1234567890",
"status": "Potential Client",
"source": "Zapier",
"industry": "Residential",
"lead_score": 10,
"extra_info": "Additional info about the client",
"address": "123 Main St"
}`}
                  </pre>
                </li>
                <li className="mt-2">
                  Test the integration by triggering the webhook. A valid
                  payload will create a new client in your system.
                </li>
              </ol>
            </div>
          )}
        </div>
      ) : (
        <NoAccessView />
      )}
    </div>
  );
};

export default Integrations;
