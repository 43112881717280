import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useClients } from "../../hooks/useClients/useClients";
import { useProjects } from "../../hooks/useProjects/useProjects";
import { useUsers } from "../../hooks/useUsers";

const OnboardingSteps = () => {
  // Always call hooks at the top of the component.
  const {
    data: customers,
    isLoading: loadingCustomers,
    isError: errorCustomers,
  } = useClients();
  const {
    data: users,
    isLoading: loadingUsers,
    isError: errorUsers,
  } = useUsers();
  const {
    data: projects,
    isLoading: loadingProjects,
    isError: errorProjects,
  } = useProjects();

  // Use safe defaults in case data is undefined.
  const safeCustomers = customers?.clients ?? [];
  const safeUsers = users?.users ?? [];
  const safeProjects = projects?.projects ?? [];

  // Determine if each onboarding step is complete.
  const hasAtLeastOneCustomer = safeCustomers.length > 0;
  // Assume the initial user is always present; require more than one user for proper team collaboration.
  const hasMoreThanOneUser = safeUsers.length > 1;
  const hasAtLeastOneProject = safeProjects.length > 0;

  // Memoize the steps so they’re recalculated only when their dependencies change.
  // Each step now includes an explanation of why the step is important.
  const steps = useMemo(
    () => [
      {
        id: 1,
        title: "Add your first customer",
        completed: hasAtLeastOneCustomer,
        actionLabel: "Add a Customer",
        link: "/clients/create", // Adjust the route as needed.
        explanation:
          "Adding a customer helps you build a reliable client base. It’s essential for tracking project progress and maintaining long-term relationships.",
      },
      {
        id: 2,
        title: "Invite more team members",
        completed: hasMoreThanOneUser,
        actionLabel: "Add Users",
        link: "/settings?tab=Users", // Adjust the route as needed.
        explanation:
          "Inviting team members fosters collaboration. A well-integrated team can streamline workflow and improve project outcomes.",
      },
      {
        id: 3,
        title: "Create your first project",
        completed: hasAtLeastOneProject,
        actionLabel: "New Project",
        link: "/projects/create", // Adjust the route as needed.
        explanation:
          "Creating a project sets the stage for managing tasks, invoices, and expenses. It organizes your work and drives productivity.",
      },
    ],
    [hasAtLeastOneCustomer, hasMoreThanOneUser, hasAtLeastOneProject]
  );

  const completedSteps = steps.filter((step) => step.completed).length;
  const totalSteps = steps.length;
  const completionPercentage = Math.round((completedSteps / totalSteps) * 100);

  // If data is still loading, show a loading indicator.
  if (loadingCustomers || loadingUsers || loadingProjects) {
    return (
      <div className="p-4 mb-6 bg-white rounded shadow">
        <p className="text-sm text-gray-500">
          Loading your onboarding progress...
        </p>
      </div>
    );
  }

  // If there is an error fetching data, show an error message.
  if (errorCustomers || errorUsers || errorProjects) {
    return (
      <div className="p-4 mb-6 bg-white rounded shadow">
        <p className="text-sm text-red-500">
          There was an error loading your onboarding data. Please try again
          later.
        </p>
      </div>
    );
  }

  // If all steps are complete, return null so nothing is shown.
  if (completedSteps === totalSteps) {
    return null;
  }

  return (
    <section className="p-4 mb-6 bg-white rounded shadow">
      {/* Header with title and progress indicator */}
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-700">Get Started</h2>
        <span className="text-sm text-gray-500">
          {completedSteps}/{totalSteps} completed
        </span>
      </div>

      {/* Accessible progress bar */}
      <div
        className="w-full bg-gray-200 rounded-full h-2 mb-6"
        role="progressbar"
        aria-valuenow={completionPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div
          className="bg-blue-600 h-2 rounded-full transition-all duration-300"
          style={{ width: `${completionPercentage}%` }}
        />
      </div>

      {/* List of onboarding steps with explanations */}
      <ul className="space-y-6">
        {steps.map((step) => (
          <li
            key={step.id}
            className="flex flex-col md:flex-row items-start md:items-center justify-between"
          >
            <div className="flex-1">
              <div className="flex items-center">
                {step.completed ? (
                  // Completed icon
                  <svg
                    className="h-6 w-6 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M9.75 18a.75.75 0 01-.53-.22l-3.75-3.75a.75.75 0 111.06-1.06l3.22 3.22 7.72-7.72a.75.75 0 111.06 1.06l-8.25 8.25a.75.75 0 01-.53.22z" />
                  </svg>
                ) : (
                  // Incomplete icon
                  <svg
                    className="h-6 w-6 text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <circle cx="12" cy="12" r="10" />
                  </svg>
                )}
                <span className="ml-2 text-sm font-medium text-gray-700">
                  {step.title}
                </span>
              </div>
              {/* Explanation for why this step is important */}
              <p className="mt-1 ml-8 text-xs text-gray-500">
                {step.explanation}
              </p>
            </div>
            {!step.completed && (
              <Link
                to={step.link}
                className="mt-2 md:mt-0 text-sm font-semibold text-blue-600 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 rounded"
              >
                {step.actionLabel}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default OnboardingSteps;
