import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  useDeleteClient,
  useUpdateClient,
} from "../../../hooks/useClients/useClients";
import Tabs from "../../../components/common/tabs/Tabs";
import ClientProjects from "./details/ClientProjects";
import ClientInvoices from "./details/ClientInvoices";
import ClientComments from "./details/ClientComments";
import Badge from "../../../components/common/badges/Badge";
import Select from "react-select";
import { useUsers } from "../../../hooks/useUsers";
import { formatPhoneNumber, getWhatsAppLink } from "../../../utils/helpers";
import { AuthContext } from "../../../context/AuthProvider";
import NoAccessView from "../../../components/common/cards/NoAccessView";
import SidebarDropdown from "../../../components/common/buttons/SidebarDropdown";

const ClientDetails = ({ data, closeSidebar }) => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;
  const [isEditing, setIsEditing] = useState(false);
  const [clientData, setClientData] = useState(data);
  const [initialClientData, setInitialClientData] = useState(data);
  const [error, setError] = useState({});
  const [defaultCountry, setDefaultCountry] = useState("us");
  const navigate = useNavigate();

  const deleteClient = useDeleteClient();
  const updateClient = useUpdateClient();

  // Load the list of users (for the Owner field)
  const {
    data: usersData,
    isLoading: usersLoading,
    isError: usersError,
  } = useUsers(1, 100);

  // Build user options – if clientData already includes options, use them; otherwise build from usersData
  const userOptions = useMemo(() => {
    if (
      clientData.assigned_user_options &&
      clientData.assigned_user_options.length > 0
    ) {
      return clientData.assigned_user_options;
    }
    if (!usersData || !usersData.users) return [];
    return usersData.users.map((user) => ({
      value: user.id,
      label: user.username,
    }));
  }, [usersData, clientData.assigned_user_options]);

  useEffect(() => {
    setClientData(data);
    setInitialClientData(data);

    const getDefaultCountry = () => {
      const locale = navigator.language || navigator.userLanguage;
      const country = locale.split("-")[1]?.toLowerCase();
      if (country) setDefaultCountry(country);
    };
    getDefaultCountry();
  }, [data]);

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await deleteClient.mutateAsync({ clientId: clientData.id });
        closeSidebar();
        navigate("/clients");
      } catch (err) {
        setError((prevError) => ({
          ...prevError,
          submit: "Failed to delete client. Please try again.",
        }));
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setClientData((prevData) => ({ ...prevData, phone: value }));
  };

  const validateInputs = () => {
    const errors = {};
    if (!clientData.name.trim()) {
      errors.name = "Name is required.";
    }
    if (clientData.email && !/^\S+@\S+\.\S+$/.test(clientData.email)) {
      errors.email = "Enter a valid email.";
    }
    if (!clientData.phone.trim()) {
      errors.phone = "Phone number is required.";
    }
    if (!clientData.status) {
      errors.status = "Status is required.";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateInputs()) return;
    try {
      await updateClient.mutateAsync({
        clientId: clientData.id,
        data: clientData,
      });
      setInitialClientData(clientData);
      setIsEditing(false);
    } catch (err) {
      setError((prevError) => ({
        ...prevError,
        submit: "Failed to update client. Please try again.",
      }));
    }
  };

  const handleCancelEdit = () => {
    setClientData(initialClientData);
    setIsEditing(false);
    setError({});
  };

  // Define tabs (projects, invoices, comments)
  const tabs = [
    {
      label: "Projects",
      content: <ClientProjects data={clientData.projects} />,
    },
    {
      label: "Invoices",
      content: userRoleActionEnabled ? (
        <ClientInvoices data={clientData.invoices} />
      ) : (
        <NoAccessView />
      ),
    },
    { label: "Comments", content: <ClientComments clientId={clientData.id} /> },
  ];

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div
          className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg"
          role="alert"
        >
          {error.submit}
        </div>
      )}

      {isEditing ? (
        <div className="space-y-4">
          {/* Client Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Client Name
            </label>
            <input
              type="text"
              name="name"
              value={clientData.name}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Enter client name"
            />
            {error.name && (
              <div className="text-red-500 text-sm mt-1">{error.name}</div>
            )}
          </div>

          {/* Client Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Client Status
            </label>
            <select
              name="status"
              value={clientData.status}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <option value="Lead">Lead</option>
              <option value="Qualified">Qualified</option>
              <option value="Proposal Sent">Proposal Sent</option>
              <option value="Negotiation">Negotiation</option>
              <option value="Active Client">Active Client</option>
              <option value="On Hold">On Hold</option>
              <option value="Completed">Completed</option>
              <option value="Past Client">Past Client</option>
            </select>
            {error.status && (
              <div className="text-red-500 text-sm mt-1">{error.status}</div>
            )}
          </div>

          {/* Client Phone */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <PhoneInput
              country={defaultCountry}
              value={clientData.phone}
              onChange={handlePhoneChange}
              placeholder="Enter client phone number"
              inputClass="w-full border p-2 rounded"
              inputStyle={{ width: "100%" }}
              enableSearch={true}
            />
            {error.phone && (
              <div className="text-red-500 text-sm mt-1">{error.phone}</div>
            )}
          </div>

          {/* Client Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email (optional)
            </label>
            <input
              type="email"
              name="email"
              value={clientData.email}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Enter client email"
            />
            {error.email && (
              <div className="text-red-500 text-sm mt-1">{error.email}</div>
            )}
          </div>

          {/* Client Address */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Address (optional)
            </label>
            <input
              type="text"
              name="address"
              value={clientData.address}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Enter client address"
            />
          </div>

          {/* Client Source */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Client Source (optional)
            </label>
            <select
              name="source"
              value={clientData.source || ""}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <option value="">Select client source</option>
              <option value="Social Media">Social Media</option>
              <option value="Referral">Referral</option>
              <option value="Ad Campaign">Ad Campaign</option>
              <option value="Event">Event</option>
              <option value="Website">Website</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Industry */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Industry (optional)
            </label>
            <select
              name="industry"
              value={clientData.industry || ""}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <option value="">Select industry</option>
              <option value="Residential">Residential</option>
              <option value="Commercial">Commercial</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Extra Info */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Extra Info (optional)
            </label>
            <textarea
              name="extra_info"
              value={clientData.extra_info || ""}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Enter additional notes or information"
              rows={3}
            ></textarea>
          </div>

          {/* Improved Owner Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Owner (optional)
            </label>
            {usersLoading ? (
              <div className="text-sm text-gray-500">Loading users...</div>
            ) : usersError ? (
              <div className="text-sm text-red-500">Error loading users</div>
            ) : (
              <Select
                name="assigned_user_id"
                options={userOptions}
                value={
                  clientData?.assigned_user?.id
                    ? userOptions.find(
                        (option) =>
                          option.value === clientData?.assigned_user?.id
                      )
                    : null
                }
                onChange={(selectedOption) =>
                  setClientData((prevData) => ({
                    ...prevData,
                    assigned_user_id: selectedOption
                      ? selectedOption.value
                      : "",
                    assigned_user: selectedOption
                      ? {
                          id: selectedOption.value,
                          username: selectedOption.label,
                        }
                      : null,
                  }))
                }
                isClearable
                placeholder="Select Owner"
                className="max-w-md"
              />
            )}
          </div>

          {/* Action Buttons */}
          <div className="mt-4 flex space-x-4">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Save
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          {/* Header with Name and Status */}
          <div className="flex items-start justify-between">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">
                {clientData.name}
              </h2>
              <Badge status={clientData.status} />
            </div>

            {/* Options Dropdown */}
            {userRoleActionEnabled && (
              <SidebarDropdown
                onEdit={handleEditToggle}
                onDelete={handleDelete}
              />
            )}
          </div>

          {/* Client Details using a Definition List */}
          <dl className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
            <div>
              <dt className="text-sm font-medium text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {clientData.phone ? (
                  <a
                    href={getWhatsAppLink(clientData.phone)}
                    className="text-green-600 hover:underline block flex"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      fill="currentColor"
                      className="size-4 mr-1 text-green-600"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                    {formatPhoneNumber(clientData.phone)}
                  </a>
                ) : (
                  "---"
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {clientData.email ? (
                  <a
                    href={`mailto:${clientData.email}`}
                    className="block w-full truncate text-blue-600 hover:underline"
                    title={clientData.email}
                  >
                    {clientData.email}
                  </a>
                ) : (
                  "---"
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {clientData.address || "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Client Source
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {clientData.source || "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Industry</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {clientData.industry || "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Extra Info</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {clientData.extra_info || "---"}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Owner</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {clientData?.assigned_user?.username || "---"}
              </dd>
            </div>
          </dl>
        </div>
      )}
      {!isEditing && <Tabs tabs={tabs} />}
    </div>
  );
};

export default ClientDetails;
