import React from "react";

// Map legacy statuses to new statuses.
const STATUS_MAP = {
  Lead: "Lead",
  Prospect: "Qualified",
  "Potential Client": "Qualified",
  "Proposal Sent": "Proposal Sent",
  Negotiation: "Negotiation",
  "Active Client": "Active Client",
  "In Progress": "Active Project",
  "Repeat Client": "Active Project",
  "High Priority": "Active Project",
  "On Hold": "On Hold",
  "Pending Payment": "On Hold",
  Inactive: "Past Client",
  Churned: "Past Client",
  "Past Client": "Past Client",
  "VIP Client": "Past Client",
  Qualified: "Qualified",
  "Active Project": "Active Project",
  Completed: "Completed",
};

// Define colors for the new statuses.
const NEW_STATUS_COLORS = {
  Lead: "bg-gray-100 text-gray-800",
  Qualified: "bg-blue-100 text-blue-800",
  "Proposal Sent": "bg-purple-100 text-purple-800",
  Negotiation: "bg-orange-100 text-orange-800",
  "Active Client": "bg-green-100 text-green-800",
  "On Hold": "bg-gray-200 text-gray-700",
  Completed: "bg-teal-100 text-teal-800",
  "Past Client": "bg-indigo-100 text-indigo-800",
};

// The Badge component accepts a status prop (which may be a legacy or new value).
const Badge = ({ status }) => {
  // Normalize the status value
  const normalizedStatus = STATUS_MAP[status] || status;
  // Use the new color mapping. If not found, fallback to default.
  const colorClass =
    NEW_STATUS_COLORS[normalizedStatus] || "bg-gray-100 text-gray-800";

  return (
    <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${colorClass}`}>
      {normalizedStatus}
    </span>
  );
};

export default Badge;
