import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../../context/AuthProvider";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import ErrorMessage from "../../../../components/common/empty/ErrorMessage";
import {
  useTaxes,
  useCreateTax,
  useUpdateTax,
} from "../../../../hooks/useTaxes/useTaxes";

const Tax = () => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;
  // Allow actions for company admins or managers (example roles: 0 or 1)
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;

  // ----- React Query Hooks -----
  const {
    data: taxesData = [],
    isLoading: isLoadingTaxes,
    isError: isErrorTaxes,
    error: fetchTaxesError,
  } = useTaxes();

  const createTaxMutation = useCreateTax();
  const updateTaxMutation = useUpdateTax();

  // ----- Local UI State -----
  const [newTax, setNewTax] = useState({
    name: "",
    description: "",
    tax_type: "inclusive",
    rate: "",
  });
  const [editTax, setEditTax] = useState(null);
  const [createError, setCreateError] = useState("");
  const [editError, setEditError] = useState("");

  // ----- Handlers -----
  const handleCreateTax = () => {
    if (!newTax.name.trim() || !newTax.rate) {
      setCreateError("Tax name and rate are required.");
      return;
    }
    setCreateError("");
    const taxData = {
      name: newTax.name,
      description: newTax.description,
      tax_type: newTax.tax_type,
      rate: parseFloat(newTax.rate),
    };
    createTaxMutation.mutate(taxData, {
      onSuccess: () =>
        setNewTax({
          name: "",
          description: "",
          tax_type: "inclusive",
          rate: "",
        }),
    });
  };

  const handleUpdateTax = (taxId, updatedData) => {
    updateTaxMutation.mutate(
      { taxId, data: updatedData },
      { onSuccess: () => setEditTax(null) }
    );
  };

  // Toggle archive status (archive if active, unarchive if archived)
  const handleToggleArchive = (tax) => {
    const updatedData = { archived: !tax.archived };
    updateTaxMutation.mutate({ taxId: tax.id, data: updatedData });
  };

  if (isLoadingTaxes) return <div>Loading Tax Settings...</div>;

  if (isErrorTaxes) {
    return (
      <ErrorMessage
        message={
          fetchTaxesError?.response?.data?.description ||
          "Failed to load tax settings."
        }
      />
    );
  }

  return (
    <div className="bg-white rounded-md border border-gray-200 w-full">
      {/* Heading */}
      <CardHeadings
        title="Tax Settings"
        description="Create, update, archive, and unarchive tax settings for your company."
      />
      <div className="px-4 pb-4">
        {userRoleActionEnabled && (
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-2">Create New Tax</h3>
            <div className="flex flex-col md:flex-row gap-2">
              <input
                type="text"
                value={newTax.name}
                onChange={(e) => setNewTax({ ...newTax, name: e.target.value })}
                placeholder="Tax Name"
                className="p-2 border rounded w-full md:w-1/4 text-sm"
              />
              <input
                type="text"
                value={newTax.description}
                onChange={(e) =>
                  setNewTax({ ...newTax, description: e.target.value })
                }
                placeholder="Description (optional)"
                className="p-2 border rounded w-full md:w-1/4 text-sm"
              />
              {/**
             * Uncomment below if you want to allow tax type selection:
             * <select
              value={newTax.tax_type}
              onChange={(e) =>
                setNewTax({ ...newTax, tax_type: e.target.value })
              }
              className="p-2 border rounded w-full md:w-1/4 text-sm"
            >
              <option value="inclusive">Inclusive</option>
              <option value="exclusive">Exclusive</option>
            </select>
             */}
              <input
                type="number"
                value={newTax.rate}
                onChange={(e) => setNewTax({ ...newTax, rate: e.target.value })}
                placeholder="Rate (%)"
                className="p-2 border rounded w-full md:w-1/4 text-sm"
                step="0.01"
              />
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm"
                onClick={handleCreateTax}
                disabled={createTaxMutation.isLoading}
              >
                {createTaxMutation.isLoading ? "Creating..." : "Create"}
              </button>
            </div>
            {createError && (
              <p className="text-red-600 text-xs mt-1">{createError}</p>
            )}
            {createTaxMutation.isError && (
              <p className="text-red-600 text-xs mt-1">
                {createTaxMutation.error?.response?.data?.description ||
                  "Error creating tax setting."}
              </p>
            )}
          </div>
        )}

        {/* Responsive Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Display Name
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                {/**
               * Uncomment below if you want to show tax type:
               * <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
               */}
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rate
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created
                </th>
                {userRoleActionEnabled && (
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {taxesData.length === 0 ? (
                <tr>
                  <td
                    colSpan={userRoleActionEnabled ? 6 : 5}
                    className="px-4 py-2 text-center text-sm text-gray-500"
                  >
                    No tax settings created yet.
                  </td>
                </tr>
              ) : (
                taxesData.map((tax) =>
                  editTax && editTax.id === tax.id ? (
                    <tr key={tax.id}>
                      <td className="px-4 py-2">
                        <input
                          type="text"
                          value={editTax.name}
                          onChange={(e) =>
                            setEditTax({ ...editTax, name: e.target.value })
                          }
                          className="p-1 border rounded w-full text-sm"
                        />
                      </td>
                      <td className="px-4 py-2">
                        <input
                          type="text"
                          value={editTax.description}
                          onChange={(e) =>
                            setEditTax({
                              ...editTax,
                              description: e.target.value,
                            })
                          }
                          className="p-1 border rounded w-full text-sm"
                        />
                      </td>
                      {/**
                     * Uncomment below if you want to show tax type:
                     * <td className="px-4 py-2">
                      <select
                        value={editTax.tax_type}
                        onChange={(e) =>
                          setEditTax({ ...editTax, tax_type: e.target.value })
                        }
                        className="p-1 border rounded w-full text-sm"
                      >
                        <option value="inclusive">Inclusive</option>
                        <option value="exclusive">Exclusive</option>
                      </select>
                    </td>
                     */}
                      <td className="px-4 py-2">
                        <input
                          type="number"
                          value={editTax.rate}
                          onChange={(e) =>
                            setEditTax({ ...editTax, rate: e.target.value })
                          }
                          className="p-1 border rounded w-full text-sm"
                          step="0.01"
                        />
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-700">
                        {new Date(tax.creation_date).toLocaleDateString()}
                      </td>
                      {userRoleActionEnabled && (
                        <td className="px-4 py-2">
                          <div className="flex gap-2">
                            <button
                              onClick={() => {
                                if (!editTax.name.trim() || !editTax.rate) {
                                  setEditError(
                                    "Tax name and rate are required."
                                  );
                                  return;
                                }
                                setEditError("");
                                handleUpdateTax(tax.id, {
                                  name: editTax.name,
                                  description: editTax.description,
                                  tax_type: editTax.tax_type,
                                  rate: parseFloat(editTax.rate),
                                });
                              }}
                              disabled={updateTaxMutation.isLoading}
                              className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 text-sm"
                            >
                              {updateTaxMutation.isLoading
                                ? "Saving..."
                                : "Save"}
                            </button>
                            <button
                              onClick={() => {
                                setEditTax(null);
                                setEditError("");
                              }}
                              className="px-3 py-1 bg-gray-400 text-white rounded hover:bg-gray-500 text-sm"
                            >
                              Cancel
                            </button>
                          </div>
                          {editError && (
                            <p className="text-red-600 text-xs mt-1">
                              {editError}
                            </p>
                          )}
                        </td>
                      )}
                    </tr>
                  ) : (
                    <tr key={tax.id}>
                      <td className="px-4 py-2 text-sm text-gray-700">
                        {tax.name}
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-700">
                        {tax.description || "---"}
                      </td>
                      {/**
                     * Uncomment below if you want to show tax type:
                     * <td className="px-4 py-2 text-sm text-gray-700">
                      {tax.tax_type.charAt(0).toUpperCase() +
                        tax.tax_type.slice(1)}
                    </td>
                     */}
                      <td className="px-4 py-2 text-sm text-gray-700">
                        {tax.rate}%
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-700">
                        {new Date(tax.creation_date).toLocaleDateString()}
                      </td>
                      {userRoleActionEnabled && (
                        <td className="px-4 py-2">
                          <div className="flex gap-2">
                            <button
                              onClick={() => {
                                setEditTax(tax);
                                setEditError("");
                              }}
                              className="px-3 py-1 bg-gray-500 text-white rounded hover:bg-gray-600 text-sm"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleToggleArchive(tax)}
                              disabled={updateTaxMutation.isLoading}
                              className={`px-3 py-1 text-white text-sm rounded hover:bg-gray-700 ${
                                tax.archived ? "bg-blue-600" : "bg-red-600"
                              }`}
                            >
                              {tax.archived ? "Unarchive" : "Archive"}
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
        {updateTaxMutation.isError && (
          <p className="text-red-600 text-xs mt-1">
            {updateTaxMutation.error?.response?.data?.description ||
              "Error processing tax setting."}
          </p>
        )}
      </div>
    </div>
  );
};

export default Tax;
