import React, { useState } from "react";
import { EmptyRows, ErrorRows, FileRow, LoadingRows } from "./TableRows";

const ProjectFilesTable = ({
  columns = 10,
  files,
  folders,
  onFileRowClick,
  onEditFolder,
  onDeleteFolder,
  loading,
  error,
}) => {
  const [expandedFolders, setExpandedFolders] = useState([]);

  const toggleFolder = (folderId) => {
    setExpandedFolders((prev) =>
      prev.includes(folderId)
        ? prev.filter((id) => id !== folderId)
        : [...prev, folderId]
    );
  };

  // Group files by folder_id
  const filesByFolder = {};
  files?.forEach((file) => {
    const folderId = file.folder_id;
    if (folderId) {
      if (!filesByFolder[folderId]) filesByFolder[folderId] = [];
      filesByFolder[folderId].push(file);
    }
  });
  // Files without folder
  const unassignedFiles = files?.filter((file) => !file.folder_id);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full text-sm text-left text-gray-500">
        <tbody className="divide-y divide-gray-200">
          {loading ? (
            <LoadingRows colSpan={columns?.length + 1 || 1} />
          ) : error ? (
            <ErrorRows colSpan={columns?.length + 1 || 1} />
          ) : !folders?.length > 0 && !unassignedFiles?.length > 0 ? (
            <EmptyRows colSpan={columns?.length + 1 || 1} />
          ) : (
            <>
              {folders &&
                folders.map((folder) => {
                  // Get files inside this folder
                  const folderFiles = filesByFolder[folder.id] || [];
                  return (
                    <React.Fragment key={folder.id}>
                      {/* Folder Row */}
                      <tr
                        className="hover:bg-gray-100 group"
                        role="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFolder(folder.id);
                        }}
                      >
                        <td className="px-6 py-4 flex items-center">
                          {/* Folder Icon */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                            />
                          </svg>

                          {/* Folder Name */}
                          <span className="mx-2 font-semibold">
                            {folder.name}
                          </span>

                          {/* Edit Button – hidden by default */}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              onEditFolder(folder);
                            }}
                            className="text-gray-500 hover:text-blue-700 hidden group-hover:inline-block ml-4"
                            title="Edit Folder"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L7 21H3v-4L16.732 4.732z"
                              />
                            </svg>
                          </button>

                          {/* Delete Button – hidden by default */}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteFolder(folder);
                            }}
                            className="text-gray-500 hover:text-red-700 hidden group-hover:inline-block mx-2"
                            title="Delete Folder"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6M9 7h6m-6 0V4a1 1 0 011-1h4a1 1 0 011 1v3"
                              />
                            </svg>
                          </button>
                        </td>
                        <td />
                        <td className="px-6 py-4 flex justify-end">
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleFolder(folder.id);
                              }}
                              className="text-gray-500 hover:text-gray-700"
                              title="Expand/Collapse"
                            >
                              {expandedFolders.includes(folder.id) ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {/* Render files inside folder if expanded */}
                      {expandedFolders.includes(folder.id) &&
                        folderFiles.map((file) => (
                          <FileRow
                            key={file.id}
                            data={file}
                            onClick={onFileRowClick}
                            extraClasses="bg-white hover:bg-gray-50"
                            indentClass="pl-12"
                          />
                        ))}
                    </React.Fragment>
                  );
                })}
              {/* Unassigned files */}
              {unassignedFiles.map((file) => (
                <FileRow key={file.id} data={file} onClick={onFileRowClick} />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectFilesTable;
