import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import { useCreateFolder } from "../../../hooks/useFiles/useFiles";

const CreateFolder = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    parent_id: "",
  });

  const { mutate: createFolder, isLoading } = useCreateFolder();

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      name: formData.name,
      project_id: projectId,
      parent_id: formData.parent_id || null,
    };
    createFolder(payload, {
      onSuccess: () => {
        navigate(`/projects/${projectId}?view=files`);
      },
    });
  };

  return (
    <div>
      <PageHeadings title="Create New Folder" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Folder Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                placeholder="Enter folder name"
                className="border p-2 w-full rounded max-w-md"
                required
              />
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="bg-blue-600 text-white px-4 py-2 rounded"
            >
              {isLoading ? "Creating..." : "Create Folder"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateFolder;
