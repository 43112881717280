import React, { useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import Tabs from "../../../components/common/tabs/Tabs";
import { useDeleteInvoice } from "../../../hooks/useInvoices/useInvoices";
import EditInvoice from "./details/EditInvoice";
import InvoiceClient from "./details/InvoiceClient";
import ClientProjects from "../clients/details/ClientProjects";
import InvoiceItems from "./details/InvoiceItems";
import { getStatusBadgeClass } from "../../../components/common/tables/TableRows";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from "./details/InvoicePDF";
import { AuthContext } from "../../../context/AuthProvider";
import { useCompanyInfo } from "../../../hooks/useCompany";
import { getBase64FromUrl } from "../../../utils/helpers";
import SidebarDropdown from "../../../components/common/buttons/SidebarDropdown";

const InvoiceDetails = ({ data, closeSidebar }) => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;
  const { data: companyInfo } = useCompanyInfo(companyId);

  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState({});
  const [currentInvoice, setCurrentInvoice] = useState(data);

  const deleteInvoice = useDeleteInvoice();

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
      try {
        await deleteInvoice.mutateAsync({ invoiceId: currentInvoice.id });
        closeSidebar();
      } catch (err) {
        setError({ submit: "Failed to delete expense. Please try again." });
      }
    }
  };

  const handleInvoiceUpdate = (updatedInvoice) => {
    setCurrentInvoice((prev) => ({
      ...prev,
      ...updatedInvoice,
    }));
    setIsEditing(false);
  };

  const [logoBase64, setLogoBase64] = useState(null);

  useEffect(() => {
    if (companyInfo?.logo_url) {
      getBase64FromUrl(companyInfo.logo_url)
        .then((base64) => setLogoBase64(base64))
        .catch((err) => {
          console.error("Error converting logo to Base64:", err);
          setLogoBase64(null);
        });
    }
  }, [companyInfo?.logo_url]);

  const tabs = [
    {
      label: "Items",
      content: (
        <>
          <InvoiceItems
            currentInvoice={currentInvoice}
            items={Object.values(currentInvoice?.items || {})}
          />
          {Object.values(currentInvoice?.items || {}) && (
            <div className="mt-6">
              <PDFDownloadLink
                document={
                  <InvoicePDF
                    companyName={companyInfo?.name}
                    companyLogo={logoBase64}
                    invoiceData={currentInvoice}
                  />
                }
                fileName={`Invoice-${
                  currentInvoice?.invoice_number || "Unknown"
                }.pdf`}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition w-full block text-center"
              >
                {({ loading }) =>
                  loading ? "Generating PDF..." : "Download Invoice as PDF"
                }
              </PDFDownloadLink>
            </div>
          )}
        </>
      ),
    },
    {
      label: "Project",
      content: (
        <ClientProjects
          data={currentInvoice?.project ? [currentInvoice?.project] : []}
          title={"Invoice Not Linked to Project."}
          description={
            "Please edit this invoice or create a project to associate it."
          }
        />
      ),
    },
    {
      label: "Client",
      content: <InvoiceClient data={currentInvoice?.client} />,
    },
  ];

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg">
          {error.submit}
        </div>
      )}
      {isEditing ? (
        <EditInvoice
          invoiceData={currentInvoice}
          onCancel={handleEditToggle}
          onSave={handleInvoiceUpdate}
        />
      ) : (
        <div className="space-y-6">
          {/* Invoice Header */}
          <div className="flex items-center justify-between">
            <div>
              <h5 className="text-xl font-bold text-gray-900">
                {currentInvoice?.invoice_number || "---"}
              </h5>
              <span
                className={`text-xs font-medium px-2.5 py-0.5 rounded ${getStatusBadgeClass(
                  currentInvoice?.status
                )}`}
              >
                {currentInvoice?.status}
              </span>
            </div>
            {/* Options Dropdown */}
            <SidebarDropdown
              onEdit={handleEditToggle}
              onDelete={handleDelete}
            />
          </div>

          {/* Invoice Details */}
          <dl className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-3">
            <div>
              <dt className="text-sm font-medium text-gray-500">Issue Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currentInvoice?.issue_date
                  ? format(new Date(currentInvoice.issue_date), "MMMM dd, yyyy")
                  : "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Due Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currentInvoice?.due_date
                  ? format(new Date(currentInvoice.due_date), "MMMM dd, yyyy")
                  : "---"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Tax</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currentInvoice?.tax
                  ? `${currentInvoice.tax.name} (${currentInvoice.tax.rate}%)`
                  : currentInvoice?.tax_id
                  ? `Tax ID: ${currentInvoice.tax_id}`
                  : "---"}
              </dd>
            </div>
          </dl>

          {/* Tabs */}
          <div className="mt-6">
            <Tabs tabs={tabs} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetails;
