import React from "react";
import CardHeadings from "../pageHeadings/CardHeadings";

const NoAccessView = () => {
  return (
    <div className="bg-white rounded-md border border-gray-200 w-full p-4">
      <CardHeadings
        title="Restricted Access"
        description="You do not have access to this section."
      />
      <div className="px-4 pb-4">
        <p className="text-gray-600">
          It appears that your account does not have the required permissions to
          view this section.
        </p>
        <p className="text-gray-600 mt-2">
          If you believe this is an error or require access, please contact your
          administrator.
        </p>
      </div>
    </div>
  );
};

export default NoAccessView;
