import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

const ProductFiltersContainer = ({
  searchValue,
  onSearchChange,
  onFiltersChange,
  ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filters, setFilters] = useState({
    favorites: false,
    nonFavorites: false,
    pinned: false,
    notPinned: false,
    categories: [],
  });

  // Ref for the filter button container
  const dropdownRef = useRef(null);

  const categoryOptions = [
    { value: "cat1", label: "Category 1" },
    { value: "cat2", label: "Category 2" },
    { value: "cat3", label: "Category 3" },
  ];

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prev) => {
      const newFilters = { ...prev, [name]: checked };
      if (onFiltersChange) onFiltersChange(newFilters);
      return newFilters;
    });
  };

  // Handle changes from react-select
  const handleCategoriesChange = (selectedOptions) => {
    setFilters((prev) => {
      const newFilters = { ...prev, categories: selectedOptions };
      if (onFiltersChange) onFiltersChange(newFilters);
      return newFilters;
    });
  };

  // Optionally trigger filters update when applying
  const handleApplyFilters = () => {
    if (onFiltersChange) onFiltersChange(filters);
    setIsDropdownOpen(false);
  };

  // Close dropdown when clicking outside the filter button area
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div {...props} className="mb-4">
      {/* Search and Filter Button Row */}
      <div className="flex items-end justify-between space-y-4 sm:flex sm:space-y-0">
        <div className="flex items-center space-x-4">
          {/* Search Input */}
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-users"
              className="bg-white block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg sm:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search..."
              value={searchValue || ""}
              onChange={onSearchChange}
            />
          </div>
          {/* Filter Dropdown Toggle Button and Dropdown Container */}
          <div ref={dropdownRef} className="relative">
            <button
              type="button"
              onClick={toggleDropdown}
              className="flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-100"
            >
              Filters
              <svg
                className="ml-2 h-4 w-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            </button>
            {/* Dropdown */}
            {isDropdownOpen && (
              <div className="absolute top-full right-0 mt-2 w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
                {/* Favorites */}
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="favorites"
                    name="favorites"
                    checked={filters.favorites}
                    onChange={handleCheckboxChange}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="favorites" className="text-sm text-gray-700">
                    Favorites
                  </label>
                </div>
                {/* Pinned */}
                <div className="flex items-center space-x-2 mt-2">
                  <input
                    type="checkbox"
                    id="pinned"
                    name="pinned"
                    checked={filters.pinned}
                    onChange={handleCheckboxChange}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="pinned" className="text-sm text-gray-700">
                    Pinned
                  </label>
                </div>
                {/* Categories */}
                <div className="mt-2">
                  <label
                    className="block text-sm font-medium text-gray-700 mb-1"
                    htmlFor="categories"
                  >
                    Categories
                  </label>
                  <Select
                    isMulti
                    name="categories"
                    options={categoryOptions}
                    value={filters.categories}
                    onChange={handleCategoriesChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select categories..."
                  />
                </div>
                {/* Apply Filters Button */}
                <button
                  type="button"
                  onClick={handleApplyFilters}
                  className="mt-4 w-full rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
                >
                  Apply Filters
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFiltersContainer;
