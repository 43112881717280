import React, { useState, useCallback, useContext } from "react";
import {
  useComments,
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from "../../../../hooks/useClients/useClients";
import { AuthContext } from "../../../../context/AuthProvider";
import { getInitials } from "../../../../utils/helpers";

const ClientComments = ({ clientId }) => {
  const { user } = useContext(AuthContext);
  const [newCommentText, setNewCommentText] = useState("");
  const [editingComment, setEditingComment] = useState(null);

  const { data: comments = [], isLoading, isError } = useComments(clientId);
  const createComment = useCreateComment(clientId);
  const updateComment = useUpdateComment(clientId);
  const deleteComment = useDeleteComment(clientId);

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(dateString));
  };

  const handleCreateComment = useCallback(() => {
    if (!newCommentText.trim()) return;
    createComment.mutate(
      { text: newCommentText },
      {
        onSuccess: () => {
          setNewCommentText("");
        },
      }
    );
  }, [newCommentText, createComment]);

  const handleUpdateComment = useCallback(() => {
    if (!editingComment || !editingComment.text.trim()) return;
    updateComment.mutate(
      {
        noteId: editingComment.id,
        data: { text: editingComment.text },
      },
      {
        onSuccess: () => {
          setEditingComment(null);
        },
      }
    );
  }, [editingComment, updateComment]);

  const handleDeleteComment = useCallback(
    (commentId) => {
      deleteComment.mutate(commentId);
    },
    [deleteComment]
  );

  if (isLoading) return <p>Loading comments...</p>;
  if (isError) return <p>Error loading comments.</p>;

  return (
    <div className="my-4">
      <h3 className="text-base font-semibold mb-4">Comments</h3>

      <div className="space-y-3">
        {comments.map((comment) => (
          <div key={comment.id} className="text-sm">
            <div className="flex items-start gap-3">
              {/* Main Content */}
              <div className="flex-1">
                <div className="flex items-center justify-between mb-1">
                  <p className="font-semibold text-gray-700">
                    {comment.user?.username || "Deleted User"}
                  </p>
                  <p className="text-xs text-gray-500">
                    {comment.created_at && formatDate(comment.created_at)}
                  </p>
                </div>

                {editingComment?.id === comment.id ? (
                  <div>
                    <textarea
                      rows={2}
                      className="w-full rounded border border-gray-300 p-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                      style={{ whiteSpace: "pre-wrap" }}
                      value={editingComment.text}
                      onChange={(e) =>
                        setEditingComment({
                          ...editingComment,
                          text: e.target.value,
                        })
                      }
                      autoFocus
                    />
                    <div className="flex items-center gap-2 mt-1">
                      <button
                        onClick={handleUpdateComment}
                        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingComment(null)}
                        className="px-2 py-1 bg-gray-400 text-white rounded hover:bg-gray-500"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p
                      className="bg-gray-50 rounded px-2 py-1 text-gray-800 border border-gray-200"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {comment.text}
                    </p>
                    {/* Show edit/delete options only for the comment creator */}
                    {user?.id === comment.user?.id && (
                      <div className="flex items-center gap-4 text-xs text-gray-500 mt-1">
                        <button
                          onClick={() => setEditingComment(comment)}
                          className="hover:text-gray-700"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteComment(comment.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Add Comment Section */}
      <div className="mt-4">
        <textarea
          rows={2}
          className="w-full rounded border border-gray-300 p-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          style={{ whiteSpace: "pre-wrap" }}
          placeholder="Add a comment..."
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
        />
        <button
          onClick={handleCreateComment}
          className="mt-2 px-3 py-1 bg-blue-500 text-white text-sm rounded hover:bg-blue-600"
          disabled={!newCommentText.trim()}
        >
          Add Comment
        </button>
      </div>
    </div>
  );
};

export default ClientComments;
