import React, { useState } from "react";

const ImagesSection = ({ files }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => setSelectedImage(null);

  return (
    <section className="mb-12">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {files.map((file) => (
          <div
            key={file.id}
            className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200 cursor-pointer"
            onClick={() => setSelectedImage(file)}
          >
            <div className="w-full h-56 bg-gray-100">
              <img
                src={file.file_url}
                alt={file.name || "Image Preview"}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-3">
              <p className="text-sm text-gray-800 font-medium">
                {file.name || "Untitled Image"}
              </p>
            </div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out"
          role="dialog"
          aria-modal="true"
          onClick={handleClose}
        >
          <div
            className="relative max-w-full max-h-full p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-5 right-5 text-white text-3xl font-bold focus:outline-none"
              onClick={handleClose}
              aria-label="Close Image Preview"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src={selectedImage.file_url}
              alt={selectedImage.name || "Image Preview"}
              className="w-full max-w-4xl max-h-[90vh] object-contain mx-auto rounded"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default ImagesSection;
