import React from "react";
import LandingHeader from "../components/layout/landingLayout/LandingHeader";
import LandingFooter from "../components/layout/landingLayout/LandingFooter";
import Hero from "../components/layout/landingLayout/LandingSections/Hero";
import Quote from "../components/layout/landingLayout/LandingSections/Quote";
import Features from "../components/layout/landingLayout/LandingSections/Features";
import Pricing from "../components/layout/landingLayout/LandingSections/Pricing";
import Faqs from "../components/layout/landingLayout/LandingSections/Faqs";

// const IS_EARLY_ACCESS = false;
// isEarlyAccess={IS_EARLY_ACCESS}

const Landing = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <LandingHeader />

      {/* Main Content */}
      <main className="flex-grow mb-24">
        <Hero />
        <Quote />
        <Features />
        <Pricing />
        <Faqs />
      </main>

      {/* Footer */}
      <LandingFooter />
    </div>
  );
};

export default Landing;
