import React from "react";

const OtherFilesSection = ({ files }) => (
  <section className="mb-12">
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
      {files.map((file) => (
        <div
          key={file.id}
          className="bg-white rounded-lg shadow-sm transition-shadow duration-200 p-4 flex flex-col"
        >
          <div className="flex items-center justify-between mb-2">
            <p className="text-sm font-semibold text-gray-800">
              {file.name || "Unnamed File"}
            </p>
          </div>
          {file.file_url ? (
            <iframe
              src={`${file.file_url}?embed`}
              className="w-full h-[350px] border border-gray-200 rounded"
              title={file.name || "File Preview"}
            />
          ) : (
            <div className="flex items-center justify-center w-full h-[350px] bg-gray-50 border border-gray-200 rounded">
              <p className="text-gray-500 text-sm">No preview available</p>
            </div>
          )}
        </div>
      ))}
    </div>
  </section>
);

export default OtherFilesSection;
