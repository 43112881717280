import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  useCreateClientFile,
  useFoldersByProject,
} from "../../../hooks/useFiles/useFiles";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";

const CreateFile = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();

  // Added folder_id to state (empty means no folder assigned)
  const [formData, setFormData] = useState({
    name: "",
    file_type: "link",
    file_url: "",
    file: null,
    previewUrl: "", // For image preview
    is_public: false,
    project_id: projectId || "",
    folder_id: "", // Optional folder assignment
  });
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const { mutate: createFile, isLoading } = useCreateClientFile();

  // Fetch folders for the project (assumes hook returns an array of folder objects with id and name)
  const { data: folders, isLoading: isFoldersLoading } = useFoldersByProject({
    projectId,
  });

  // Define maximum file sizes in bytes
  const MAX_SIZE_PDF = 10 * 1024 * 1024; // 10MB
  const MAX_SIZE_IMAGE = 5 * 1024 * 1024; // 5MB

  // Allowed MIME types
  const ALLOWED_IMAGE_TYPES = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
  ];
  const ALLOWED_PDF_TYPES = ["application/pdf"];

  // Create a ref for the file input
  const fileInputRef = useRef(null);

  // -------------------------------
  // DRAG-AND-DROP EVENT HANDLERS
  // -------------------------------
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFile = e.dataTransfer.files && e.dataTransfer.files[0];
    if (droppedFile) {
      handleFileValidationAndSet(droppedFile);
    }
  };

  // Trigger hidden file input when clicking dropzone
  const handleClickDropzone = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // -------------------------------
  // FILE VALIDATION
  // -------------------------------
  const validateFile = (file) => {
    if (!file) {
      setError((prev) => ({ ...prev, file: "No file selected." }));
      return false;
    }

    const { file_type } = formData;

    if (file_type === "pdf") {
      if (!ALLOWED_PDF_TYPES.includes(file.type)) {
        setError((prev) => ({
          ...prev,
          file: "Please upload a valid PDF file.",
        }));
        return false;
      }
      if (file.size > MAX_SIZE_PDF) {
        setError((prev) => ({ ...prev, file: "PDF file size exceeds 10MB." }));
        return false;
      }
    } else if (file_type === "image") {
      if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
        setError((prev) => ({
          ...prev,
          file: "Please upload a PNG, JPG, JPEG, or GIF image.",
        }));
        return false;
      }
      if (file.size > MAX_SIZE_IMAGE) {
        setError((prev) => ({
          ...prev,
          file: "Image file size exceeds 5MB.",
        }));
        return false;
      }
    } else {
      setError((prev) => ({
        ...prev,
        file_type: "Unsupported file type selected.",
      }));
      return false;
    }

    setError((prev) => ({ ...prev, file: "" }));
    return true;
  };

  // Set file state once validation passes
  const handleFileValidationAndSet = (file) => {
    if (validateFile(file)) {
      let preview = "";
      if (formData.file_type === "image") {
        preview = URL.createObjectURL(file);
      }
      setFormData((prevData) => ({
        ...prevData,
        file,
        previewUrl: preview,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        file: null,
        previewUrl: "",
      }));
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  // When user selects a file via the hidden <input>
  const handleFileInputChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFileValidationAndSet(file);
    }
  };

  // -------------------------------
  // Handle form inputs (including folder assignment)
  // -------------------------------
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "file_type") {
      setFormData((prevData) => ({
        ...prevData,
        file_type: value,
        file: null,
        file_url: "",
        previewUrl: "",
      }));
      setError((prev) => ({ ...prev, file: "", file_url: "" }));
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
      setError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      return !!parsedUrl;
    } catch (error) {
      return false;
    }
  };

  const handleRemoveFile = () => {
    setFormData((prevData) => ({
      ...prevData,
      file: null,
      previewUrl: "",
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setError((prev) => ({ ...prev, file: "" }));
  };

  // -------------------------------
  // SUBMIT
  // -------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    setError({});
    setSuccessMessage("");

    if (!formData.name.trim()) {
      setError({ name: "File name is required." });
      return;
    }
    if (!formData.project_id) {
      setError({ project_id: "Project ID is required." });
      return;
    }

    const { file_type, file_url, file } = formData;

    if (["pdf", "image"].includes(file_type)) {
      if (!file) {
        setError({ file: "Please upload a PDF or Image file." });
        return;
      }
    } else if (["link", "embed", "canva"].includes(file_type)) {
      if (!file_url || !validateUrl(file_url)) {
        setError({ file_url: "A valid URL is required." });
        return;
      }
    } else {
      setError({ file_type: "Invalid file type selected." });
      return;
    }

    // Prepare data for submission (including folder_id)
    let submissionData;
    let headers = {};

    if (["pdf", "image"].includes(file_type)) {
      submissionData = new FormData();
      submissionData.append("name", formData.name);
      submissionData.append("file_type", formData.file_type);
      submissionData.append("file", formData.file);
      submissionData.append("is_public", formData.is_public);
      submissionData.append("project_id", formData.project_id);
      submissionData.append("folder_id", formData.folder_id);
    } else {
      submissionData = {
        name: formData.name,
        file_type: formData.file_type,
        file_url: formData.file_url,
        is_public: formData.is_public,
        project_id: formData.project_id,
        folder_id: formData.folder_id,
      };
      headers = { "Content-Type": "application/json" };
    }

    createFile(submissionData, {
      headers,
      onSuccess: () => {
        setSuccessMessage("File created successfully!");
        setTimeout(() => {
          navigate(`/projects/${formData.project_id}`);
        }, 1500);
      },
      onError: (err) => {
        setError({
          submit:
            err.response?.data?.description ||
            "An error occurred while creating the file.",
        });
      },
    });
  };

  // Cleanup preview URL to avoid memory leaks
  useEffect(() => {
    return () => {
      if (formData.previewUrl) {
        URL.revokeObjectURL(formData.previewUrl);
      }
    };
  }, [formData.previewUrl]);

  // Map folders to react-select options
  const folderOptions = folders
    ? folders.map((folder) => ({
        label: folder.name,
        value: folder.id,
      }))
    : [];

  return (
    <div>
      <PageHeadings title="Create Client File" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {successMessage && (
            <div className="mb-4 p-4 text-green-700 bg-green-100 rounded">
              {successMessage}
            </div>
          )}

          {error.submit && (
            <div className="mb-4 p-4 text-red-700 bg-red-100 rounded">
              {error.submit}
            </div>
          )}

          <form
            onSubmit={handleSubmit}
            className="space-y-4"
            encType="multipart/form-data"
          >
            {/* Folder Assignment using react-select */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Folder (Optional)
              </label>
              <Select
                options={folderOptions}
                value={
                  folderOptions.find(
                    (option) => option.value === formData.folder_id
                  ) || null
                }
                onChange={(selectedOption) =>
                  setFormData((prev) => ({
                    ...prev,
                    folder_id: selectedOption ? selectedOption.value : "",
                  }))
                }
                isClearable
                placeholder="Select a folder"
                className="max-w-md"
              />
            </div>

            {/* File Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                File Name
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter a descriptive file name"
                value={formData.name}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                required
              />
              {error.name && (
                <div className="text-red-500 text-sm mt-1">{error.name}</div>
              )}
            </div>

            {/* File Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                File Type
              </label>
              <select
                name="file_type"
                value={formData.file_type}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="link">Link</option>
                <option value="embed">Embed</option>
                <option value="pdf">PDF</option>
                <option value="image">Image</option>
                <option value="canva">Canva</option>
              </select>
              {error.file_type && (
                <div className="text-red-500 text-sm mt-1">
                  {error.file_type}
                </div>
              )}
            </div>

            {/* Conditional File Upload (drag-and-drop) or URL Input */}
            {["pdf", "image"].includes(formData.file_type) ? (
              <div>
                {!formData.file ? (
                  <div
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={handleClickDropzone}
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500">
                        {formData.file_type === "pdf"
                          ? "PDF (MAX. 10MB)"
                          : "PNG, JPG, JPEG, GIF (MAX. 5MB)"}
                      </p>
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      name="file"
                      accept={
                        formData.file_type === "pdf"
                          ? "application/pdf"
                          : "image/png, image/jpeg, image/jpg, image/gif"
                      }
                      onChange={handleFileInputChange}
                      className="hidden"
                    />
                  </div>
                ) : (
                  <div className="w-full max-w-md p-4 border rounded-md relative">
                    {formData.file_type === "image" && formData.previewUrl && (
                      <img
                        src={formData.previewUrl}
                        alt="Uploaded"
                        className="max-h-64 mx-auto"
                      />
                    )}

                    {formData.file_type === "pdf" && (
                      <div className="flex flex-col">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-8 mb-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>
                        <p className="text-gray-700">{formData.file.name}</p>
                      </div>
                    )}

                    <button
                      type="button"
                      onClick={handleRemoveFile}
                      className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>
                )}

                {error.file && (
                  <div className="text-red-500 text-sm mt-1 w-full text-center">
                    {error.file}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  File URL
                </label>
                <input
                  type="url"
                  name="file_url"
                  placeholder={
                    formData.file_type === "link"
                      ? "https://example.com/link"
                      : formData.file_type === "embed"
                      ? "https://example.com/embed"
                      : "https://example.com"
                  }
                  value={formData.file_url}
                  onChange={handleChange}
                  className="border p-2 w-full rounded max-w-md"
                  required
                />
                {error.file_url && (
                  <div className="text-red-500 text-sm mt-1">
                    {error.file_url}
                  </div>
                )}
              </div>
            )}

            {/* Public Toggle */}
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                name="is_public"
                checked={formData.is_public}
                onChange={handleChange}
                className="mr-2"
              />
              <label className="text-sm text-gray-700">Make Public</label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
            >
              {isLoading ? "Creating..." : "Create File"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateFile;
