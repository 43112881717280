import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const MainStats = ({ data, companyInfo }) => {
  const { t } = useTranslation();
  return (
    <>
      <StatCard
        title={t("home.mainStats.totalProjects")}
        value={data?.pagination?.total_items || 0}
      />
      <PlanCard tier={companyInfo?.tier} />
    </>
  );
};

const StatCard = ({ title, value }) => {
  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-1">
      <div className="flex items-center">
        <div className="ml-4">
          <h3 className="text-md font-semibold text-gray-700">{title}</h3>
          <p className="text-2xl font-bold text-gray-900">{value}</p>
        </div>
      </div>
    </div>
  );
};

const PlanCard = ({ tier }) => {
  const { t } = useTranslation();

  let planName, cta;

  switch (tier) {
    case "1":
      planName = t("home.mainStats.plans.pro");
      break;
    case "2":
      planName = t("home.mainStats.plans.enterprise");
      break;
    case "0":
    default:
      planName = t("home.mainStats.plans.free");
      cta = (
        <NavLink
          className="ml-auto px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          to={"/settings?tab=Billing"}
        >
          {t("home.mainStats.upgradeNow")}
        </NavLink>
      );
  }

  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-blue-50 border border-blue-300 p-6 col-span-1">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-md font-semibold text-gray-700">
            {t("home.mainStats.currentPlan")}{" "}
          </h3>
          <p className="text-2xl font-bold text-gray-900">{planName}</p>
        </div>
        {/*cta*/}
      </div>
    </div>
  );
};

export default MainStats;
