import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const { t } = useTranslation();
  // State to keep track of the currently open FAQ item.
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle the open state for a given FAQ index.
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Define FAQ data using translation keys.
  const faqData = [
    {
      question: t("faqs.question1", "How does the free beta work?"),
      answer: t(
        "faqs.answer1",
        "During our beta, you and your team will enjoy unlimited access to Plura's full suite of tools. In return, we simply ask for your valuable feedback to help us make Plura even better."
      ),
    },
    {
      question: t("faqs.question2", "Who was Plura built for?"),
      answer: t(
        "faqs.answer2",
        "Plura was designed for teams that demand powerful yet effortless tools to streamline project management, enhance collaboration, and drive success."
      ),
    },
    {
      question: t("faqs.question3", "Is customer support available 24/7?"),
      answer: t(
        "faqs.answer3",
        "Yes! Our dedicated support team is available around the clock to help you with any questions or issues you may encounter."
      ),
    },
    {
      question: t("faqs.question4", "How secure is my data?"),
      answer: t(
        "faqs.answer4",
        "We take data security very seriously. We use industry-standard encryption and best practices to ensure that your information remains safe and confidential."
      ),
    },
  ];

  return (
    <section className="max-w-3xl mx-auto px-4 py-8">
      <h2 className="text-center text-blue-600 mb-2 font-medium">
        {t("faqs.title", "FAQS")}
      </h2>
      <p className="text-3xl font-bold text-center mb-8">
        {t("faqs.subtitle", "Common Questions")}
      </p>

      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border rounded-md">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full flex justify-between items-center p-4 text-left focus:outline-none"
            >
              <span className="font-medium text-gray-800">{faq.question}</span>
              <svg
                className={`w-5 h-5 transition-transform duration-200 ${
                  openIndex === index ? "rotate-180" : "rotate-0"
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {openIndex === index && (
              <div className="p-4 border-t bg-gray-50 text-gray-700">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faqs;
