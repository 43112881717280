import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateProduct } from "../../../hooks/useProducts/products";

const CreateProductForm = () => {
  const navigate = useNavigate();
  const createProductMutation = useCreateProduct();

  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    product_type: "",
    description: "",
    price: "",
    discount_percentage: "",
    is_favorite: false,
    is_pinned: false,
    sku: "",
    provider_id: "",
    category_id: "",
  });

  // Example only: In real usage, fetch from your API
  const categories = [{ id: "cat1", name: "General" }];
  const providers = [{ id: "prov1", name: "Acme Supplies" }];

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    const payload = {
      ...formData,
      price: parseFloat(formData.price),
      discount_percentage: parseFloat(formData.discount_percentage) || 0,
    };

    createProductMutation.mutate(payload, {
      onSuccess: () => navigate("/product-library"),
      onError: (err) => setError(err.message || "Failed to create product"),
    });
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="mb-6 text-2xl font-semibold text-gray-800">
        Create New Product
      </h2>

      {error && (
        <div className="mb-4 rounded bg-red-100 p-3 text-red-600">{error}</div>
      )}

      <form onSubmit={handleSubmit} className="max-w-lg space-y-5">
        {/* Name */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Product Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            required
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>

        {/* Product Type */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Product Type
          </label>
          <input
            type="text"
            placeholder="e.g. Furniture, Service..."
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.product_type}
            onChange={(e) => handleChange("product_type", e.target.value)}
          />
        </div>

        {/* Description */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            rows={3}
            className="w-full rounded border border-gray-300 p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Enter product description"
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
          />
        </div>

        {/* SKU */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            SKU (optional)
          </label>
          <input
            type="text"
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Unique SKU code"
            value={formData.sku}
            onChange={(e) => handleChange("sku", e.target.value)}
          />
        </div>

        {/* Price */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Price <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            step="0.01"
            required
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.price}
            onChange={(e) => handleChange("price", e.target.value)}
          />
        </div>

        {/* Discount */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Discount (%)
          </label>
          <input
            type="number"
            step="0.01"
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.discount_percentage}
            onChange={(e) =>
              handleChange("discount_percentage", e.target.value)
            }
          />
        </div>

        {/* Provider */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Provider
          </label>
          <select
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.provider_id}
            onChange={(e) => handleChange("provider_id", e.target.value)}
          >
            <option value="">Select a provider</option>
            {providers.map((prov) => (
              <option key={prov.id} value={prov.id}>
                {prov.name}
              </option>
            ))}
          </select>
        </div>

        {/* Category */}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Category
          </label>
          <select
            className="w-full rounded border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.category_id}
            onChange={(e) => handleChange("category_id", e.target.value)}
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        {/* Submit */}
        <button
          type="submit"
          disabled={createProductMutation.isLoading}
          className="inline-block rounded bg-blue-600 px-4 py-2 text-white transition hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {createProductMutation.isLoading ? "Creating..." : "Create Product"}
        </button>
      </form>
    </div>
  );
};

export default CreateProductForm;
