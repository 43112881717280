import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateClient } from "../../../hooks/useClients/useClients";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useUsers } from "../../../hooks/useUsers";
import Select from "react-select";
import { AuthContext } from "../../../context/AuthProvider";

const CreateClient = () => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    status: "Lead",
    source: "",
    industry: "",
    extra_info: "",
  });
  const [error, setError] = useState({});
  const [defaultCountry, setDefaultCountry] = useState("us");
  const createClient = useCreateClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userRoleActionEnabled) {
      navigate("/clients");
    }
  }, [userRoleActionEnabled, navigate]);

  const {
    data: usersData,
    isLoading: usersLoading,
    isError: usersError,
  } = useUsers(1, 100);

  // Build options for react-select:
  const userOptions = useMemo(() => {
    if (!usersData || !usersData.users) return [];
    return usersData.users.map((user) => ({
      value: user.id,
      label: user.username,
    }));
  }, [usersData]);

  useEffect(() => {
    const getDefaultCountry = () => {
      const locale = navigator.language || navigator.userLanguage;
      const country = locale.split("-")[1]?.toLowerCase();
      if (country) {
        setDefaultCountry(country);
      }
    };
    getDefaultCountry();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({ ...prevData, phone: value }));
  };

  const validateInputs = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required.";
    }
    // Validate email only if provided.
    if (formData.email && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Enter a valid email.";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required.";
    }
    if (!formData.status) {
      errors.status = "Status is required.";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    if (!validateInputs()) return;

    // Optionally, convert lead_score to a number
    const preparedData = {
      ...formData,
      lead_score: formData.lead_score ? Number(formData.lead_score) : 0,
    };

    try {
      await createClient.mutateAsync(preparedData);
      navigate("/clients");
    } catch (error) {
      setError({ submit: "Failed to create client. Please try again." });
    }
  };

  return (
    <div>
      <PageHeadings title="Add New Client" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {error.submit && (
            <div
              className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Error</span>
              <div>{error.submit}</div>
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Client Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client Name
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter client name"
                value={formData.name}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.name && (
                <div className="text-red-500 text-sm mt-1">{error.name}</div>
              )}
            </div>

            {/* Client Status */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client Status
              </label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="Lead">Lead</option>
                <option value="Qualified">Qualified</option>
                <option value="Proposal Sent">Proposal Sent</option>
                <option value="Negotiation">Negotiation</option>
                <option value="Active Client">Active Client</option>
                <option value="On Hold">On Hold</option>
                <option value="Completed">Completed</option>
                <option value="Past Client">Past Client</option>
              </select>
              {error.status && (
                <div className="text-red-500 text-sm mt-1">{error.status}</div>
              )}
            </div>

            {/* Client Phone */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone
              </label>
              <PhoneInput
                country={defaultCountry}
                value={formData.phone}
                onChange={handlePhoneChange}
                placeholder="Enter client phone number"
                inputClass="w-full border p-2 rounded"
                enableSearch={true}
              />
              {error.phone && (
                <div className="text-red-500 text-sm mt-1">{error.phone}</div>
              )}
            </div>

            {/* Client Email (Optional) */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email (optional)
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter client email"
                value={formData.email}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
              {error.email && (
                <div className="text-red-500 text-sm mt-1">{error.email}</div>
              )}
            </div>

            {/* Client Address */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address (optional)
              </label>
              <input
                type="text"
                name="address"
                placeholder="Enter client address"
                value={formData.address}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* NEW FIELDS */}

            {/* Client Source */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client Source (optional)
              </label>
              <select
                name="source"
                value={formData.source || ""}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="">Select client source</option>
                <option value="Social Media">Social Media</option>
                <option value="Referral">Referral</option>
                <option value="Ad Campaign">Ad Campaign</option>
                <option value="Event">Event</option>
                <option value="Website">Website</option>
                <option value="Other">Other</option>
              </select>
            </div>

            {/* Industry */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Industry (optional)
              </label>
              <select
                name="industry"
                value={formData.industry || ""}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="">Select industry</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
                <option value="Other">Other</option>
              </select>
            </div>

            {/* Assigned User ID */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Owner (optional)
              </label>
              {usersLoading ? (
                <div className="text-sm text-gray-500">Loading users...</div>
              ) : usersError ? (
                <div className="text-sm text-red-500">Error loading users</div>
              ) : (
                <Select
                  name="assigned_user_id"
                  options={userOptions}
                  value={
                    userOptions.find(
                      (option) => option.value === formData.assigned_user_id
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      assigned_user_id: selectedOption
                        ? selectedOption.value
                        : "",
                    }))
                  }
                  isClearable
                  placeholder="Select assigned user"
                  className="max-w-md"
                />
              )}
            </div>

            {/* Extra Info */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Extra Info (optional)
              </label>
              <textarea
                name="extra_info"
                placeholder="Enter any additional notes or information"
                value={formData.extra_info || ""}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                rows={3}
              ></textarea>
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Client
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateClient;
