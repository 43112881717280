import React, { useState, useMemo, useEffect, useRef } from "react";
import Select from "react-select";

const TaskFilters = ({
  phaseOptions,
  phaseFilter,
  setPhaseFilter,
  usersLoading,
  usersError,
  userOptions,
  usersFilter,
  setUsersFilter,
  statusOptions,
  statusFilter,
  setStatusFilter,
  filterByProjectStatus = false,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const containerRef = useRef(null);

  // Compute the number of active filters.
  const activeFilterCount = useMemo(() => {
    let count = 0;
    if (phaseFilter) count++;
    if (usersFilter && usersFilter?.length > 0) count++;
    if (statusFilter) count++;
    return count;
  }, [phaseFilter, usersFilter, statusFilter]);

  // Handle clicks outside the component to close the filter dropdown.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={"relative"} ref={containerRef}>
      {/* Toggle Button */}
      <button
        type="button"
        onClick={() => setShowFilters((prev) => !prev)}
        className="flex items-center px-4 py-2 shadow-sm rounded-lg text-sm font-medium border border-gray-200 text-gray-900 bg-white hover:bg-gray-50 mb-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5 mr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
          />
        </svg>
        <span>Filters</span>
        {activeFilterCount > 0 && (
          <span className="ml-2 inline-block px-2 py-0.5 text-xs font-semibold text-gray-700 bg-gray-200 rounded-full">
            {activeFilterCount}
          </span>
        )}
      </button>

      {/* Dropdown Filter Panel */}
      {showFilters && (
        <div className="absolute z-10 mt-2 w-80 bg-white p-4 rounded shadow">
          {/* Phase Filter (Single Select) */}
          <div className="mb-4">
            <label className="block text-xs font-medium text-gray-700 mb-1">
              Phase
            </label>
            <Select
              options={phaseOptions}
              value={phaseFilter}
              onChange={setPhaseFilter}
              isClearable
              placeholder="All phases"
            />
          </div>

          {/* Users Filter (Multi-Select) */}
          <div className="mb-4">
            <label className="block text-xs font-medium text-gray-700 mb-1">
              Assigned Users
            </label>
            {usersLoading ? (
              <div className="text-xs text-gray-500">Loading users...</div>
            ) : usersError ? (
              <div className="text-xs text-red-500">Error loading users</div>
            ) : (
              <Select
                options={userOptions}
                value={usersFilter}
                onChange={setUsersFilter}
                isMulti
                placeholder="Any user"
              />
            )}
          </div>

          {/* Project Status Filter (Multi-Select) */}
          {filterByProjectStatus && (
            <div className="mb-4">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                Project Status
              </label>
              <Select
                options={statusOptions}
                value={statusFilter}
                onChange={setStatusFilter}
                isClearable
                placeholder="All statuses"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskFilters;
