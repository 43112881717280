import React, { useEffect, useState } from "react";
/*
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();
*/

// A component that handles the iframe loading and fallback logic for one PDF file
const PDFIframe = ({ file }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFallback, setShowFallback] = useState(false);
  /*
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  <Document file={file.file_url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>*/

  useEffect(() => {
    // Set a timeout to show fallback if PDF isn't loaded within 10 seconds
    const timer = setTimeout(() => {
      if (!isLoaded) {
        setShowFallback(true);
      }
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, [isLoaded]);

  return (
    <>
      {!showFallback ? (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            file.file_url
          )}&embedded=true`}
          className="w-full h-[600px] border border-gray-200 rounded"
          title={file.name || "PDF Document"}
          onLoad={() => setIsLoaded(true)}
        />
      ) : (
        <div className="w-full h-[600px] border border-gray-200 rounded flex flex-col items-center justify-center">
          <p className="mb-4">
            PDF could not be loaded. Please try again or download the file.
          </p>
          <a href={file.file_url} className="text-blue-600 underline" download>
            Download PDF
          </a>
        </div>
      )}
    </>
  );
};

const PDFSection = ({ files }) => (
  <section className="mb-12">
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
      {files.map((file) => (
        <div
          key={file.id}
          className="bg-white rounded-lg shadow-sm transition-shadow duration-200 p-4"
        >
          <div className="mb-2 flex items-center justify-between">
            <p className="text-sm text-gray-800 font-semibold">
              {file.name || "PDF Document"}
            </p>
          </div>
          <PDFIframe file={file} />
        </div>
      ))}
    </div>
  </section>
);

export default PDFSection;
