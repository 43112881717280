import React from "react";
import { useTranslation } from "react-i18next";

const Quote = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-50 py-24">
      <div className="max-w-screen-xl px-4 mx-auto text-center">
        <figure className="mx-auto max-w-3xl">
          <blockquote>
            <p className="text-2xl font-light text-gray-900 mb-6">
              {t(
                "quote.description",
                "We help simplify operations and improve profitability so designers and architects can focus on what they do best…"
              )}
            </p>
            <h2 className="text-3xl font-extrabold text-gray-900">
              {t("quote.title", "Create unique and memorable spaces.")}
            </h2>
          </blockquote>
        </figure>
      </div>
    </section>
  );
};

export default Quote;
