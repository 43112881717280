import React from "react";

const FILE_PREVIEW_CONTAINER_STYLE = {
  position: "relative",
  width: "100%",
  height: "0",
  paddingTop: "56.25%",
  boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
  marginTop: "1.6em",
  marginBottom: "0.9em",
  overflow: "hidden",
  borderRadius: "8px",
};

const FilePreview = ({ fileData }) => {
  if (["pdf", "image"].includes(fileData.file_type) && fileData.file_url) {
    if (fileData.file_type === "image") {
      return (
        <div className="mt-4">
          <img
            src={fileData.file_url}
            alt={fileData.name}
            className="max-w-full h-auto rounded shadow"
          />
        </div>
      );
    }
    return (
      <div style={FILE_PREVIEW_CONTAINER_STYLE}>
        <iframe
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
          }}
          src={fileData.file_url}
          title="PDF File"
        />
      </div>
    );
  }

  if (["canva", "embed"].includes(fileData.file_type) && fileData.file_url) {
    return (
      <div style={FILE_PREVIEW_CONTAINER_STYLE}>
        <iframe
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
          }}
          src={
            fileData.file_type === "canva"
              ? `${fileData.file_url}?embed`
              : fileData.file_url
          }
          allowFullScreen
          title={
            fileData.file_type === "canva" ? "Canva File" : "Embedded File"
          }
        />
      </div>
    );
  }
  return null;
};

export default FilePreview;
