import React from "react";
import Tabs from "../../../components/common/tabs/Tabs";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import Account from "./account/Account";
import Users from "./users/Users";
import Invoices from "./billing/Invoices";
import Billing from "./billing/Billing";
import Projects from "./projects/Projects";
import Integrations from "./integrations/Integrations";
import Tax from "./tax/Tax";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t("settings.tabs.account"),
      value: "account",
      content: <Account />,
    },
    {
      label: t("settings.tabs.users"),
      value: "users",
      content: <Users />,
    },
    {
      label: t("settings.tabs.projects"),
      value: "projects",
      content: <Projects />,
    },
    {
      label: t("settings.tabs.tax"),
      value: "tax",
      content: <Tax />,
    },
    {
      label: t("settings.tabs.integrations"),
      value: "integrations",
      content: <Integrations />,
    },
    // Optional tabs (if needed)
    // {
    //   label: t("settings.tabs.billing"),
    //   value: 'billing',
    //   content: <Billing />,
    // },
    // {
    //   label: t("settings.tabs.invoices"),
    //   value: 'invoices',
    //   content: <Invoices />,
    // },
  ];
  return (
    <div>
      <PageHeadings title={t("settings.title")} tags={[]} showCta={false} />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Settings;
