import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useUpdateInvoice } from "../../../../hooks/useInvoices/useInvoices";
import { useProjects } from "../../../../hooks/useProjects/useProjects";
import { useClients } from "../../../../hooks/useClients/useClients";
import { useTaxes } from "../../../../hooks/useTaxes/useTaxes";
import { InvoiceItems } from "../../../../components/common/inputs/InvoiceItems";

const EditInvoice = ({ invoiceData, onCancel, onSave }) => {
  // Fetch projects, clients, and taxes.
  const { data: projectsData } = useProjects(1, 10000);
  const { data: clientsData } = useClients(1, 10000);
  const { data: taxesData = [] } = useTaxes(false);

  // Prepare dropdown options.
  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];
  const clientOptions =
    clientsData?.clients.map((client) => ({
      value: client.id,
      label: client.name,
    })) || [];
  const taxOptions = taxesData.map((tax) => ({
    value: tax.id,
    label: `${tax.name} (${tax.rate}%)`,
    tax_type: tax.tax_type,
    rate: parseFloat(tax.rate),
  }));

  // Initialize formData from invoiceData.
  const [formData, setFormData] = useState({
    ...invoiceData,
    project_id: invoiceData?.project_id || "",
    client_id: invoiceData?.client_id || "",
    items: invoiceData?.items || {},
    tax_id: invoiceData?.tax_id || "",
  });
  const [error, setError] = useState({});
  const updateInvoice = useUpdateInvoice();

  // Update formData if invoiceData changes.
  useEffect(() => {
    setFormData({
      ...invoiceData,
      project_id: invoiceData?.project_id || "",
      client_id: invoiceData?.client_id || "",
      items: invoiceData?.items || {},
      tax_id: invoiceData?.tax_id || "",
    });
  }, [invoiceData]);

  // Recalculate totals and final amount.
  useEffect(() => {
    const total = Object.values(formData.items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );
    const discount =
      formData.discount_type === "percentage"
        ? (total * parseFloat(formData.discount_value || 0)) / 100
        : parseFloat(formData.discount_value || 0);
    let final = Math.max(total - discount, 0);

    const selectedTax = taxOptions.find(
      (option) => option.value === formData.tax_id
    );
    if (selectedTax) {
      final += total * (selectedTax.rate / 100);
    }
    setFormData((prevData) => ({
      ...prevData,
      total_amount: total,
      final_amount: final,
    }));
  }, [
    formData.items,
    formData.discount_value,
    formData.discount_type,
    formData.tax_id,
    taxesData,
  ]);

  // General change handler.
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Invoice items change handler.
  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? Math.max(1, parseFloat(value))
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };
      return { ...prevData, items: updatedItems };
    });
  };

  // Add a new item.
  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: { name: "", description: "", quantity: 1, value: 0 },
      },
    }));
  };

  // Remove an item.
  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];
      return { ...prevData, items: updatedItems };
    });
  };

  // Validate inputs.
  const validateInputs = () => {
    const errors = {};
    if (!formData.due_date?.trim()) errors.due_date = "Due date is required.";
    if (formData.total_amount <= 0)
      errors.total_amount = "Total amount must be greater than 0.";

    Object.keys(formData.items).forEach((itemId) => {
      const item = formData.items[itemId];
      if (!item.name.trim())
        errors[`itemName${itemId}`] = "Item name is required.";
      if (!item.value || isNaN(item.value) || item.value <= 0)
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit the form.
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    if (!validateInputs()) return;

    // Clean up item values.
    const cleanedData = {
      ...formData,
      items: Object.fromEntries(
        Object.entries(formData.items).map(([key, item]) => [
          key,
          {
            ...item,
            value: parseFloat(item.value),
            quantity: parseInt(item.quantity, 10),
          },
        ])
      ),
    };

    try {
      const updatedInvoice = await updateInvoice.mutateAsync({
        invoiceId: invoiceData.id,
        data: cleanedData,
      });
      onSave(updatedInvoice);
    } catch (err) {
      setError({ submit: "Failed to update invoice. Please try again." });
    }
  };

  return (
    <div>
      {error.submit && (
        <div className="mb-3 rounded-md bg-red-50 p-3 text-red-600">
          {error.submit}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6 text-sm">
        {/* Invoice Info */}
        <div className="max-w-xs">
          <div className="mb-2 font-bold text-gray-700">Invoice Info</div>
          <div className="grid grid-cols-1 gap-4">
            {/* Status */}
            <div>
              <label htmlFor="status" className="block text-gray-700">
                Status
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="Pending">Pending</option>
                <option value="Paid">Paid</option>
                <option value="Overdue">Overdue</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
            {/* Due Date */}
            <div>
              <label htmlFor="due_date" className="block text-gray-700">
                Due Date
              </label>
              <input
                type="date"
                id="due_date"
                name="due_date"
                value={formData.due_date ? formData.due_date.split("T")[0] : ""}
                onChange={handleChange}
                className={`mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                  error.due_date ? "border-red-500" : ""
                }`}
              />
              {error.due_date && (
                <p className="mt-1 text-xs text-red-600">{error.due_date}</p>
              )}
            </div>
          </div>
        </div>

        {/* Client & Project */}
        <div className="max-w-xs">
          <div className="mb-2 font-bold text-gray-700">
            Client &amp; Project
          </div>
          <div className="grid grid-cols-1 gap-4">
            {/* Client Dropdown */}
            <div>
              <label className="block text-gray-700">Client</label>
              <AsyncSelect
                className="mt-1 text-sm"
                cacheOptions
                defaultOptions={clientOptions}
                isClearable
                placeholder="Select a client"
                value={
                  clientOptions.find(
                    (option) => option.value === formData.client_id
                  ) || null
                }
                onChange={(selected) =>
                  setFormData((prev) => ({
                    ...prev,
                    client_id: selected ? selected.value : "",
                  }))
                }
                loadOptions={(inputValue, callback) =>
                  callback(
                    clientOptions.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  )
                }
              />
            </div>
            {/* Project Dropdown */}
            <div>
              <label className="block text-gray-700">Project (Optional)</label>
              <AsyncSelect
                className="mt-1 text-sm"
                cacheOptions
                defaultOptions={projectOptions}
                isClearable
                placeholder="Select a project"
                value={
                  projectOptions.find(
                    (option) => option.value === formData.project_id
                  ) || null
                }
                onChange={(selected) =>
                  setFormData((prev) => ({
                    ...prev,
                    project_id: selected ? selected.value : "",
                  }))
                }
                loadOptions={(inputValue, callback) =>
                  callback(
                    projectOptions.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* Items */}
        <div>
          <InvoiceItems
            items={formData.items}
            errors={error}
            onItemChange={handleItemChange}
            onAddItem={addItem}
            onRemoveItem={removeItem}
          />
        </div>

        {/* Totals, Discounts &amp; Tax */}
        <div className="max-w-xs">
          <div className="mb-2 font-bold text-gray-700">
            Totals, Discounts &amp; Tax
          </div>
          <div className="grid grid-cols-1 gap-4">
            {/* Tax */}
            <div>
              <label className="block text-gray-700">Tax (Optional)</label>
              <AsyncSelect
                className="mt-1 text-sm"
                cacheOptions
                defaultOptions={taxOptions}
                isClearable
                placeholder="Select a tax option"
                value={
                  taxOptions.find(
                    (option) => option.value === formData.tax_id
                  ) || null
                }
                onChange={(selected) =>
                  setFormData((prev) => ({
                    ...prev,
                    tax_id: selected ? selected.value : "",
                  }))
                }
              />
            </div>

            {/* Discount Type */}
            <div>
              <label className="block text-gray-700">Discount Type</label>
              <select
                name="discount_type"
                value={formData.discount_type}
                onChange={handleChange}
                className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="percentage">Percentage</option>
                <option value="value">Value</option>
              </select>
            </div>

            {/* Discount Value */}
            <div>
              <label className="block text-gray-700">Discount Value</label>
              <input
                type="number"
                name="discount_value"
                value={formData.discount_value}
                onChange={handleChange}
                className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
            </div>

            {/* Total Amount */}
            <div>
              <label className="block text-gray-700">Total Amount</label>
              <input
                type="text"
                name="total_amount"
                value={
                  formData.total_amount
                    ? new Intl.NumberFormat().format(formData.total_amount)
                    : 0
                }
                readOnly
                disabled
                className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
              />
              {error.total_amount && (
                <p className="mt-1 text-xs text-red-600">
                  {error.total_amount}
                </p>
              )}
            </div>

            {/* Final Amount */}
            <div>
              <label className="block text-gray-700">Final Amount</label>
              <input
                type="text"
                name="final_amount"
                value={
                  formData.final_amount
                    ? new Intl.NumberFormat().format(formData.final_amount)
                    : 0
                }
                readOnly
                disabled
                className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
              />
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="flex space-x-4 mt-4">
          <button
            type="submit"
            className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 disabled:opacity-50"
            disabled={updateInvoice.isLoading}
          >
            {updateInvoice.isLoading ? "Saving..." : "Save Changes"}
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="rounded bg-gray-600 px-4 py-2 text-white hover:bg-gray-700 focus:ring-2 focus:ring-gray-500 focus:ring-offset-1"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditInvoice;
