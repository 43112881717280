import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ProjectHeader = ({
  data,
  logo,
  banner,
  enableBreadCrumb = false,
  projectName,
  folderName,
}) => {
  const navigate = useNavigate();

  // Render the logo: if a logo URL is provided, display the image, otherwise a placeholder with the company name.
  const renderLogo = () => {
    if (logo) {
      return (
        <img
          src={logo}
          alt={`${data.name} logo`}
          className="size-32 rounded border-4 border-white object-cover"
        />
      );
    }
    return (
      <div className="size-32 flex text-center items-center justify-center rounded border-4 border-white bg-gray-200 text-md font-bold text-gray-800">
        {data.name}
      </div>
    );
  };

  // Render header in breadcrumb mode (no banner; inline logo and breadcrumb)
  if (enableBreadCrumb) {
    return (
      <header className="bg-white border-b border-gray-200">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col space-y-4 py-4">
          <div>
            {data.website ? (
              <Link to={data.website} target="_blank" rel="noopener noreferrer">
                {renderLogo()}
              </Link>
            ) : (
              renderLogo()
            )}
          </div>
          <nav className="flex items-center space-x-2 text-sm text-gray-600">
            <button
              onClick={() => navigate(-1)}
              className="hover:underline focus:outline-none"
            >
              {projectName}
            </button>
            <span>/</span>
            <span className="text-gray-800">{folderName}</span>
          </nav>
        </div>
      </header>
    );
  }

  // Render header with banner and overlapping logo
  return (
    <header className="bg-white">
      <div className="relative">
        {/* Banner Section */}
        <div className="h-72 w-full bg-gray-200 overflow-hidden">
          {banner ? (
            <img
              src={banner}
              alt="Banner"
              className="h-full w-full object-cover"
            />
          ) : (
            <div className="h-full w-full relative">
              <div className="absolute inset-0 bg-gradient-to-r from-[#8B5E3C] via-[#A67B5B] to-[#D2B48C] filter blur-xl opacity-40"></div>
            </div>
          )}
        </div>
        {/* Logo Overlapping the Banner */}
        <div className="absolute left-0 right-0 bottom-[-60px]">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {data.website ? (
              <Link to={data.website} target="_blank" rel="noopener noreferrer">
                {renderLogo()}
              </Link>
            ) : (
              renderLogo()
            )}
          </div>
        </div>
      </div>
      {/* (Removed the separate company name block as requested) */}
    </header>
  );
};

export default ProjectHeader;
