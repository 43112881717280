import React from "react";

const HeroSection = ({ data }) => {
  return (
    <div className="relative flex items-center justify-center py-24">
      <div className="absolute inset-0 pointer-events-none"></div>
      <div className="relative z-10 mx-auto max-w-3xl px-6 text-center">
        {data?.status && (
          <div className="mb-6 inline-block rounded-full border border-gray-300 px-4 py-1 text-sm font-medium text-gray-700 shadow-sm">
            {data?.status}
          </div>
        )}
        <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 drop-shadow">
          {data?.name}
        </h1>
        {data?.description && (
          <p className="mt-6 text-lg text-gray-600 max-w-2xl mx-auto">
            {data?.description}
          </p>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
