import React from "react";
import { Link } from "react-router-dom";
import WhatsAppButton from "../../../common/buttons/WhatsAppButton";
import hero from "../../../../assets/images/hero.png";
import { useTranslation } from "react-i18next";

const Hero = ({ isEarlyAccess }) => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="text-center place-self-center lg:col-span-12">
          <h1
            className="max-w-3xl mb-4 mt-10 mx-auto text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-gray-900"
            style={{ lineHeight: "1.1" }}
          >
            {t("hero.title.part1")}{" "}
            <span className="text-blue-700">{t("hero.title.part2")}</span>
          </h1>
          <p className="max-w-2xl mx-auto mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
            {t("hero.subtitle")}
          </p>

          {/* Button Container */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-3">
            <Link
              to="https://calendar.app.google/VRyag6a5opnk3dEw5"
              target="_blank"
              className="w-full sm:w-auto bg-white inline-flex items-center justify-center px-3 py-2 text-sm font-medium sm:px-5 sm:py-3 sm:text-base text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
            >
              {t("hero.bookDemo")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4 sm:w-5 sm:h-5 ml-2 -mr-1"
              >
                <path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
              </svg>
            </Link>

            {isEarlyAccess ? (
              <WhatsAppButton isHero />
            ) : (
              <Link
                to="/register"
                className="w-full sm:w-auto inline-flex items-center justify-center px-3 py-2 text-sm font-medium sm:px-5 sm:py-3 sm:text-base text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
              >
                {t("hero.getStarted")}
                <svg
                  className="w-4 h-4 sm:w-5 sm:h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            )}
          </div>

          <img
            className="w-full md:max-w-6xl mx-auto mt-10"
            src={hero}
            alt={t("hero.imageAlt")}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
