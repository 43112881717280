import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import FiltersContainer from "../../../components/common/filters/FiltersContainer";
import TableContainer from "../../../components/common/tables/TableContainer";
import Pagination from "../../../components/common/tables/Pagination";
import { PURCHASE_ORDERS_TABLE_COLUMNS } from "../../../utils/tableColumns";
import { useCompanyExpenses } from "../../../hooks/useExpenses/useExpenses";
import PurchaseOrder from "./PurchaseOrder";
import SlideOver from "../../../components/layout/SlideOver";
import NoAccessView from "../../../components/common/cards/NoAccessView";
import { AuthContext } from "../../../context/AuthProvider";

const PurchaseOrders = () => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [sideOveOpen, setSideOverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const navigate = useNavigate();

  const { data, isLoading, isError } = useCompanyExpenses(
    currentPage,
    perPage,
    debouncedSearch
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchInput);
    }, 500); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleAddExpense = () => {
    navigate("/purchase-orders/create");
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleRowClick = (data) => {
    setSideOverOpen((prevState) => !prevState);
    setSelectedRow(data || {});
  };

  const expenses = data?.expenses || [];
  const totalItems = data?.pagination?.total_items || 0;

  if (!userRoleActionEnabled) {
    return <NoAccessView />;
  }

  return (
    <div>
      <PageHeadings
        title="Purchase Orders"
        tags={[]}
        showCta={true}
        ctaLabel="Create"
        handleCtaAction={handleAddExpense}
      />
      <DefaultCard>
        <FiltersContainer value={searchInput} onChange={handleSearchChange} />
        <TableContainer
          hasCheckbox={false}
          columns={PURCHASE_ORDERS_TABLE_COLUMNS}
          rowType="purchase_orders"
          data={expenses}
          loading={isLoading}
          error={isError}
          handleRowClick={handleRowClick}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={totalItems}
          pageSize={perPage}
          onPageChange={handlePageChange}
        />
      </DefaultCard>
      <SlideOver open={sideOveOpen} setOpen={setSideOverOpen} title="Details">
        <PurchaseOrder
          data={selectedRow}
          closeSidebar={() => setSideOverOpen(false)}
        />
      </SlideOver>
    </div>
  );
};

export default PurchaseOrders;
