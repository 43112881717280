import React from "react";

const FileDropZone = ({
  fileData,
  previewUrl,
  fileInputRef,
  onFileDrop,
  onClickDropzone,
  onRemoveFile,
  error,
  onInputChange,
}) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files && e.dataTransfer.files[0];
    if (droppedFile) onFileDrop(droppedFile);
  };

  return (
    <div className="flex flex-col w-full">
      {/* Existing file preview */}
      {!fileData.file && fileData.file_url && (
        <div className="w-full max-w-md p-4 border rounded-md relative">
          {fileData.file_type === "image" ? (
            <img
              src={fileData.file_url}
              alt={fileData.name}
              className="max-w-full h-auto rounded shadow"
            />
          ) : (
            <div className="flex flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8 mb-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
              <p
                className="text-gray-700 truncate max-w-xs"
                title={fileData.name}
              >
                {fileData.name}
              </p>
            </div>
          )}
          <button
            type="button"
            onClick={onRemoveFile}
            className="absolute top-2 right-2 text-red-700 bg-white p-2 rounded-lg"
            title="Remove File"
          >
            Remove
          </button>
        </div>
      )}

      {/* Drag & drop zone */}
      {!fileData.file && !fileData.file_url && (
        <div
          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 mt-2"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={onClickDropzone}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path d="M13 13h3a3 3 0 0 0... (omitted)" />
            </svg>
            <p className="mb-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-500">
              {fileData.file_type === "pdf"
                ? "PDF (MAX. 10MB)"
                : "PNG, JPG, JPEG, GIF (MAX. 5MB)"}
            </p>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            name="file"
            accept={
              fileData.file_type === "pdf"
                ? "application/pdf"
                : "image/png, image/jpeg, image/jpg, image/gif"
            }
            onChange={onInputChange}
            className="hidden"
          />
        </div>
      )}

      {/* New file preview */}
      {fileData.file && (
        <div className="w-full max-w-md p-4 border rounded-md relative mt-4">
          {fileData.file_type === "image" && previewUrl && (
            <img
              src={previewUrl}
              alt="Uploaded"
              className="max-w-full h-auto rounded shadow"
            />
          )}
          {fileData.file_type === "pdf" && (
            <div className="flex flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-8 mb-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
              <p
                className="text-gray-700 truncate max-w-xs"
                title={fileData.file.name}
              >
                {fileData.file.name}
              </p>
            </div>
          )}
          <button
            type="button"
            onClick={onRemoveFile}
            className="absolute top-2 right-2 text-red-700 bg-white p-2 rounded-lg"
            title="Remove File"
          >
            Remove
          </button>
        </div>
      )}

      {error.file && (
        <div className="text-red-500 text-sm mt-1 w-full text-center">
          {error.file}
        </div>
      )}
    </div>
  );
};

export default FileDropZone;
