import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Pricing = () => {
  const { t } = useTranslation();

  // Define your plans with details pulled from translation keys
  const plans = [
    {
      id: "free",
      title: t("pricing.plans.free.title"),
      subtitle: t("pricing.plans.free.subtitle"),
      price: 0,
      details: [
        t("pricing.plans.free.projects"),
        t("pricing.plans.free.users"),
        t("pricing.plans.free.files"),
        t("pricing.plans.free.noCreditCard"),
      ],
      additional: t("pricing.plans.free.additional"),
      isRecommended: false,
    },
    {
      id: "basic",
      title: t("pricing.plans.basic.title"),
      subtitle: t("pricing.plans.basic.subtitle"),
      price: 0,
      details: [
        t("pricing.plans.basic.projects"),
        t("pricing.plans.basic.users"),
        t("pricing.plans.basic.files"),
        t("pricing.plans.basic.noCreditCard"),
      ],
      additional: t("pricing.plans.basic.additional"),
      isRecommended: true,
    },
    {
      id: "pro",
      title: t("pricing.plans.pro.title"),
      subtitle: t("pricing.plans.pro.subtitle"),
      price: 0,
      details: [
        t("pricing.plans.pro.projects"),
        t("pricing.plans.pro.users"),
        t("pricing.plans.pro.files"),
        t("pricing.plans.pro.noCreditCard"),
      ],
      additional: t("pricing.plans.pro.additional"),
      isRecommended: false,
    },
  ];

  return (
    <section className="bg-gray-50">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        {/* Header */}
        <div className="max-w-screen-md mx-auto text-center mb-8 lg:mb-12">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
            {t("pricing.title")}
          </h2>
          <p className="mb-5 font-light text-gray-500 sm:text-xl">
            {t("pricing.subtitle")}
          </p>
        </div>

        {/* Pricing Cards */}
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 lg:space-y-0">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`flex flex-col p-6 mx-auto max-w-md text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow-xl xl:p-8
                ${plan.isRecommended ? "ring-2 ring-blue-500" : ""}`}
            >
              {/* Badge for the recommended plan */}
              {plan.isRecommended && (
                <div className="absolute mt-[-1.8rem] ml-[-1.5rem] bg-blue-500 text-white text-xs px-2 py-1 rounded-sm uppercase tracking-wider">
                  {t("pricing.popularBadge")}
                </div>
              )}
              <h3 className="mb-4 text-2xl font-semibold">{plan.title}</h3>
              <p className="font-light text-gray-500 sm:text-lg">
                {plan.subtitle}
              </p>

              {/* Price */}
              <div className="flex justify-center items-baseline my-8">
                <span className="mr-2 text-5xl font-extrabold">
                  ${plan.price}
                </span>
              </div>

              {/* Feature List */}
              <ul className="mb-8 space-y-4 text-left">
                {plan.details.map((detail, index) => (
                  <li key={index} className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{detail}</span>
                  </li>
                ))}
              </ul>

              {/* Additional Info */}
              <p className="mb-4 text-sm text-gray-600">{plan.additional}</p>

              {/* CTA Button */}
              <Link
                to="/register"
                className="self-end mt-auto w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {t("pricing.button")}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
