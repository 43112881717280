import React, { useState, useContext, useRef } from "react";
import { AuthContext } from "../../../../context/AuthProvider";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import ErrorMessage from "../../../../components/common/empty/ErrorMessage";
import {
  usePhases,
  useCreatePhase,
  useUpdatePhase,
  useDeletePhase,
} from "../../../../hooks/useTasks/usePhases";

const Projects = () => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;

  // ----- React Query Hooks -----
  const {
    data: phasesData = [],
    isLoading: isLoadingPhases,
    isError: isErrorPhases,
    error: fetchPhasesError,
  } = usePhases(companyId);

  const createPhaseMutation = useCreatePhase();
  const updatePhaseMutation = useUpdatePhase();
  const deletePhaseMutation = useDeletePhase();

  // ----- Local UI State -----
  const [newPhase, setNewPhase] = useState({ name: "", description: "" });
  const [editPhase, setEditPhase] = useState(null);

  // For Delete Confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [phaseToDelete, setPhaseToDelete] = useState(null);

  // Modal reference to detect outside clicks
  const modalRef = useRef(null);

  // ----- Handlers -----
  const handleCreatePhase = () => {
    if (!newPhase.name.trim()) return;
    createPhaseMutation.mutate(
      { companyId, data: { ...newPhase } },
      {
        onSuccess: () => setNewPhase({ name: "", description: "" }),
      }
    );
  };

  const handleUpdatePhase = (phaseId, updatedData) => {
    updatePhaseMutation.mutate(
      { phaseId, data: updatedData },
      { onSuccess: () => setEditPhase(null) }
    );
  };

  const handleOpenDeleteModal = (phase) => {
    setPhaseToDelete(phase);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (phaseToDelete) {
      deletePhaseMutation.mutate(phaseToDelete.id, {
        onSuccess: () => {
          setShowDeleteModal(false);
          setPhaseToDelete(null);
        },
      });
    }
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setPhaseToDelete(null);
  };

  // Close modal if user clicks outside it
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleCloseModal();
    }
  };

  // Attach/detach mousedown listener for outside click
  React.useEffect(() => {
    if (showDeleteModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showDeleteModal]);

  // ----- Render Helpers -----
  if (isLoadingPhases) return <div>Loading Phases...</div>;

  if (isErrorPhases) {
    return (
      <ErrorMessage
        message={
          fetchPhasesError?.response?.data?.description ||
          "Failed to load phases."
        }
      />
    );
  }

  return (
    <div className="bg-white rounded-md border border-gray-200 w-full relative">
      {/* Heading */}
      <CardHeadings
        title="Phases Management"
        description="Create, update, and delete phases for your company projects."
      />
      <div className="px-4">
        {/* Create Phase Form */}
        {userRoleActionEnabled && (
          <div className="my-4">
            <h3 className="text-sm font-semibold mb-2">Create New Phase</h3>
            <div className="flex flex-col md:flex-row gap-2">
              <input
                type="text"
                value={newPhase.name}
                onChange={(e) =>
                  setNewPhase({ ...newPhase, name: e.target.value })
                }
                placeholder="Phase Name"
                className="p-2 border rounded w-full md:w-1/3 text-sm"
              />
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm"
                onClick={handleCreatePhase}
                disabled={createPhaseMutation.isLoading}
              >
                {createPhaseMutation.isLoading ? "Creating..." : "Create"}
              </button>
            </div>
            {createPhaseMutation.isError && (
              <p className="text-red-600 text-xs mt-1">
                {createPhaseMutation.error?.response?.data?.description ||
                  "Error creating phase."}
              </p>
            )}
          </div>
        )}
      </div>

      <hr />

      <div className="px-4">
        {/* Display Existing Phases */}
        <div className="mt-4">
          <h3 className="text-sm font-semibold mb-2">Existing Phases</h3>
          {phasesData.length === 0 ? (
            <p className="text-gray-500 text-sm mb-6">No phases created yet.</p>
          ) : (
            phasesData?.map((phase) => (
              <div
                key={phase.id}
                className="flex flex-row items-center justify-between p-2 border-b max-w-lg"
              >
                {editPhase?.id === phase.id ? (
                  <div className="flex flex-row gap-2 w-full">
                    <input
                      type="text"
                      value={editPhase.name}
                      onChange={(e) =>
                        setEditPhase({ ...editPhase, name: e.target.value })
                      }
                      className="p-1 border rounded w-full text-sm"
                    />
                    <button
                      onClick={() =>
                        handleUpdatePhase(phase.id, {
                          name: editPhase.name,
                        })
                      }
                      className="px-3 py-1 bg-green-600 text-white text-sm rounded hover:bg-green-700"
                      disabled={updatePhaseMutation.isLoading}
                    >
                      {updatePhaseMutation.isLoading ? "Saving..." : "Save"}
                    </button>
                    <button
                      onClick={() => setEditPhase(null)}
                      className="px-3 py-1 bg-gray-400 text-white text-sm rounded hover:bg-gray-500"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col">
                      <span className="font-semibold text-sm">
                        {phase.name}
                      </span>
                    </div>
                    {userRoleActionEnabled && (
                      <div className="flex gap-2 mt-2 md:mt-0">
                        {/* Gray "Edit" button */}
                        <button
                          onClick={() => setEditPhase(phase)}
                          className="px-3 py-1 bg-gray-500 text-white text-sm rounded hover:bg-gray-600"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleOpenDeleteModal(phase)}
                          className="px-3 py-1 bg-red-600 text-white text-sm rounded hover:bg-red-700"
                          disabled={deletePhaseMutation.isLoading}
                        >
                          {deletePhaseMutation.isLoading
                            ? "Deleting..."
                            : "Delete"}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))
          )}
        </div>

        {/* Error or Status for Update/Delete */}
        {updatePhaseMutation.isError && (
          <p className="text-red-600 text-xs mt-1">
            {updatePhaseMutation.error?.response?.data?.description ||
              "Error updating phase."}
          </p>
        )}
        {deletePhaseMutation.isError && (
          <p className="text-red-600 text-xs mt-1">
            {deletePhaseMutation.error?.response?.data?.description ||
              "Error deleting phase."}
          </p>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && phaseToDelete && (
        <>
          {/* Overlay */}
          <div
            id="modal-overlay"
            className="fixed inset-0 bg-black bg-opacity-30 z-40"
          />

          {/* Modal Container */}
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div
                ref={modalRef}
                className="bg-white rounded-lg shadow-xl p-6 w-80 relative"
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold">
                    Confirm Phase Deletion
                  </h2>
                  <button
                    onClick={handleCloseModal}
                    className="text-gray-500 hover:text-gray-700 font-bold text-xl"
                    aria-label="Close modal"
                  >
                    &times;
                  </button>
                </div>

                <p className="text-sm text-gray-700">
                  Are you sure you want to delete the phase{" "}
                  <span className="font-semibold">{phaseToDelete.name}</span>?
                  <br />
                  <br />
                  Any tasks currently associated with this phase will remain in
                  the system, but they will no longer be linked to a phase.
                </p>

                <div className="flex justify-end gap-2 mt-4">
                  <button
                    className="px-3 py-1 text-sm rounded bg-gray-400 text-white hover:bg-gray-500"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-3 py-1 text-sm rounded bg-red-600 text-white hover:bg-red-700"
                    onClick={handleConfirmDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Projects;
