import React, { useState } from "react";
import NoFilesMessage from "./NoFilesMessage";
import ImagesSection from "./ImagesSection";
import PDFSection from "./PDFSection";
import LinksSection from "./LinksSection";
import OtherFilesSection from "./OtherFilesSection";

const FilesContainer = ({ data = [], hasFolder }) => {
  // Filter files by type
  const images = data.filter((file) => file.file_type === "image");
  const pdfs = data.filter((file) => file.file_type === "pdf");
  const links = data.filter((file) => file.file_type === "link");
  const others = data.filter(
    (file) =>
      file.file_type !== "image" &&
      file.file_type !== "pdf" &&
      file.file_type !== "link"
  );

  const hasNoFiles = data.length === 0;

  // Build an array of available file types (only include if length > 0)
  const fileTypes = [];
  if (images.length > 0) fileTypes.push({ key: "images", label: "Images" });
  if (pdfs.length > 0) fileTypes.push({ key: "pdfs", label: "Attachments" });
  if (links.length > 0) fileTypes.push({ key: "links", label: "Links" });
  if (others.length > 0) fileTypes.push({ key: "others", label: "Others" });

  // Use the first available file type as the default selection
  const [selectedType, setSelectedType] = useState(
    fileTypes.length > 0 ? fileTypes[0].key : ""
  );

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  // Render only the section for the selected file type
  const renderSection = () => {
    switch (selectedType) {
      case "images":
        return <ImagesSection files={images} />;
      case "pdfs":
        return <PDFSection files={pdfs} />;
      case "links":
        return <LinksSection files={links} />;
      case "others":
        return <OtherFilesSection files={others} />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-8">
      {hasNoFiles && !hasFolder ? (
        <NoFilesMessage />
      ) : hasNoFiles ? (
        <div />
      ) : (
        <>
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Files</h3>
          {/* Only show selection UI if there is more than one file type */}
          {fileTypes.length > 1 && (
            <>
              {/* Mobile select dropdown */}
              <div className="sm:hidden mb-4">
                <label htmlFor="tabs" className="sr-only">
                  Select file type
                </label>
                <select
                  id="tabs"
                  value={selectedType}
                  onChange={(e) => handleTypeChange(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  {fileTypes.map((type) => (
                    <option key={type.key} value={type.key}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Desktop tabs */}
              <div className="hidden sm:flex mb-4">
                <ul className="flex text-sm font-medium text-center text-gray-500 rounded-lg shadow-sm">
                  {fileTypes.map((type, index) => (
                    <li key={type.key}>
                      <button
                        type="button"
                        onClick={() => handleTypeChange(type.key)}
                        className={`px-4 py-2 border ${
                          selectedType === type.key
                            ? "text-gray-900 bg-gray-100"
                            : "bg-white hover:text-gray-700 hover:bg-gray-50"
                        } ${
                          index === 0
                            ? "rounded-l-lg"
                            : index === fileTypes.length - 1
                            ? "rounded-r-lg"
                            : ""
                        } focus:outline-none`}
                      >
                        {type.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {/* Render the selected file section */}
          <div>{renderSection()}</div>
        </>
      )}
    </div>
  );
};

export default FilesContainer;
