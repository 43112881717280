import React, { useState, useEffect, useMemo } from "react";
import AsyncSelect from "react-select/async";
import { useProjects } from "../../../../hooks/useProjects/useProjects";
import { useProviders } from "../../../../hooks/useProviders/useProviders";
import { useTaxes } from "../../../../hooks/useTaxes/useTaxes";
import { InvoiceItems } from "../../../../components/common/inputs/InvoiceItems";

const EditPurchaseOrder = ({
  updateExpense,
  expenseData,
  handleEditToggle,
  onExpenseUpdate,
}) => {
  // Fetch taxes data.
  const { data: taxesData = [] } = useTaxes(false);
  const taxOptions = useMemo(
    () =>
      taxesData.map((tax) => ({
        value: tax.id,
        label: `${tax.name} (${tax.rate}%)`,
        tax_type: tax.tax_type,
        rate: parseFloat(tax.rate),
      })),
    [taxesData]
  );

  // Set up initial form state.
  const [formData, setFormData] = useState({
    description: expenseData?.description || "",
    amount: expenseData?.amount || 0,
    final_amount: expenseData?.final_amount || 0,
    due_date: expenseData?.due_date ? expenseData.due_date.split("T")[0] : "",
    status: expenseData?.status || "Pending",
    location: expenseData?.location || "",
    payment_type: expenseData?.payment_type || "Credit Card",
    items: expenseData?.items || {},
    project_id: expenseData?.project_id || "",
    provider_id: expenseData?.provider_id || "",
    tax_id: expenseData?.tax_id || "",
  });
  const [error, setError] = useState({});

  // Update form state if expenseData changes externally.
  useEffect(() => {
    setFormData({
      description: expenseData?.description || "",
      amount: expenseData?.amount || 0,
      final_amount: expenseData?.final_amount || 0,
      due_date: expenseData?.due_date ? expenseData.due_date.split("T")[0] : "",
      status: expenseData?.status || "Pending",
      location: expenseData?.location || "",
      payment_type: expenseData?.payment_type || "Credit Card",
      items: expenseData?.items || {},
      project_id: expenseData?.project_id || "",
      provider_id: expenseData?.provider_id || "",
      tax_id: expenseData?.tax_id || "",
    });
  }, [expenseData]);

  // Fetch projects and providers.
  const { data: projectsData } = useProjects(1, 10000);
  const { data: providersData } = useProviders(1, 10000);

  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];
  const providerOptions =
    providersData?.providers.map((provider) => ({
      value: provider.id,
      label: provider.company_name,
    })) || [];

  const calculateTotalAmount = (items) =>
    Object.values(items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );

  // Recalculate totals when items or tax selection changes.
  useEffect(() => {
    const total = calculateTotalAmount(formData.items);
    const selectedTax = taxOptions.find(
      (option) => option.value === formData.tax_id
    );
    let final_amount = total;
    if (selectedTax) {
      final_amount += total * (selectedTax.rate / 100);
    }
    setFormData((prevData) => ({
      ...prevData,
      amount: total,
      final_amount,
    }));
  }, [formData.items, formData.tax_id, taxOptions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? Math.max(0, parseFloat(value) || 0)
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };
      return {
        ...prevData,
        items: updatedItems,
        amount: calculateTotalAmount(updatedItems),
      };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: {
          name: "",
          description: "",
          quantity: 1,
          value: 0,
        },
      },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];
      return {
        ...prevData,
        items: updatedItems,
        amount: calculateTotalAmount(updatedItems),
      };
    });
  };

  const validateInputs = () => {
    const errors = {};
    if (!formData.description.trim())
      errors.description = "Description is required.";
    if (formData.amount <= 0)
      errors.amount = "Total amount must be greater than 0.";
    Object.keys(formData.items).forEach((itemId) => {
      const item = formData.items[itemId];
      if (!item.name.trim())
        errors[`itemName${itemId}`] = "Item name is required.";
      if (!item.quantity || isNaN(item.quantity) || item.quantity <= 0)
        errors[`itemQuantity${itemId}`] = "Quantity must be greater than 0.";
      if (!item.value || isNaN(item.value) || item.value <= 0)
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    if (!validateInputs()) return;

    try {
      const cleanedData = {
        ...formData,
        amount: calculateTotalAmount(formData.items),
      };

      const updatedExpense = await updateExpense.mutateAsync({
        expenseId: expenseData.id,
        data: cleanedData,
      });
      onExpenseUpdate(updatedExpense);
      handleEditToggle();
    } catch (error) {
      setError({
        submit: "Failed to update expense. Please try again.",
      });
    }
  };

  return (
    <div className="container mx-auto">
      <div>
        {error.submit && (
          <div className="text-red-500 p-4 mb-4 bg-red-50 rounded">
            {error.submit}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6 text-sm">
          {/* Expense Details */}
          <div className="max-w-2xl">
            <p className="mb-2 font-bold text-gray-700">Expense Details</p>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {/* Description */}
              <div>
                <label htmlFor="description" className="block text-gray-700">
                  Description
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Description of the expense"
                  value={formData.description}
                  onChange={handleChange}
                  className={`mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                    error.description ? "border-red-500" : ""
                  }`}
                />
                {error.description && (
                  <p className="mt-1 text-xs text-red-600">
                    {error.description}
                  </p>
                )}
              </div>
              {/* Status */}
              <div>
                <label htmlFor="status" className="block text-gray-700">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                >
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="In Process">In Process</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              {/* Due Date */}
              <div>
                <label htmlFor="due_date" className="block text-gray-700">
                  Due Date
                </label>
                <input
                  type="date"
                  id="due_date"
                  name="due_date"
                  value={formData.due_date}
                  onChange={handleChange}
                  className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                />
              </div>
              {/* Payment Type */}
              <div>
                <label htmlFor="payment_type" className="block text-gray-700">
                  Payment Type
                </label>
                <select
                  id="payment_type"
                  name="payment_type"
                  value={formData.payment_type}
                  onChange={handleChange}
                  className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                >
                  <option value="Credit Card">Credit Card</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cash">Cash</option>
                  <option value="Check">Check</option>
                  <option value="Wire Transfer">Wire Transfer</option>
                  <option value="Mobile Payment">Mobile Payment</option>
                  <option value="Digital Wallet">Digital Wallet</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {/* Location */}
              <div>
                <label htmlFor="location" className="block text-gray-700">
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Expense location"
                  value={formData.location}
                  onChange={handleChange}
                  className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Project & Provider */}
          <div className="max-w-2xl">
            <p className="mb-2 font-bold text-gray-700">
              Project &amp; Provider
            </p>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {/* Project */}
              <div>
                <label className="block text-gray-700">
                  Project (optional)
                </label>
                <AsyncSelect
                  className="mt-1 text-sm"
                  cacheOptions
                  defaultOptions={projectOptions}
                  loadOptions={(inputValue, callback) =>
                    callback(
                      projectOptions.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    )
                  }
                  onChange={(selected) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      project_id: selected ? selected.value : "",
                    }))
                  }
                  placeholder="Select a project"
                  value={
                    projectOptions.find(
                      (option) => option.value === formData.project_id
                    ) || null
                  }
                />
              </div>
              {/* Provider */}
              <div>
                <label className="block text-gray-700">
                  Provider (optional)
                </label>
                <AsyncSelect
                  className="mt-1 text-sm"
                  cacheOptions
                  defaultOptions={providerOptions}
                  loadOptions={(inputValue, callback) =>
                    callback(
                      providerOptions.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    )
                  }
                  onChange={(selected) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      provider_id: selected ? selected.value : "",
                    }))
                  }
                  placeholder="Select a provider"
                  value={
                    providerOptions.find(
                      (option) => option.value === formData.provider_id
                    ) || null
                  }
                />
              </div>
            </div>
          </div>

          {/* Tax Selection */}
          <div className="max-w-xs">
            <p className="mb-2 font-bold text-gray-700">Tax (optional)</p>
            <AsyncSelect
              className="text-sm"
              cacheOptions
              defaultOptions={taxOptions}
              isClearable
              placeholder="Select a tax option"
              value={
                taxOptions.find((option) => option.value === formData.tax_id) ||
                null
              }
              onChange={(selected) =>
                setFormData((prevData) => ({
                  ...prevData,
                  tax_id: selected ? selected.value : "",
                }))
              }
            />
          </div>

          <div>
            <InvoiceItems
              items={formData.items}
              errors={error}
              onItemChange={handleItemChange}
              onAddItem={addItem}
              onRemoveItem={removeItem}
            />
          </div>

          {/* Totals */}
          <div className="max-w-xs">
            <p className="mb-2 font-bold text-gray-700">Totals</p>
            <div className="grid grid-cols-1 gap-4">
              {/* Subtotal */}
              <div>
                <label htmlFor="amount" className="block text-gray-700">
                  Subtotal
                </label>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  value={
                    formData.amount
                      ? new Intl.NumberFormat().format(formData.amount)
                      : 0
                  }
                  readOnly
                  disabled
                  className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
                />
              </div>
              {/* Final Amount */}
              <div>
                <label htmlFor="final_amount" className="block text-gray-700">
                  Total (Tax Included)
                </label>
                <input
                  type="text"
                  id="final_amount"
                  name="final_amount"
                  value={
                    formData.final_amount
                      ? new Intl.NumberFormat().format(formData.final_amount)
                      : 0
                  }
                  readOnly
                  disabled
                  className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
                />
              </div>
            </div>
          </div>

          {/* Form Actions */}
          <div className="mt-4 flex space-x-4">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 disabled:opacity-50"
              disabled={updateExpense.isLoading}
            >
              {updateExpense.isLoading ? "Saving..." : "Save"}
            </button>
            <button
              type="button"
              onClick={handleEditToggle}
              className="bg-gray-500 text-white px-4 py-2 rounded focus:ring-2 focus:ring-gray-500 focus:ring-offset-1"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPurchaseOrder;
