import React, { useState } from "react";

const ApiKeyDisplay = ({ apiKeyData }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    if (apiKeyData?.api_key) {
      navigator.clipboard.writeText(apiKeyData.api_key);
      setCopySuccess(true);
      // Reset the copy state after 2 seconds
      setTimeout(() => setCopySuccess(false), 2000);
    }
  };

  return (
    <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
      <div className="flex-1">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Your API Key
        </label>
        <div className="flex rounded-md shadow-sm">
          <input
            type="text"
            readOnly
            value={apiKeyData.api_key}
            className="flex-1 block w-full rounded-l-md border border-gray-300 bg-gray-50 p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <button
            onClick={handleCopy}
            className="inline-flex items-center px-4 py-2 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {copySuccess ? "Copied!" : "Copy"}
          </button>
        </div>
        {copySuccess && (
          <p className="mt-2 text-xs text-green-600">
            API Key copied to clipboard!
          </p>
        )}
      </div>
    </div>
  );
};

export default ApiKeyDisplay;
