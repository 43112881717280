import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  useDeleteClientFile,
  useUpdateClientFile,
  useFoldersByProject,
} from "../../../hooks/useFiles/useFiles";
import Badge from "../../../components/common/badges/Badge";
import FilePreview from "./details/FilePreview";
import FileDropZone from "./details/FileDropZone";
import SidebarDropdown from "../../../components/common/buttons/SidebarDropdown";

const FileDetails = ({ data, closeSidebar }) => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [fileData, setFileData] = useState(data);
  const [initialFileData, setInitialFileData] = useState(data);
  const [error, setError] = useState({});
  const [previewUrl, setPreviewUrl] = useState("");

  // Fetch available folders based on the file's project
  const { data: folders, isLoading: isFoldersLoading } = useFoldersByProject({
    projectId: data?.project_id,
  });

  // Map folders to react-select options (convert id to string)
  let folderOptions = folders
    ? folders.map((folder) => ({
        label: folder.name,
        value: folder.id.toString(),
      }))
    : [];

  // Mutations
  const deleteFile = useDeleteClientFile();
  const updateFile = useUpdateClientFile();

  // File validations and constants
  const MAX_SIZE_PDF = 10 * 1024 * 1024; // 10MB
  const MAX_SIZE_IMAGE = 5 * 1024 * 1024; // 5MB
  const ALLOWED_IMAGE_TYPES = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
  ];
  const ALLOWED_PDF_TYPES = ["application/pdf"];

  // On mount/data change, initialize state and preview
  useEffect(() => {
    if (data) {
      setFileData(data);
      setInitialFileData(data);
      if (data.file_type === "image" && data.file_url) {
        setPreviewUrl(data.file_url);
      } else {
        setPreviewUrl("");
      }
    }
  }, [data]);

  // Cleanup preview URL if created via URL.createObjectURL
  useEffect(() => {
    return () => {
      if (previewUrl && previewUrl.startsWith("blob:")) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const validateFile = (file) => {
    if (!file) {
      setError((prev) => ({ ...prev, file: "No file selected." }));
      return false;
    }
    if (fileData.file_type === "pdf") {
      if (!ALLOWED_PDF_TYPES.includes(file.type)) {
        setError((prev) => ({
          ...prev,
          file: "Please upload a valid PDF file.",
        }));
        return false;
      }
      if (file.size > MAX_SIZE_PDF) {
        setError((prev) => ({ ...prev, file: "PDF file size exceeds 10MB." }));
        return false;
      }
    } else if (fileData.file_type === "image") {
      if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
        setError((prev) => ({
          ...prev,
          file: "Please upload a PNG, JPG, JPEG, or GIF image.",
        }));
        return false;
      }
      if (file.size > MAX_SIZE_IMAGE) {
        setError((prev) => ({
          ...prev,
          file: "Image file size exceeds 5MB.",
        }));
        return false;
      }
    } else {
      setError((prev) => ({
        ...prev,
        file_type: "Unsupported file type selected.",
      }));
      return false;
    }
    setError((prev) => ({ ...prev, file: "" }));
    return true;
  };

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const validateInputs = () => {
    const errors = {};
    if (!fileData.name.trim()) errors.name = "Name is required.";

    if (["pdf", "image"].includes(fileData.file_type)) {
      if (!fileData.file && !fileData.file_url) {
        errors.file = "Please upload a PDF or Image file.";
      }
    } else if (["link", "embed", "canva"].includes(fileData.file_type)) {
      if (!fileData.file_url.trim()) {
        errors.file_url = "File URL is required.";
      } else if (!validateUrl(fileData.file_url)) {
        errors.file_url = "Invalid URL format.";
      }
    } else {
      errors.file_type = "Invalid file type selected.";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFileDrop = (file) => {
    if (validateFile(file)) {
      const newPreviewUrl =
        fileData.file_type === "image" ? URL.createObjectURL(file) : "";
      setFileData((prev) => ({
        ...prev,
        file,
        file_url: "",
      }));
      setPreviewUrl(newPreviewUrl);
    } else {
      setFileData((prev) => ({ ...prev, file: null }));
      if (fileInputRef.current) fileInputRef.current.value = "";
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (name === "file") {
      const selectedFile = files[0];
      if (selectedFile) {
        handleFileDrop(selectedFile);
      }
      return;
    }

    if (name === "file_type") {
      setFileData((prev) => ({
        ...prev,
        file_type: value,
        file: null,
        file_url: "",
      }));
      setError((prev) => ({ ...prev, file: "", file_url: "" }));
      setPreviewUrl("");
      if (fileInputRef.current) fileInputRef.current.value = "";
      return;
    }

    setFileData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    setError({});
    if (!isEditing && fileData.file_type === "image" && fileData.file_url) {
      setPreviewUrl(fileData.file_url);
    } else {
      setPreviewUrl("");
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this file?")) return;
    try {
      await deleteFile.mutateAsync({ fileId: fileData.id });
      closeSidebar();
      navigate(`/projects/${fileData.project_id}`);
    } catch {
      setError((prev) => ({
        ...prev,
        submit: "Failed to delete file. Please try again.",
      }));
    }
  };

  const handleCancelEdit = () => {
    setFileData(initialFileData);
    setIsEditing(false);
    setError({});
    setPreviewUrl(initialFileData.file_url || "");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveFile = () => {
    setFileData((prev) => ({
      ...prev,
      file: null,
      file_url: "",
    }));
    setPreviewUrl("");
    if (fileInputRef.current) fileInputRef.current.value = "";
    setError((prev) => ({ ...prev, file: "" }));
  };

  const handleSave = async () => {
    if (!validateInputs()) return;

    let submissionData;
    let headers = {};

    if (["pdf", "image"].includes(fileData.file_type) && fileData.file) {
      submissionData = new FormData();
      submissionData.append("name", fileData.name);
      submissionData.append("file_type", fileData.file_type);
      submissionData.append("file", fileData.file);
      submissionData.append("is_public", fileData.is_public);
      submissionData.append("project_id", fileData.project_id);
    } else {
      submissionData = {
        name: fileData.name,
        file_type: fileData.file_type,
        is_public: fileData.is_public,
        project_id: fileData.project_id,
      };
      if (["link", "embed", "canva"].includes(fileData.file_type)) {
        submissionData.file_url = fileData.file_url;
      }
      headers = { "Content-Type": "application/json" };
    }
    // Include the folder assignment in the payload
    if (submissionData instanceof FormData) {
      submissionData.append("folder_id", fileData.folder_id || "");
    } else {
      submissionData.folder_id = fileData.folder_id || "";
    }

    try {
      await updateFile.mutateAsync(
        {
          fileId: fileData.id,
          data: submissionData,
          headers,
        },
        {
          onSuccess: (updatedFile) => {
            setFileData(updatedFile);
            setInitialFileData(updatedFile);
            setIsEditing(false);
          },
          onError: () => {
            setError((prev) => ({
              ...prev,
              submit: "Failed to update file. Please try again.",
            }));
          },
        }
      );
    } catch {
      setError((prev) => ({
        ...prev,
        submit: "Failed to update file. Please try again.",
      }));
    }
  };

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div
          className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg"
          role="alert"
        >
          {error.submit}
        </div>
      )}

      {isEditing ? (
        <div className="space-y-4">
          {/* File Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              File Name
            </label>
            <input
              type="text"
              name="name"
              value={fileData.name}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="File Name"
            />
            {error.name && (
              <div className="text-red-500 text-sm mt-1">{error.name}</div>
            )}
          </div>

          {/* File Type */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              File Type
            </label>
            <select
              name="file_type"
              value={fileData.file_type}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <option value="link">Link</option>
              <option value="embed">Embed</option>
              <option value="pdf">PDF</option>
              <option value="image">Image</option>
              <option value="canva">Canva</option>
            </select>
            {error.file_type && (
              <div className="text-red-500 text-sm mt-1">{error.file_type}</div>
            )}
          </div>

          {/* Folder Assignment using react-select */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Folder (Optional)
            </label>
            <Select
              options={folderOptions}
              value={
                fileData?.folder_id
                  ? folderOptions.find(
                      (option) => option.value === fileData.folder_id
                    )
                  : null
              }
              onChange={(selectedOption) =>
                setFileData((prev) => ({
                  ...prev,
                  folder_id: selectedOption ? selectedOption.value : "",
                }))
              }
              isClearable
              placeholder="Select a folder (Optional)"
              className="max-w-md"
            />
          </div>

          {/* File Upload / URL Input */}
          {["pdf", "image"].includes(fileData.file_type) ? (
            <FileDropZone
              fileData={fileData}
              previewUrl={previewUrl}
              fileInputRef={fileInputRef}
              onFileDrop={handleFileDrop}
              onClickDropzone={() =>
                fileInputRef.current && fileInputRef.current.click()
              }
              onRemoveFile={handleRemoveFile}
              error={error}
              onInputChange={handleInputChange}
            />
          ) : (
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">
                File URL
              </label>
              <input
                type="url"
                name="file_url"
                value={fileData.file_url}
                onChange={handleInputChange}
                className="border p-2 w-full rounded"
                placeholder={
                  fileData.file_type === "link"
                    ? "https://example.com/link"
                    : fileData.file_type === "embed"
                    ? "https://example.com/embed"
                    : "https://example.com"
                }
              />
              {error.file_url && (
                <div className="text-red-500 text-sm mt-1">
                  {error.file_url}
                </div>
              )}
            </div>
          )}

          {/* Public Toggle */}
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              name="is_public"
              checked={fileData.is_public}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label className="text-sm text-gray-700">Make Public</label>
          </div>

          {/* Action Buttons */}
          <div className="mt-4 flex space-x-4">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={updateFile.isLoading}
            >
              {updateFile.isLoading ? "Saving..." : "Save"}
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 text-white px-4 py-2 rounded"
              disabled={updateFile.isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-2">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h5 className="text-xl font-bold leading-none text-gray-900">
                {fileData.name}
              </h5>
              <Badge
                status={fileData.is_public ? "Public" : "Private"}
                color={fileData.is_public ? "green" : "gray"}
              />
              {/* Show assigned folder if available */}
              {fileData.folder_id && (
                <div className="mt-2">
                  <span className="text-gray-500">Folder:</span>
                  <span className="ml-2 font-semibold">
                    {folderOptions.find(
                      (option) => option.value === fileData.folder_id.toString()
                    )?.label || fileData.folder_id}
                  </span>
                </div>
              )}
            </div>
            <SidebarDropdown
              onEdit={handleEditToggle}
              onDelete={handleDelete}
            />
          </div>

          {!!fileData?.file_url && (
            <p>
              <span className="block text-gray-500">File URL:</span>
              <a
                href={fileData.file_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline break-words"
              >
                {fileData.file_url}
              </a>
            </p>
          )}

          <FilePreview fileData={fileData} />
        </div>
      )}
    </div>
  );
};

export default FileDetails;
