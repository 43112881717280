import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";
import "react-phone-input-2/lib/style.css";

import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import { AuthContext } from "../../../context/AuthProvider";

// Hook imports
import { useCreateExpense } from "../../../hooks/useExpenses/useExpenses";
import { useProjects } from "../../../hooks/useProjects/useProjects";
import { useProviders } from "../../../hooks/useProviders/useProviders";
import { useTaxes } from "../../../hooks/useTaxes/useTaxes";
import { InvoiceItems } from "../../../components/common/inputs/InvoiceItems";

const CreatePurchaseOrder = () => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;

  const userLocale = navigator.language || "es-CO";
  const userCurrency = "COP";

  const location = useLocation();
  const paramProjectId = new URLSearchParams(location.search).get("projectId");
  const navigate = useNavigate();

  // Main form state
  const [formData, setFormData] = useState({
    description: "",
    amount: 0,
    final_amount: 0,
    due_date: "",
    status: "Pending",
    location: "",
    payment_type: "Credit Card",
    items: {},
    project_id: paramProjectId || "",
    provider_id: "",
    tax_id: "",
  });

  const [error, setError] = useState({});
  const [alertMessage, setAlertMessage] = useState(false);

  // Hooks
  const createExpense = useCreateExpense();
  const { data: projectsData } = useProjects(1, 10000);
  const { data: providersData } = useProviders(1, 10000);
  const { data: taxesData = [] } = useTaxes(false);

  // Options
  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];

  const providerOptions =
    providersData?.providers.map((provider) => ({
      value: provider.id,
      label: provider.company_name,
    })) || [];

  const taxOptions = useMemo(
    () =>
      taxesData.map((tax) => ({
        value: tax.id,
        label: `${tax.name} (${tax.rate}%)`,
        tax_type: tax.tax_type,
        rate: parseFloat(tax.rate),
      })),
    [taxesData]
  );

  // Redirect if user doesn't have the right permissions
  useEffect(() => {
    if (!userRoleActionEnabled) {
      navigate("/purchase-orders");
    }
  }, [userRoleActionEnabled, navigate]);

  // --- Event Handlers ---
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value"
              ? parseFloat(value)
              : field === "quantity"
              ? Math.max(1, parseInt(value, 10) || 1)
              : value,
        },
      };
      return { ...prevData, items: updatedItems };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: {
        ...prevData.items,
        [newItemId]: { name: "", description: "", quantity: 1, value: 0 },
      },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];
      return { ...prevData, items: updatedItems };
    });
  };

  // --- Validation ---
  const validateInputs = () => {
    const errors = {};
    if (!formData.description.trim()) {
      errors.description = "Description is required.";
    }
    if (formData.amount <= 0) {
      errors.amount = "Total amount must be greater than 0.";
    }
    if (Object.keys(formData.items).length === 0) {
      setAlertMessage(true);
      return false;
    }

    Object.entries(formData.items).forEach(([itemId, item]) => {
      if (!item.name.trim()) {
        errors[`itemName${itemId}`] = "Item name is required.";
      }
      if (!item.quantity || isNaN(item.quantity) || item.quantity <= 0) {
        errors[`itemQuantity${itemId}`] = "Quantity must be greater than 0.";
      }
      if (!item.value || isNaN(item.value) || item.value <= 0) {
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
      }
    });

    setError(errors);

    if (Object.keys(errors).length > 0) {
      const quantityOrValueError = Object.keys(errors).some(
        (key) => key.includes("itemQuantity") || key.includes("itemValue")
      );
      if (quantityOrValueError) {
        setAlertMessage(true);
      }
      return false;
    }

    setAlertMessage(false);
    return true;
  };

  // --- Effects ---
  useEffect(() => {
    const total = Object.values(formData.items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );

    let finalAmount = total;
    const selectedTax = taxOptions.find(
      (option) => option.value === formData.tax_id
    );
    if (selectedTax) {
      finalAmount += total * (selectedTax.rate / 100);
    }

    setFormData((prev) => ({
      ...prev,
      amount: total,
      final_amount: finalAmount,
    }));
  }, [formData.items, formData.tax_id, taxOptions]);

  // --- Submit ---
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});

    if (!validateInputs()) return;

    // Recalculate before sending
    const recalculatedTotal = Object.values(formData.items).reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.value) || 0) * (parseInt(item.quantity, 10) || 1),
      0
    );
    const updatedData = {
      ...formData,
      amount: recalculatedTotal,
    };

    try {
      await createExpense.mutateAsync(updatedData);
      const redirectUrl = new URLSearchParams(location.search).get("redirect")
        ? `${new URLSearchParams(location.search).get("redirect")}?view=finance`
        : `/purchase-orders`;
      navigate(redirectUrl);
    } catch (error) {
      setError({ submit: "Failed to create expense. Please try again." });
    }
  };

  return (
    <div className="container mx-auto">
      <PageHeadings title="Create Purchase Order" tags={[]} showCta={false} />

      <DefaultCard>
        <div className="bg-white p-4 rounded-md shadow-sm">
          {/* Alert for item quantity/value errors */}
          {alertMessage && (
            <div className="mb-4 rounded-md bg-yellow-50 p-4 text-sm text-yellow-800">
              One or more items have invalid quantities or values. Please
              correct them and try again.
            </div>
          )}

          {/* Submit error alert */}
          {error.submit && (
            <div className="mb-4 rounded-md bg-red-50 p-4 text-red-600">
              {error.submit}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6 text-sm">
            {/* EXPENSE DETAILS */}
            <div className="max-w-2xl">
              <p className="mb-2 font-bold text-gray-700">Expense Details</p>
              {/* 2 columns if multiple fields in the same section */}
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {/* Description */}
                <div>
                  <label htmlFor="description" className="block text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description of the expense"
                    value={formData.description}
                    onChange={handleChange}
                    className={`mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500 ${
                      error.description ? "border-red-500" : ""
                    }`}
                  />
                  {error.description && (
                    <p className="mt-1 text-xs text-red-600">
                      {error.description}
                    </p>
                  )}
                </div>

                {/* Status */}
                <div>
                  <label htmlFor="status" className="block text-gray-700">
                    Status
                  </label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="In Process">In Process</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>

                {/* Payment Due Date */}
                <div>
                  <label htmlFor="due_date" className="block text-gray-700">
                    Payment Due Date (optional)
                  </label>
                  <input
                    type="date"
                    id="due_date"
                    name="due_date"
                    placeholder="Select due date for payment"
                    value={formData.due_date}
                    onChange={handleChange}
                    className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>

                {/* Payment Type */}
                <div>
                  <label htmlFor="payment_type" className="block text-gray-700">
                    Payment Type
                  </label>
                  <select
                    id="payment_type"
                    name="payment_type"
                    value={formData.payment_type}
                    onChange={handleChange}
                    className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  >
                    <option value="Credit Card">Credit Card</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="Cash">Cash</option>
                    <option value="Check">Check</option>
                    <option value="Wire Transfer">Wire Transfer</option>
                    <option value="Mobile Payment">Mobile Payment</option>
                    <option value="Digital Wallet">
                      Digital Wallet (e.g., PayPal)
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                {/* Location */}
                <div>
                  <label htmlFor="location" className="block text-gray-700">
                    Location (optional)
                  </label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Expense location"
                    value={formData.location}
                    onChange={handleChange}
                    className="mt-1 w-full rounded border border-gray-300 px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>

            {/* PROJECT & PROVIDER */}
            <div className="max-w-2xl">
              <p className="mb-2 font-bold text-gray-700">
                Project &amp; Provider
              </p>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {/* Project (only if not using paramProjectId) */}
                {!paramProjectId && (
                  <div>
                    <label className="block text-gray-700">
                      Project (optional)
                    </label>
                    <AsyncSelect
                      className="mt-1 text-sm"
                      cacheOptions
                      defaultOptions={projectOptions}
                      isClearable
                      placeholder="Select a project"
                      onChange={(selected) =>
                        setFormData((prev) => ({
                          ...prev,
                          project_id: selected ? selected.value : "",
                        }))
                      }
                      loadOptions={(inputValue, callback) => {
                        callback(
                          projectOptions.filter((option) =>
                            option.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        );
                      }}
                    />
                  </div>
                )}

                {/* Provider */}
                <div>
                  <label className="block text-gray-700">
                    Provider (optional)
                  </label>
                  <AsyncSelect
                    className="mt-1 text-sm"
                    cacheOptions
                    defaultOptions={providerOptions}
                    isClearable
                    placeholder="Select a provider"
                    onChange={(selected) =>
                      setFormData((prev) => ({
                        ...prev,
                        provider_id: selected ? selected.value : "",
                      }))
                    }
                    loadOptions={(inputValue, callback) => {
                      callback(
                        providerOptions.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            {/* ITEMS */}
            <div>
              <InvoiceItems
                items={formData.items}
                errors={error}
                onItemChange={handleItemChange}
                onAddItem={addItem}
                onRemoveItem={removeItem}
              />
            </div>

            {/* TAX */}
            <div className="max-w-xs">
              <p className="mb-2 font-bold text-gray-700">Tax (optional)</p>
              <AsyncSelect
                className="text-sm"
                cacheOptions
                defaultOptions={taxOptions}
                isClearable
                placeholder="Select a tax option"
                value={
                  taxOptions.find(
                    (option) => option.value === formData.tax_id
                  ) || null
                }
                onChange={(selected) =>
                  setFormData((prev) => ({
                    ...prev,
                    tax_id: selected ? selected.value : "",
                  }))
                }
              />
            </div>

            {/* TOTALS */}
            <div className="max-w-xs">
              <p className="mb-2 font-bold text-gray-700">Totals</p>
              <div className="grid grid-cols-1 gap-4">
                {/* Subtotal */}
                <div>
                  <label htmlFor="amount" className="block text-gray-700">
                    Subtotal
                  </label>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    value={
                      formData.amount
                        ? new Intl.NumberFormat(userLocale, {
                            currency: userCurrency,
                            maximumFractionDigits: 0,
                          }).format(formData.amount)
                        : 0
                    }
                    readOnly
                    disabled
                    className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
                  />
                  {error.amount && (
                    <p className="mt-1 text-xs text-red-600">{error.amount}</p>
                  )}
                </div>

                {/* Final Amount */}
                <div>
                  <label htmlFor="final_amount" className="block text-gray-700">
                    Total (Tax Included)
                  </label>
                  <input
                    type="text"
                    id="final_amount"
                    name="final_amount"
                    value={
                      formData.final_amount
                        ? new Intl.NumberFormat(userLocale, {
                            currency: userCurrency,
                            maximumFractionDigits: 0,
                          }).format(formData.final_amount)
                        : 0
                    }
                    readOnly
                    disabled
                    className="mt-1 w-full cursor-not-allowed rounded border border-gray-300 bg-gray-100 px-2 py-1 text-gray-600"
                  />
                </div>
              </div>
            </div>

            {/* SUBMIT */}
            <button
              type="submit"
              className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 disabled:opacity-50"
              disabled={createExpense.isLoading}
            >
              {createExpense.isLoading ? "Adding..." : "Add Expense"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreatePurchaseOrder;
