import React, { useState, useEffect, useCallback, useContext } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import ProductFiltersContainer from "../../../components/common/filters/ProductFiltersContainer";
import ProductCard from "../../../components/common/cards/ProductCard";
import { useNavigate } from "react-router-dom";
import {
  useProducts,
  useUpdateProduct,
} from "../../../hooks/useProducts/products";
import { AuthContext } from "../../../context/AuthProvider";
import LoadingPage from "../../../components/common/empty/LoadingPage";
import ErrorMessage from "../../../components/common/empty/ErrorMessage";
import EmptyPage from "../../../components/common/empty/EmptyPage";

const ProductLibrary = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  // States for filtering and search.
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");

  // Pagination states.
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10; // Adjust page size as needed.

  // Fetch products using the updated hook.
  const { data, isLoading, isError, refetch } = useProducts(
    currentPage,
    limit,
    { filters, search }
  );
  const updateProductMutation = useUpdateProduct();

  // Reset products and page when filters or search change.
  useEffect(() => {
    setProducts([]);
    setCurrentPage(1);
  }, [filters, search]);

  // Update products list when new data arrives.
  useEffect(() => {
    if (data) {
      setProducts((prevProducts) => {
        const productIds = new Set(prevProducts.map((product) => product.id));
        const newProducts = data.products.filter(
          (product) => !productIds.has(product.id)
        );
        return currentPage === 1
          ? data.products
          : [...prevProducts, ...newProducts];
      });
      // Determine if there are more products available.
      setHasMore(data.pagination.total_items > currentPage * limit);
    }
  }, [data, currentPage, limit]);

  // Handler for toggling favorite status.
  const handleToggleFavorite = (product) => {
    const updatedValue = !product.is_favorite;
    updateProductMutation.mutate(
      {
        productId: product.id,
        data: { is_favorite: updatedValue },
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  // Handler for toggling pinned status.
  const handleTogglePinned = (product) => {
    const updatedValue = !product.is_pinned;
    updateProductMutation.mutate(
      {
        productId: product.id,
        data: { is_pinned: updatedValue },
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  // Update filters state.
  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  // Update search state.
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Infinite scrolling logic.
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!isLoading && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [isLoading, hasMore]);

  useEffect(() => {
    if (hasMore) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, hasMore]);

  // Determine empty state messaging.
  const emptyTitle =
    search || Object.keys(filters).length > 0
      ? "No Products Found"
      : "No Products Yet";
  const emptyDescription =
    search || Object.keys(filters).length > 0
      ? "We couldn’t find any products matching your filters."
      : "It looks like you haven’t added any products yet.";

  return (
    <div>
      <PageHeadings
        title="Product Library"
        tags={[]}
        showCta={true}
        ctaLabel="Create"
        handleCtaAction={() => navigate("/product-library/create")}
      />
      <section className="bg-gray-50 antialiased">
        <div className="mx-auto max-w-screen-xl">
          <ProductFiltersContainer
            searchValue={search}
            onSearchChange={handleSearchChange}
            onFiltersChange={handleFiltersChange}
          />
          {isLoading && currentPage === 1 ? (
            <LoadingPage />
          ) : isError ? (
            <ErrorMessage />
          ) : products.length === 0 ? (
            <EmptyPage title={emptyTitle} description={emptyDescription} />
          ) : (
            <>
              <div className="mb-4 grid gap-4 sm:grid-cols-2 md:mb-8 lg:grid-cols-3">
                {products.map((product) => (
                  <ProductCard
                    key={product.id}
                    img={product.img}
                    hasTags={true}
                    showHide={true}
                    showFavorite={true}
                    hasCta={false}
                    tags={product.tags}
                    handleToggleFavorite={() => handleToggleFavorite(product)}
                    handleTogglePinned={() => handleTogglePinned(product)}
                    title={product.name}
                    description={product.description}
                    partner={product.partner}
                    price={product.price}
                    ctaLabel="View Details"
                    handleCta={() => navigate(`/products/${product.id}`)}
                    isFavorite={product.is_favorite}
                    isPinned={product.is_pinned}
                  />
                ))}
              </div>
              {isLoading && currentPage > 1 && <div>Loading more...</div>}
              {!hasMore && !isLoading && currentPage > 1 && (
                <div className="text-center py-4">
                  No more products to load.
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default ProductLibrary;
