import React, { useState, useEffect, useMemo, useContext } from "react";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import AsyncSelect from "react-select/async";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useUpdateProject } from "../../../../hooks/useProjects/useProjects";
import { useClients } from "../../../../hooks/useClients/useClients";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthProvider";
import { generateEncryptedLink } from "../../../../utils/cryptoUtils";
import { QRCodeCanvas } from "qrcode.react";
import BannerDropZone from "../../../../components/common/inputs/BannerDropZone";

const ProjectSettings = ({ data }) => {
  const { user } = useContext(AuthContext);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyQRSuccess, setCopyQRSuccess] = useState(false);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;

  const [formData, setFormData] = useState({
    name: data?.name || "",
    description: data?.description || "",
    status: data?.status || "Planned",
    address: data?.address || "",
    country: data?.country || "",
    city: data?.city || "",
    portal_enabled: data?.portal_enabled || false,
    banner_url: data?.banner_url || null,
    client_id:
      data?.clients && data.clients.length > 0 ? data.clients[0].id : "",
  });

  const [sharableLink] = useState(
    generateEncryptedLink(user?.company_id, data?.id)
  );
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  // Banner file states
  const [bannerFile, setBannerFile] = useState(null);
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState(
    data?.banner_url || null
  );

  const queryClient = useQueryClient();
  const { mutate: updateProject, isLoading } = useUpdateProject();
  const { data: clientsData, isLoading: clientsLoading } = useClients(1, 100);

  // Memoize client options to prevent unnecessary re-renders.
  const clientOptions = useMemo(
    () =>
      clientsData?.clients.map((client) => ({
        value: client.id,
        label: client.name,
      })) || [],
    [clientsData]
  );

  // Pre-select client if one is associated.
  const [selectedClient, setSelectedClient] = useState(null);
  useEffect(() => {
    if (formData.client_id) {
      const client = clientOptions.find(
        (option) => option.value === formData.client_id
      );
      if (client && client !== selectedClient) {
        setSelectedClient(client);
      }
    }
  }, [formData.client_id, clientOptions, selectedClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTogglePortalEnabled = () => {
    setFormData((prevData) => ({
      ...prevData,
      portal_enabled: !prevData.portal_enabled,
    }));
  };

  const handleCountryChange = (country) => {
    setFormData((prevData) => ({ ...prevData, country, city: "" }));
  };

  const handleCityChange = (city) => {
    setFormData((prevData) => ({ ...prevData, city }));
  };

  const handleClientChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      client_id: selectedOption ? selectedOption.value : "",
    }));
    setSelectedClient(selectedOption);
  };

  // Banner file validations and setting state.
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const MIN_WIDTH = 800; // Minimum recommended width
  const MIN_HEIGHT = 300; // Minimum recommended height

  const handleBannerFile = (file) => {
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        remove_banner: false,
      }));
      // Validate file type.
      if (!file.type.startsWith("image/")) {
        setError((prev) => ({
          ...prev,
          banner: "Please upload a valid image file.",
        }));
        setBannerFile(null);
        setBannerPreviewUrl(null);
        return;
      }
      // Validate file size.
      if (file.size > MAX_FILE_SIZE) {
        setError((prev) => ({
          ...prev,
          banner: "File size exceeds the maximum limit of 5MB.",
        }));
        setBannerFile(null);
        setBannerPreviewUrl(null);
        return;
      }
      // Validate image dimensions.
      const img = new Image();
      img.onload = () => {
        if (
          img.width < MIN_WIDTH ||
          img.height < MIN_HEIGHT ||
          img.width <= img.height
        ) {
          setError((prev) => ({
            ...prev,
            banner: `Banner image must be at least ${MIN_WIDTH}px wide and ${MIN_HEIGHT}px tall and rectangular (wider than tall).`,
          }));
          setBannerFile(null);
          setBannerPreviewUrl(null);
        } else {
          setError((prev) => ({ ...prev, banner: null }));
          setBannerFile(file);
          setBannerPreviewUrl(URL.createObjectURL(file));
        }
      };
      img.onerror = () => {
        setError((prev) => ({
          ...prev,
          banner: "Failed to load the image file.",
        }));
        setBannerFile(null);
        setBannerPreviewUrl(null);
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleBannerFileDrop = (file) => {
    handleBannerFile(file);
  };

  const handleBannerInputChange = (e) => {
    const file = e.target.files[0];
    handleBannerFile(file);
  };

  const handleRemoveBanner = () => {
    setBannerFile(null);
    setBannerPreviewUrl(null);
    setFormData((prevData) => ({
      ...prevData,
      banner_url: null,
      remove_banner: true,
    }));
    setError((prev) => ({ ...prev, banner: null }));
  };

  const copyQRCode = async () => {
    try {
      const canvas = document.getElementById("qrCodeCanvas");
      if (!canvas) return;
      canvas.toBlob(async (blob) => {
        if (!blob) return;
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);
        setCopyQRSuccess(true);
        setTimeout(() => setCopyQRSuccess(false), 2000);
      });
    } catch (err) {
      console.error("Failed to copy QR Code:", err);
      alert("Failed to copy QR Code.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name) {
      setError({ name: "Project name is required." });
      return;
    }

    let submissionData;
    // If banner file exists, use FormData for multipart/form-data submission.
    if (bannerFile) {
      submissionData = new FormData();
      Object.keys(formData).forEach((key) => {
        submissionData.append(key, formData[key]);
      });
      submissionData.append("banner", bannerFile);
    } else {
      submissionData = formData;
    }

    updateProject(
      { projectId: data.id, data: submissionData },
      {
        onSuccess: () => {
          setSuccessMessage("Project updated successfully!");
          queryClient.invalidateQueries(["project", data.id]);
          setTimeout(() => setSuccessMessage(""), 3000);
        },
        onError: (err) => {
          setError({ submit: err.message || "Failed to update project." });
        },
      }
    );
  };

  return (
    <div>
      <div className="px-4 sm:px-0 bg-white rounded-lg">
        <CardHeadings
          title="Project Configuration"
          description="Details and settings for this project."
        />
      </div>
      <div className="bg-white p-4 border border-gray-100">
        {successMessage && (
          <div className="mb-4 p-4 text-green-700 bg-green-100 rounded">
            {successMessage}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Project Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Project Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Project Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="border p-2 w-full rounded max-w-md"
            />
            {error.name && (
              <div className="text-red-500 text-sm mt-1">{error.name}</div>
            )}
          </div>

          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="border p-2 w-full rounded max-w-md"
              required
            >
              <option value="Planned">Planned</option>
              <option value="In Progress">In Progress</option>
              <option value="Active">Active</option>
              <option value="Completed">Completed</option>
              <option value="Paused">Paused</option>
              <option value="Cancelled">Cancelled</option>
            </select>
            {error.status && (
              <div className="text-red-500 text-sm mt-1">{error.status}</div>
            )}
          </div>

          {/* Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              name="description"
              placeholder="Project Description"
              value={formData.description}
              onChange={handleChange}
              className="border p-2 w-full rounded max-w-md"
              rows="4"
            />
          </div>

          {/* Client Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Associate Client
            </label>
            <AsyncSelect
              cacheOptions
              defaultOptions={clientOptions}
              isLoading={clientsLoading}
              value={selectedClient}
              loadOptions={(inputValue, callback) => {
                const filteredOptions = clientOptions.filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                );
                callback(filteredOptions);
              }}
              onChange={handleClientChange}
              placeholder="Select a client"
              isClearable
              className="max-w-md"
            />
          </div>

          {/* Address */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Address
            </label>
            <input
              type="text"
              name="address"
              placeholder="Project Address"
              value={formData.address}
              onChange={handleChange}
              className="border p-2 w-full rounded max-w-md"
            />
          </div>

          {/* Country */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <CountryDropdown
              value={formData.country}
              onChange={handleCountryChange}
              classes="border p-2 w-full rounded max-w-md"
            />
          </div>

          {/* City */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              City
            </label>
            <RegionDropdown
              country={formData.country}
              value={formData.city}
              onChange={handleCityChange}
              classes="border p-2 w-full rounded max-w-md"
            />
          </div>

          {/* Banner Image Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Banner Image
            </label>
            <BannerDropZone
              file={bannerFile}
              previewUrl={bannerPreviewUrl}
              onFileDrop={handleBannerFileDrop}
              onInputChange={handleBannerInputChange}
              onRemoveFile={handleRemoveBanner}
              error={error.banner}
            />
          </div>

          {/* Portal Enabled Switch */}
          <div className="flex items-center mt-4">
            <label className="text-sm font-medium text-gray-700 mr-4">
              Portal Enabled
            </label>
            <button
              type="button"
              onClick={handleTogglePortalEnabled}
              className={`w-10 h-6 flex items-center rounded-full p-1 ${
                formData.portal_enabled ? "bg-blue-500" : "bg-gray-300"
              }`}
            >
              <div
                className={`h-4 w-4 rounded-full bg-white transform ${
                  formData.portal_enabled ? "translate-x-4" : "translate-x-0"
                }`}
              />
            </button>
          </div>

          {data?.portal_enabled && (
            <div className="space-y-6 bg-white">
              {/* QR Code Section */}
              <div>
                <label className="block text-sm font-semibold text-gray-800 mb-2">
                  QR Code
                </label>
                <div className="flex items-center justify-center p-4 bg-gray-100 rounded-lg max-w-md">
                  <QRCodeCanvas
                    id="qrCodeCanvas"
                    value={window.location.origin + sharableLink}
                    size={128}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="L"
                  />
                </div>
                <div className="mt-2 flex">
                  <button
                    type="button"
                    onClick={copyQRCode}
                    className="px-3 py-2 text-xs font-medium text-center rounded text-white bg-gray-800 hover:bg-gray-900"
                  >
                    {copyQRSuccess ? "Copied!" : "Copy QR Code"}
                  </button>
                </div>
              </div>
              {/* Shareable Link Section */}
              <div className="bg-white">
                <label className="block text-sm font-semibold text-gray-800 mb-2">
                  Shareable Link
                </label>
                <div className="flex items-center bg-gray-50 p-2 rounded border border-gray-300 max-w-md">
                  <Link
                    to={sharableLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline truncate w-full"
                    title={window.location.origin + sharableLink}
                  >
                    {`${window.location.origin}${
                      sharableLink.length > 30
                        ? `${sharableLink.substring(0, 30)}...`
                        : sharableLink
                    }`}
                  </Link>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(
                        window.location.origin + sharableLink
                      );
                      setCopySuccess(true);
                      setTimeout(() => setCopySuccess(false), 2000);
                    }}
                    className="ml-3 flex items-center p-2 rounded-lg bg-gray-200 hover:bg-gray-300 focus:outline-none transition"
                  >
                    {copySuccess ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-green-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-gray-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Submit Button */}
          {userRoleActionEnabled && (
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </button>
          )}
          {error.submit && (
            <div className="text-red-500 text-sm mt-1">{error.submit}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ProjectSettings;
