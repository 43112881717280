import React from "react";
import { Link } from "react-router-dom";
import LandingHeader from "../components/layout/landingLayout/LandingHeader";
import LandingFooter from "../components/layout/landingLayout/LandingFooter";
import projects from "../assets/images/projects.png";
import { useTranslation } from "react-i18next";

const About = () => {
  return (
    <div className="flex min-h-screen flex-col bg-white">
      <LandingHeader />
      <main className="flex-grow">
        <HeroSection />
        <GlobalVision />
        <WhyPlura />
        <OurStory />
        <FinalCTA />
      </main>
      <LandingFooter />
    </div>
  );
};

export default About;

/* --------------------------------
   1. Hero Section
--------------------------------- */
const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <section className="relative bg-gradient-to-b from-blue-50 to-transparent py-32">
      <div className="mx-auto max-w-screen-xl px-4 text-center">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
          {t("aboutPage.hero.title")}
        </h1>
        <p className="mx-auto mb-4 max-w-2xl text-lg text-gray-700 lg:text-xl">
          {t("aboutPage.hero.subtitle")}
        </p>
        <p className="mx-auto mb-8 max-w-2xl text-lg font-semibold text-gray-800 lg:text-xl">
          {t("aboutPage.hero.tagline")}
        </p>

        <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row">
          <Link
            to="/register"
            className="inline-flex items-center justify-center rounded-lg bg-blue-700 px-6 py-3 text-lg font-medium text-white shadow hover:opacity-90 focus:outline-none"
          >
            {t("aboutPage.hero.ctaFreeTrial")}
          </Link>
          <Link
            to="https://calendar.app.google/VRyag6a5opnk3dEw5"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center rounded-lg border border-gray-300 bg-white px-6 py-3 text-lg font-medium text-gray-800 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100"
          >
            {t("aboutPage.hero.ctaDemo")}
          </Link>
        </div>
      </div>
      <div className="relative mt-12">
        <img
          src={projects}
          alt="Tablero de Plura"
          className="mx-auto w-full max-w-5xl rounded-lg shadow"
        />
        <div className="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white to-transparent"></div>
      </div>
    </section>
  );
};

/* --------------------------------
   2. Global Vision Section
--------------------------------- */
const GlobalVision = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-white pb-40">
      <div className="mx-auto max-w-screen-xl px-4 text-center">
        <div className="mx-auto max-w-3xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 mx-auto mb-6 text-blue-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"
            />
          </svg>
          <h2 className="mb-4 text-3xl font-bold text-gray-900">
            {t("aboutPage.globalVision.title")}
          </h2>
          <p className="leading-relaxed text-gray-700 md:text-lg">
            {t("aboutPage.globalVision.description")}
          </p>
        </div>
      </div>
    </section>
  );
};

/* --------------------------------
   3. Why Plura Section
--------------------------------- */
const WhyPlura = () => {
  const { t } = useTranslation();
  // Retrieve card translations as an array from the translation file.
  const cards = t("aboutPage.whyPlura.cards", { returnObjects: true });
  const icons = ["📊", "⚡", "🤝"];

  return (
    <section className="bg-gray-50 py-24">
      <div className="mx-auto max-w-6xl px-6 lg:px-8">
        <div className="mb-12 text-center">
          <h2 className="mb-4 text-3xl font-bold text-gray-900">
            {t("aboutPage.whyPlura.title")}
          </h2>
          <p className="leading-relaxed text-gray-700 md:text-lg">
            {t("aboutPage.whyPlura.description")}
          </p>
        </div>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {cards.map((card, idx) => (
            <div
              key={idx}
              className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow"
            >
              <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100 text-2xl">
                {icons[idx]}
              </div>
              <h3 className="mb-2 text-2xl font-semibold text-gray-800">
                {card.title}
              </h3>
              <p className="text-gray-600">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

/* --------------------------------
   4. Our Story Section
--------------------------------- */
const OurStory = () => {
  const { t } = useTranslation();
  // Retrieve sections array from the translation file.
  const sections = t("aboutPage.ourStory.sections", { returnObjects: true });
  const icons = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-10 h-10 text-blue-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
      />
    </svg>,
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-10 h-10 text-blue-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
      />
    </svg>,
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-10 h-10 text-blue-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
      />
    </svg>,
  ];

  return (
    <section className="bg-white py-24">
      <div className="mx-auto max-w-6xl px-6 lg:px-8">
        <div className="mb-12 text-center">
          <h2 className="mb-4 text-3xl font-bold text-gray-900">
            {t("aboutPage.ourStory.title")}
          </h2>
          <p className="leading-relaxed text-gray-700 md:text-lg">
            {t("aboutPage.ourStory.description")}
          </p>
        </div>
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3">
          {sections.map((section, idx) => (
            <div
              key={idx}
              className="rounded-2xl bg-white p-8 shadow text-center border border-gray-100"
            >
              <div className="mb-4 flex justify-center">{icons[idx]}</div>
              <h3 className="text-2xl font-semibold text-gray-900">
                {section.title}
              </h3>
              <p className="mt-3 text-gray-600">{section.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

/* --------------------------------
   5. Final CTA Section
--------------------------------- */
const FinalCTA = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-blue-50 py-24 mb-12">
      <div className="mx-auto max-w-5xl px-6 text-center">
        <h2 className="text-3xl font-bold text-gray-900 md:text-4xl">
          {t("aboutPage.finalCTA.title")}
        </h2>
        <p className="mt-4 text-lg text-gray-700 md:text-xl leading-relaxed">
          {t("aboutPage.finalCTA.description")}
        </p>
        <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row">
          <Link
            to="/register"
            className="inline-flex items-center justify-center rounded-lg bg-blue-700 px-6 py-3 text-lg font-medium text-white shadow hover:opacity-90 focus:outline-none"
          >
            {t("aboutPage.finalCTA.ctaFreeTrial")}
          </Link>
          <Link
            to="https://calendar.app.google/VRyag6a5opnk3dEw5"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center rounded-lg border border-gray-300 bg-white px-6 py-3 text-lg font-medium text-gray-800 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100"
          >
            {t("aboutPage.finalCTA.ctaDemo")}
          </Link>
        </div>
      </div>
    </section>
  );
};
