import React from "react";
import { Link } from "react-router-dom";

const FoldersSection = ({ folders, token }) => {
  return (
    <section className="mb-24">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">Folders</h3>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {folders.map(({ folder, files }) => {
          // Extract up to 3 images for preview
          const imageFiles = files
            .filter((file) => file.file_type === "image")
            .slice(0, 3);
          return (
            <Link
              key={folder.id}
              className="relative bg-white rounded cursor-pointer shadow-sm hover:shadow-md transition-shadow duration-200"
              to={`${token}/${folder.id}`}
            >
              {/* Image Previews */}
              {imageFiles.length > 0 ? (
                <div className="grid grid-cols-3 gap-1">
                  {/* First image (Large, spans 2 columns) */}
                  <div
                    className={
                      imageFiles.length === 1 ? "col-span-3" : "col-span-2"
                    }
                  >
                    <img
                      key={imageFiles[0].id}
                      src={imageFiles[0].file_url}
                      alt="Folder preview"
                      className="w-full h-40 object-cover rounded"
                    />
                  </div>

                  {/* Column for stacked smaller images */}
                  <div className="flex flex-col gap-1">
                    {/* Second image */}
                    {imageFiles[1] && (
                      <img
                        key={imageFiles[1].id}
                        src={imageFiles[1].file_url}
                        alt="Folder preview"
                        className="w-full h-[78px] object-cover rounded"
                      />
                    )}

                    {/* Third image */}
                    {imageFiles[2] && (
                      <img
                        key={imageFiles[2].id}
                        src={imageFiles[2].file_url}
                        alt="Folder preview"
                        className="w-full h-[78px] object-cover rounded"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="h-40 bg-gray-50 rounded-lg flex items-center justify-center text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                    />
                  </svg>
                </div>
              )}

              {/* Folder Name & File Count */}
              <div className="px-4 py-2">
                <h4 className="text-base font-medium mt-2">{folder.name}</h4>
                <p className="text-sm text-gray-600">
                  {files.length} File{files.length !== 1 && "s"}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default FoldersSection;
