import React, { useState, useEffect, useCallback, useContext } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import ProjectCard from "../../../components/common/cards/ProjectCard";
import { useNavigate } from "react-router-dom";
import { useProjects } from "../../../hooks/useProjects/useProjects";
import ErrorMessage from "../../../components/common/empty/ErrorMessage";
import LoadingPage from "../../../components/common/empty/LoadingPage";
import EmptyPage from "../../../components/common/empty/EmptyPage";
import FiltersContainer from "../../../components/common/filters/FiltersContainer";
import { AuthContext } from "../../../context/AuthProvider";

const Projects = () => {
  const { user } = useContext(AuthContext);
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // States for filtering
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [archiveFilter, setArchiveFilter] = useState("");
  const perPage = 20;

  // Debounce the search text: update debounced value after 500ms of inactivity
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  // Pass filters to the useProjects hook using the debounced search text.
  // If archiveFilter is empty, we pass undefined so that only active projects are returned.
  const { data, isLoading, isError } = useProjects(currentPage, perPage, {
    name: debouncedSearchText,
    status: statusFilter,
    archived: archiveFilter === "" ? undefined : archiveFilter,
  });

  // Reset projects and page when filters change.
  useEffect(() => {
    setProjects([]);
    setCurrentPage(1);
  }, [debouncedSearchText, statusFilter, archiveFilter]);

  // Update projects and pagination state when new data arrives.
  useEffect(() => {
    if (data) {
      setProjects((prevProjects) => {
        const projectIds = new Set(prevProjects.map((project) => project.id));
        const newProjects = data.projects.filter(
          (project) => !projectIds.has(project.id)
        );
        return currentPage === 1
          ? data.projects
          : [...prevProjects, ...newProjects];
      });
      setHasMore(data.pagination.total_items > currentPage * perPage);
    }
  }, [data, currentPage, perPage]);

  const handleAddClient = () => {
    navigate("/projects/create");
  };

  // Infinite scrolling logic.
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!isLoading && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [isLoading, hasMore]);

  useEffect(() => {
    if (hasMore) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, hasMore]);

  // Determine empty state message based on whether filters are active.
  const emptyTitle =
    debouncedSearchText || statusFilter || archiveFilter
      ? "No Projects Found"
      : "Get Started with Your First Project";
  const emptyDescription =
    debouncedSearchText || statusFilter || archiveFilter
      ? "We couldn’t find any projects matching your filters. Try adjusting your search or filter options."
      : "It looks like you haven’t created any projects yet. Start by setting up your first project to organize your work, collaborate with your team, and track progress effectively.";

  return (
    <div>
      <PageHeadings
        title="Projects"
        tags={[]}
        showCta={userRoleActionEnabled}
        ctaLabel="Create"
        handleCtaAction={handleAddClient}
      />

      {/* Filters Section */}
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
        {/* Search Input */}
        <FiltersContainer
          removeDefaultStyles={true}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {/* Status Filter */}
        <div className="flex">
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Status: All</option>
            <option value="Planned">Planned</option>
            <option value="In Progress">In Progress</option>
            <option value="Active">Active</option>
            <option value="Completed">Completed</option>
            <option value="Paused">Paused</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
        <div className="flex">
          <select
            value={archiveFilter}
            onChange={(e) => setArchiveFilter(e.target.value)}
            className="p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Active</option>
            <option value="true">Archived</option>
          </select>
        </div>
      </div>

      {isLoading && currentPage === 1 ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorMessage />
      ) : projects.length === 0 ? (
        <EmptyPage title={emptyTitle} description={emptyDescription} />
      ) : (
        <>
          <div className="mb-4 grid gap-4 sm:grid-cols-2 md:mb-8 lg:grid-cols-3">
            {projects.map((project) => (
              <ProjectCard
                key={project.id}
                data={project}
                enableCopy={userRoleActionEnabled}
              />
            ))}
          </div>
          {!hasMore && !isLoading && currentPage > 1 && (
            <div className="text-center py-4">No more projects to load.</div>
          )}
        </>
      )}
    </div>
  );
};

export default Projects;
