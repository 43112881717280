import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import axiosInstance from "./axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// -----------------------------
// Company Info Hooks
// -----------------------------

const fetchCompanyInfo = async (companyId, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/companies/${companyId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const useCompanyInfo = (companyId) => {
  const { user } = useContext(AuthContext);
  return useQuery(["companyInfo", companyId], () =>
    fetchCompanyInfo(companyId, user?.token)
  );
};

const updateCompanyInfo = async ({ companyId, token, payload }) => {
  const headers = { Authorization: `Bearer ${token}` };
  if (!(payload instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }
  const response = await axiosInstance.put(
    `${API_URL}/companies/${companyId}`,
    payload,
    { headers }
  );
  return response.data;
};

export const useUpdateCompanyInfo = (companyId) => {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => updateCompanyInfo({ companyId, token: user?.token, payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["companyInfo", companyId]);
      },
    }
  );
};

// -----------------------------
// API Key Hooks
// -----------------------------

// GET: Retrieve the company's API key
const fetchCompanyApiKey = async ({ companyId, token }) => {
  const response = await axiosInstance.get(
    `${API_URL}/companies/${companyId}/apikey`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const useCompanyApiKey = (companyId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["companyApiKey", companyId],
    () => fetchCompanyApiKey({ companyId, token: user?.token }),
    { enabled: !!companyId && !!user?.token }
  );
};

// POST: Generate (or regenerate) the company's API key
const generateCompanyApiKey = async ({ companyId, token }) => {
  const response = await axiosInstance.post(
    `${API_URL}/companies/${companyId}/apikey`,
    {}, // No payload required
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const useGenerateCompanyApiKey = (companyId) => {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  return useMutation(
    () => generateCompanyApiKey({ companyId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["companyApiKey", companyId]);
      },
    }
  );
};

// DELETE: Delete (revoke) the company's API key
const deleteCompanyApiKey = async ({ companyId, token }) => {
  const response = await axiosInstance.delete(
    `${API_URL}/companies/${companyId}/apikey`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const useDeleteCompanyApiKey = (companyId) => {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  return useMutation(
    () => deleteCompanyApiKey({ companyId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["companyApiKey", companyId]);
      },
    }
  );
};
