import React from "react";
import TaskList from "../../todoList/viewMode/TaskList";
import TaskBoard from "../../todoList/viewMode/TaskBoard";
import { useTasksOfProject } from "../../../../hooks/useTasks/useTasks";
import LoadingPage from "../../../../components/common/empty/LoadingPage";
import ErrorMessage from "../../../../components/common/empty/ErrorMessage";
import GanttView from "../../todoList/viewMode/GanttView";

const ProjectTasks = ({ projectId, viewMode, phaseData, filters }) => {
  const {
    data: tasks,
    isLoading,
    isError,
  } = useTasksOfProject(projectId, filters);

  return (
    <div>
      {isLoading ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <>
          {viewMode === "list" && (
            <TaskList data={tasks} projectId={projectId} />
          )}
          {viewMode === "board" && (
            <TaskBoard data={tasks} projectId={projectId} />
          )}
          {viewMode === "gantt" && (
            <GanttView tasks={tasks} phases={phaseData} />
          )}
        </>
      )}
    </div>
  );
};

export default ProjectTasks;
