import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import ProjectButtonGroupMenu from "../../../components/common/buttonGroup/ProjectButtonGroupMenu";
import ProjectFinance from "./project/ProjectFinance";
import ProjectFiles from "./project/ProjectFiles";
import ProjectTasks from "./project/ProjectTasks";
import ProjectSettings from "./project/ProjectSettings";
import TasksButtonGroupMenu from "../../../components/common/buttonGroup/TasksButtonGroupMenu";
import NoProjectsCard from "../../../components/common/cards/NoProjectsCard";
import { useProject } from "../../../hooks/useProjects/useProjects";
import LoadingPage from "../../../components/common/empty/LoadingPage";
import { usePhases } from "../../../hooks/useTasks/usePhases";
import { AuthContext } from "../../../context/AuthProvider";
import TaskFilters from "../../../components/common/filters/TaskFilters";
import { useUsers } from "../../../hooks/useUsers";
import NoAccessView from "../../../components/common/cards/NoAccessView";

const Project = () => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;
  const userRoleActionEnabled = user?.role === 0 || user?.role === 1;

  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const initialViewMode = queryParams.get("view") || "files";

  const { data: project, isLoading, isError } = useProject(id);
  const { data: phasesData = [] } = usePhases(companyId);
  const phaseData = phasesData || [];
  const [viewMode, setViewMode] = useState(initialViewMode);
  const [viewModeTasks, setViewModeTasks] = useState("board");

  // --- Filter states for tasks view ---
  const [phaseFilter, setPhaseFilter] = useState(null);
  const [usersFilter, setUsersFilter] = useState([]);

  // Map phases (for filters) to react-select options.
  const phaseOptions = useMemo(
    () =>
      phaseData.map((phase) => ({
        value: phase.id,
        label: phase.name,
      })),
    [phaseData]
  );

  // Load company users using your users hook.
  const {
    data: usersData,
    isLoading: usersLoading,
    isError: usersError,
  } = useUsers(1, 100);
  const userOptions = useMemo(() => {
    if (!usersData || !usersData.users) return [];
    return usersData.users.map((user) => ({
      value: user.id,
      label: user.username,
    }));
  }, [usersData]);

  // Build filters object for tasks. (ProjectTasks should use these to fetch tasks accordingly.)
  const filters = useMemo(() => {
    return {
      phase_id: phaseFilter ? phaseFilter.value : "",
      user_ids:
        usersFilter && usersFilter.length > 0
          ? usersFilter.map((u) => u.value)
          : "",
    };
  }, [phaseFilter, usersFilter]);

  // Update URL query parameters when viewMode changes.
  useEffect(() => {
    navigate(`?view=${viewMode}`, { replace: true });
  }, [viewMode, navigate]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError || !project) {
    return <NoProjectsCard />;
  }

  return (
    <div>
      <PageHeadings title={project?.name} tags={[]} showCta={false} />
      <ProjectButtonGroupMenu viewMode={viewMode} setViewMode={setViewMode}>
        {viewMode === "tasks" && (
          <>
            <TasksButtonGroupMenu
              viewMode={viewModeTasks}
              setViewMode={setViewModeTasks}
            />
          </>
        )}
      </ProjectButtonGroupMenu>
      {viewMode === "tasks" && (
        <div className="flex">
          <TaskFilters
            phaseOptions={phaseOptions}
            phaseFilter={phaseFilter}
            setPhaseFilter={setPhaseFilter}
            usersLoading={usersLoading}
            usersError={usersError}
            userOptions={userOptions}
            usersFilter={usersFilter}
            setUsersFilter={setUsersFilter}
          />
        </div>
      )}
      {viewMode === "files" && (
        <DefaultCard>
          <ProjectFiles />
        </DefaultCard>
      )}
      {viewMode === "tasks" && (
        <ProjectTasks
          projectId={project?.id}
          viewMode={viewModeTasks}
          phaseData={phaseData}
          filters={filters}
        />
      )}
      {viewMode === "finance" && (
        <DefaultCard>
          {userRoleActionEnabled ? (
            <ProjectFinance projectId={project?.id} />
          ) : (
            <NoAccessView />
          )}
        </DefaultCard>
      )}
      {viewMode === "settings" && (
        <DefaultCard>
          <ProjectSettings data={project} />
        </DefaultCard>
      )}
    </div>
  );
};

export default Project;
